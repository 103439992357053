import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Diaporama} from '../../diaporama';

@Component({
  selector: 'hpf-diaporama-create',
  templateUrl: './diaporama-create.component.html'
})
export class DiaporamaCreateComponent implements OnInit {
  /** The diaporama */
  diaporama = new Diaporama();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the diaporama is created */
  onCreate(diaporama: Diaporama): void {
    this.router.navigateByUrl(`/diaporama/${diaporama.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
