import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-text-translated',
  templateUrl: './text-translated.component.html'
})
export class TextTranslatedComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
