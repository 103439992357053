import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import { Language, LanguageInterface } from '../language/language';
import { environment } from '@env/environment';
export interface AudioInterface extends BaseModelInterface {
	created_at: number | Date;
	owner: string | User | UserInterface;
	uri: string;
	name: string;
	lang?: string | Language | LanguageInterface;
	fileUpload: {};
	ext: string;
}
export interface AudioPayload {
	uri: string;
	name: string;
	lang?: string | Language | LanguageInterface;
	fileUpload: {};
}
type AudioPayloadKey = keyof AudioPayload;
export class Audio extends BaseModel<AudioInterface, AudioPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}

	getUri(): string {
		return `${environment.api.uri}/audio/view/${this.props._id}`;
	}

	getEditUrl(): string {
		return `${environment.api.uri}/audio/${this.props._id}`;
	}

	getMediaType(): string {
		return 'audio';
	}

	/** Denotes if the instance of owner has been populated */
	ownerExists(): boolean {
		return (
			!!this.props &&
			this.props.owner instanceof User &&
			this.props.owner.exists()
		);
	}
	/** Denotes if the instance of lang has been populated */
	langExists(): boolean {
		return (
			!!this.props &&
			this.props.lang instanceof Language &&
			this.props.lang.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: AudioInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.owner === 'object') {
			this.props.owner = new User(<UserInterface>this.props.owner);
		}
		if (this.props.lang !== null && typeof this.props.lang === 'object') {
			this.props.lang = new Language(<LanguageInterface>this.props.lang);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): AudioInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.lang === 'undefined') {
			props.lang = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.owner instanceof User) {
			props.owner = props.owner.toObject();
		}
		if (props.lang !== null && props.lang instanceof Language) {
			props.lang = props.lang.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): AudioPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as AudioInterface);
		payload.lang = payload.lang ? this.extractId(payload.lang) : null;
		return payload as AudioPayload;
	}
	toFormData(): FormData {
		var payload = this.toPayload();
		const formData = new FormData();

		for (const key in payload) {
			formData.append(key, payload[key]);
		}

		return formData;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): AudioPayloadKey[] {
		return ['name', 'lang', 'fileUpload'];
	}
}
