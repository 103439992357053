import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { Image } from '../image';
import { ImageService } from '../image.service';
import { LanguageTabComponent } from '@app/models';
@Component({
	selector: 'hpf-image-form',
	templateUrl: './image-form.component.html',
})
export class ImageFormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The image to inject in the form */
	@Input() image: Image;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<Image>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;

	apiUrl = environment.api.uri;

	@ViewChildren(LanguageTabComponent) languageTabs: QueryList<
		LanguageTabComponent
	>;
	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private imageService: ImageService,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'image',
			'update'
		));

		// Init model
		if (!this.image) this.image = new Image();

		// Create form
		this.form = this.formBuilder.group({
			name: new FormControl(this.image.props.name, [Validators.required]),
		});
		// Update form when model loads or changes
		this.modelSubscription = this.image.subscribe(() => {
			this.updateForm();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();
			for (let element of this.languageTabs) {
				await element.submit();
			}

			// Creation or update ?
			if (this.image.isNew()) {
				// Creation

				console.log(this.image);

				const image: Image = await this.imageService.postWithFile(
					this.image.toFormData()
				);
				/*
				const image: Image = await this.imageService.create(
					this.image.toPayload()
				);
*/
				this.create.next(image);
			} else {
				// Update

				var up = this.image.toFormData();

				var image = await this.imageService.updateWithFile(
					this.image.getId(),
					up
				);
				this.update.next(image);
				window.location.reload();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.image.props[key] = this.form.get(key).value;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			name: this.image.props.name,
		});
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.imageService
			.remove(this.image.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
	fileUploaded(data) {
		this.image.props.fileUpload = data.target.files[0];
		console.log(data);
	}
}
