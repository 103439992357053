import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-audio',
  templateUrl: './audio.component.html'
})
export class AudioComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
