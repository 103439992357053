
<nz-modal
[(nzVisible)]="deleteModal"
(nzOnCancel)="deleteModal = false"
(nzOnOk)="onDelete()"
[nzTitle]="'common_deletion' | translate"
[nzContent]="'common_delete-one' | translate"
[nzOkLoading]="deleting"
></nz-modal>
<form nz-form
  class="audio-description-form w-100"
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  [nzAutoTips]="explainErrorsService.autoTips">
<nz-affix>
    <div class="py-4 border-bottom bg-white">
        <div class="container">
            <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-between">
                    <!-- Title -->
                    <div>
                        <h1 class="mb-0">
                            <span *ngIf="audioDescription.isNew()">{{ 'audio-description_create-form-title' | translate }}</span>
                            <span *ngIf="!audioDescription.isNew()">{{ audioDescription.getLabel() }}</span>
                        </h1>
                        <small *ngIf="!audioDescription.isNew()" class="text-black-50">{{ 'common_id' | translate: { id : audioDescription.getId() } }}</small>
                    </div>
                    <!-- /Title -->
                    
                    <!-- Actions -->
                    <div>
                        <button
                            nz-button
                            [disabled]="form.invalid"
                            [nzLoading]="saving"
                            nzType="primary"
                            *appRestrictedByScope="{
                                model: 'audio_description',
                                action: 'update'
                            }"
                        >
                            {{ 'common_save' | translate }}
                        </button>
                        <ng-container *appRestrictedByScope="{
                            model: 'audio_description',
                            action: 'delete'
                        }">
                            <button
                                *ngIf="!audioDescription.isNew() && deletable"
                                class="ml-4"
                                nz-button
                                nzShape="circle"
                                [title]="'common_delete' | translate"
                                [nzLoading]="deleting"
                                (click)="deleteModal = true"
                                type="button"
                            >
                                <i nz-icon nzType="delete"></i>
                            </button>
                        </ng-container>
                    </div>
                    <!-- /Actions -->
                </div>
            </div>
        </div>
    </div>
</nz-affix>
<div class="container">
<nz-card class="round-border my-5">
<div *ngIf="loading" class="row">
    <div class="col-12">
        <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
    </div>
</div>
<div *ngIf="!loading">
    <div class="row" *ngIf="!readOnly">
        <div class="col-12 col-md-6">
            <!-- Name -->

            
            <nz-form-item>
                <h4>{{ 'audio-description_name' | translate }}</h4>
                <nz-form-control>

         
                    <input nz-input
                           class="w-100"
                           type="text"
                           [placeholder]="'common_value-string' | translate"
                           formControlName="name">
                </nz-form-control>
            </nz-form-item>
            <!-- /Name -->
        </div>
        <div class="col-12 col-md-6">
            <!-- Audio -->

            
            <nz-form-item>
                <h4>{{ 'audio-description_audio' | translate }}</h4>
                <nz-form-control [nzValidateStatus]="form.get('audio')">

         
                    <hpf-audio-select
                        [controlName]="'audio'"
                        [formGroup]="form"
                        [quickFormEnabled]="quickFormEnabled"
                        [(model)]="audioDescription.props.audio"
                        [multiple]="true"
                        [filterEnabled]="true"
                        [placeholder]="'audio_common_search-placeholder' | translate"
                        [emptyLabel]="'common_empty' | translate">
                    </hpf-audio-select>
                </nz-form-control>
            </nz-form-item>
            <!-- /Audio -->
        </div>
        <div class="col-12 col-md-6">
            <!-- Title -->

            <h4>{{ 'audio-description_title' | translate }}</h4>
               <app-language-tab [name]="'title'" [model]="audioDescription"></app-language-tab>
               </div>

        <div class="col-12 col-md-6">
            <!-- Description -->

            <h4>{{ 'audio-description_description' | translate }}</h4>
               <app-language-tab [name]="'description'" [model]="audioDescription"></app-language-tab>
               </div>

    </div>

    <nz-divider *ngIf="!audioDescription.isNew() && !readOnly"></nz-divider>
    <div *ngIf="!audioDescription.isNew()" class="row">
        <div class="col-12 col-md-6" >
            <!-- _Id -->
            <div class="mb-4">
                <h4>{{ 'audio-description_-id' | translate }}</h4>
                <div>{{ audioDescription.props._id }}</div>
            </div>
            <!-- /_Id -->
        </div>
        <div class="col-12 col-md-6" >
            <!-- Created At -->
            <div class="mb-4">
                <h4>{{ 'audio-description_created-at' | translate }}</h4>
                <div>{{ audioDescription.props.created_at | date:'medium' }}</div>
            </div>
            <!-- /Created At -->
        </div>
        <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Name -->
            <div class="mb-4">
                <h4>{{ 'audio-description_name' | translate }}</h4>
                <div>{{ audioDescription.props.name }}</div>
            </div>
            <!-- /Name -->
        </div>
        <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Audio -->
            <div class="mb-4">
                <h4>{{ 'audio-description_audio' | translate }}</h4>
                <div *ngIf="audioDescription.audioExists()">
                    <div *ngFor="let item of audioDescription.props.audio">
                        <a class="mr-2" [routerLink]="['/audio', item.getId()]">
                            {{ item.getLabel() }}
                        </a>
                    </div>
                </div>
            </div>
            <!-- /Audio -->
        </div>
        <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Title -->
            <div class="mb-4">
                <h4>{{ 'audio-description_title' | translate }}</h4>
                <div *ngIf="audioDescription.titleExists()">
                    <div *ngFor="let item of audioDescription.props.title">
                        <a class="mr-2" [routerLink]="['/text-translated', item.getId()]">
                            {{ item.getLabel() }}
                        </a>
                    </div>
                </div>
            </div>
            <!-- /Title -->
        </div>
        <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Description -->
            <div class="mb-4">
                <h4>{{ 'audio-description_description' | translate }}</h4>
                <div *ngIf="audioDescription.descriptionExists()">
                    <div *ngFor="let item of audioDescription.props.description">
                        <a class="mr-2" [routerLink]="['/text-translated', item.getId()]">
                            {{ item.getLabel() }}
                        </a>
                    </div>
                </div>
            </div>
            <!-- /Description -->
        </div>
    </div>
</div>
</nz-card>
</div>
</form>
