import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { Audio } from '../audio';
import { AudioService } from '../audio.service';
@Component({
	selector: 'hpf-audio-form',
	templateUrl: './audio-form.component.html',
})
export class AudioFormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The audio to inject in the form */
	@Input() audio: Audio;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<Audio>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private audioService: AudioService,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'audio',
			'update'
		));

		// Init model
		if (!this.audio) this.audio = new Audio();

		// Create form
		this.form = this.formBuilder.group({
			name: new FormControl(this.audio.props.name, [Validators.required]),
			lang: new FormControl(this.audio.props.lang, []),
		});

		// Update form when model loads or changes
		this.modelSubscription = this.audio.subscribe(() => {
			this.updateForm();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();
			// Creation or update ?
			if (this.audio.isNew()) {
				// Creation
				const audio: Audio = await this.audioService.postWithFile(
					this.audio.toFormData()
				);
				this.create.next(audio);
			} else {
				// Update
				var up = this.audio.toFormData();
				delete up['uri'];

				await this.audioService.updateWithFile(this.audio.getId(), up);
				this.update.next();
				window.location.reload();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.audio.props[key] = this.form.get(key).value;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			name: this.audio.props.name,
			lang: this.audio.props.lang,
		});
	}
	fileUploaded(data): void {
		this.audio.props.fileUpload = data.target.files[0];
		console.log(data);
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.audioService
			.remove(this.audio.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
