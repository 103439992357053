import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {RouteSearchParams} from '../route-search-params';

@Component({
  selector: 'hpf-route-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class RouteListTopBarComponent extends EntityListTopBarComponent< RouteSearchParams> {
}
 