import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {AboutSearchParams} from '../about-search-params';

@Component({
  selector: 'hpf-about-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class AboutListTopBarComponent extends EntityListTopBarComponent< AboutSearchParams> {
}
 