import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {DiaporamaSearchParams} from '../diaporama-search-params';

@Component({
  selector: 'hpf-diaporama-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class DiaporamaListTopBarComponent extends EntityListTopBarComponent< DiaporamaSearchParams> {
}
 