<div
	class="row"
	*appRestrictedByScope="{
		model: 'activity_log',
		action: 'search'
	}"
>
	<div class="py-4 pb-5 col-12">
		<div class="py-3">
			<h3>
				<a
					[routerLink]="['/activity-log']"
					[queryParams]="historySearchParams.toObject()"
				>
					{{ 'history-title' | translate }}
				</a>
			</h3>
		</div>
		<hpf-activity-log-table
			[searchParams]="historySearchParams"
			(select)="onSelectHistory($event)"
			[columns]="['created_at', 'action', 'initiator', 'name']"
		>
		</hpf-activity-log-table>
	</div>
</div>
