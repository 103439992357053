
<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<form nz-form
      class="marker-form w-100"
      *ngIf="form"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      [nzAutoTips]="explainErrorsService.autoTips">
    <nz-affix>
        <div class="py-4 border-bottom bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex align-items-center justify-content-between">
            			<!-- Title -->
            			<div>
            				<h1 class="mb-0">
            					<span *ngIf="marker.isNew()">{{ 'marker_create-form-title' | translate }}</span>
            					<span *ngIf="!marker.isNew()">{{ marker.getLabel() }}</span>
            				</h1>
            				<small *ngIf="!marker.isNew()" class="text-black-50">{{ 'common_id' | translate: { id : marker.getId() } }}</small>
            			</div>
            			<!-- /Title -->
            			
            			<!-- Actions -->
            			<div>
            				<button
            					nz-button
            					[disabled]="form.invalid"
            					[nzLoading]="saving"
            					nzType="primary"
                                *appRestrictedByScope="{
                                    model: 'marker',
                                    action: 'update'
                                }"
            				>
            					{{ 'common_save' | translate }}
            				</button>
                            <ng-container *appRestrictedByScope="{
                                model: 'marker',
                                action: 'delete'
                            }">
                                <button
                                    *ngIf="!marker.isNew() && deletable"
                                    class="ml-4"
                                    nz-button
                                    nzShape="circle"
                                    [title]="'common_delete' | translate"
                                    [nzLoading]="deleting"
                                    (click)="deleteModal = true"
                                    type="button"
                                >
                                    <i nz-icon nzType="delete"></i>
                                </button>
                            </ng-container>
            			</div>
            			<!-- /Actions -->
            		</div>
            	</div>
            </div>
        </div>
    </nz-affix>
    <div class="container">
    <nz-card class="round-border my-5">
    <div *ngIf="loading" class="row">
        <div class="col-12">
            <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
    </div>
    <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">
            <div class="col-12 col-md-6">
                <!-- Label -->

                
                <nz-form-item>
                    <h4>{{ 'marker_label' | translate }}</h4>
                    <nz-form-control>

             
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="label">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Label -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Plateau -->

                
                <nz-form-item>
                    <h4>{{ 'marker_plateau' | translate }}</h4>
                    <nz-form-control [nzValidateStatus]="form.get('plateau')">

             
                        <hpf-plateau-select
                            [controlName]="'plateau'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="marker.props.plateau"
                            [filterEnabled]="true"
                            [placeholder]="'plateau_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-plateau-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Plateau -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Point Of Interests -->

                
                <nz-form-item>
                    <h4>{{ 'marker_point-of-interests' | translate }}</h4>
                    <nz-form-control [nzValidateStatus]="form.get('point_of_interests')">

             
                        <hpf-point-of-interest-select
                            [controlName]="'point_of_interests'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="marker.props.point_of_interests"
                            [multiple]="true"
                            [nullable]="true"
                            [filterEnabled]="true"
                            [placeholder]="'point-of-interest_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-point-of-interest-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Point Of Interests -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Image -->

                
                <nz-form-item>
                    <h4>{{ 'marker_image' | translate }}</h4>
                    <nz-form-control [nzValidateStatus]="form.get('image')">

             
                        <hpf-image-select
                            [controlName]="'image'"
                            [formGroup]="form"
                            [quickFormEnabled]="quickFormEnabled"
                            [(model)]="marker.props.image"
                            [filterEnabled]="true"
                            [placeholder]="'image_common_search-placeholder' | translate"
                            [emptyLabel]="'common_empty' | translate">
                        </hpf-image-select>
                    </nz-form-control>
                </nz-form-item>
                <!-- /Image -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Latitude -->

                
                <nz-form-item>
                    <h4>{{ 'marker_latitude' | translate }}</h4>
                    <nz-form-control>

                        <input
                        nz-input
                        class="w-100"
                        type="text"
                        [placeholder]="
                            'common_value-string' | translate
                        "
                        formControlName="latitude"
                        (keyup)="locationFormUpdate.next()"
                    />
                    </nz-form-control>
                </nz-form-item>
                <!-- /Latitude -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Longitude -->

                
                <nz-form-item>
                    <h4>{{ 'marker_longitude' | translate }}</h4>
                    <nz-form-control>

             
                        <input
                        nz-input
                        class="w-100"
                        type="text"
                        [placeholder]="
                            'common_value-string' | translate
                        "
                        formControlName="longitude"
                        (keyup)="locationFormUpdate.next()"
                    />
                    </nz-form-control>
                </nz-form-item>
                <!-- /Longitude -->
            </div>
            <div class="col-12">
                <!-- Map -->
                <div class="mb-4">
                    <h4>
                        {{ 'point-of-interest_map' | translate }}
                    </h4>
                    <div
                        *ngIf="showMap"
                        style="height: 500px;"
                        leaflet
                        leafletDraw
                        [leafletDrawOptions]="mapDrawOptions"
                        (leafletDrawCreated)="addMarker($event)"
                        [(leafletCenter)]="mapOptions.center"
                        [(leafletLayers)]="mapOptions.layers"
                        [(leafletZoom)]="mapOptions.zoom"
                        [leafletMaxBounds]="mapOptions.maxBounds"
                    >
                        <div [leafletLayer]="mapDrawnItems"></div>
                    </div>
                </div>
                <!-- Map -->
            </div>

        </div>

        <nz-divider *ngIf="!marker.isNew() && !readOnly"></nz-divider>
        <div *ngIf="!marker.isNew()" class="row">
            <div class="col-12 col-md-6" >
                <!-- _Id -->
                <div class="mb-4">
                    <h4>{{ 'marker_-id' | translate }}</h4>
                    <div>{{ marker.props._id }}</div>
                </div>
                <!-- /_Id -->
            </div>
            <div class="col-12 col-md-6" >
                <!-- Created At -->
                <div class="mb-4">
                    <h4>{{ 'marker_created-at' | translate }}</h4>
                    <div>{{ marker.props.created_at | date:'medium' }}</div>
                </div>
                <!-- /Created At -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Label -->
                <div class="mb-4">
                    <h4>{{ 'marker_label' | translate }}</h4>
                    <div>{{ marker.props.label }}</div>
                </div>
                <!-- /Label -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Plateau -->
                <div class="mb-4">
                    <h4>{{ 'marker_plateau' | translate }}</h4>
                    <div *ngIf="marker.plateauExists()">
                        <a [routerLink]="['/plateau', marker.props.plateau.getId()]">
                            {{ marker.props.plateau.getLabel() }}
                        </a>
                    </div>
                </div>
                <!-- /Plateau -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Point Of Interests -->
                <div class="mb-4">
                    <h4>{{ 'marker_point-of-interests' | translate }}</h4>
                    <div *ngIf="marker.pointOfInterestsExists()">
                        <div *ngFor="let item of marker.props.point_of_interests">
                            <a class="mr-2" [routerLink]="['/point-of-interest', item.getId()]">
                                {{ item.getLabel() }}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- /Point Of Interests -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Image -->
                <div class="mb-4">
                    <h4>{{ 'marker_image' | translate }}</h4>
                    <div *ngIf="marker.imageExists()">
                        <a [routerLink]="['/image', marker.props.image.getId()]">
                            {{ marker.props.image.getLabel() }}
                        </a>
                    </div>
                </div>
                <!-- /Image -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Latitude -->
                <div class="mb-4">
                    <h4>{{ 'marker_latitude' | translate }}</h4>
                    <div>{{ marker.props.latitude }}</div>
                </div>
                <!-- /Latitude -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Longitude -->
                <div class="mb-4">
                    <h4>{{ 'marker_longitude' | translate }}</h4>
                    <div>{{ marker.props.longitude }}</div>
                </div>
                <!-- /Longitude -->
            </div>
        </div>
    </div>
    </nz-card>
    </div>
</form>
