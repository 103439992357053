import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable()
export class NavigationService {
	/** Constructor */
	constructor(private router: Router) {}

	/**
	 * Navigate to the given path after a delay unless another navigation is done
	 * Resolves true if the delay is reached, false if the user has navigated manually
	 */
	delayed(path: string[] | string, delay = 2000): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			let sub: Subscription, timeout: any;

			sub = this.router.events.subscribe((event) => {
				if (event instanceof NavigationStart) {
					clearTimeout(timeout);
					resolve(false);
				}
			});

			timeout = setTimeout(() => {
				sub.unsubscribe();
				this.go(path)
					.then(() => {
						resolve(true);
					})
					.catch(reject);
			}, delay);
		});
	}

	/** Navigate to the given path */
	async go(path: string[] | string): Promise<void> {
		if (typeof path === 'string') {
			await this.router.navigateByUrl(path);
		} else {
			await this.router.navigate(path);
		}
	}
}
