<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<form
	nz-form
	class="role-permission-form w-100"
	*ngIf="form"
	[formGroup]="form"
	(ngSubmit)="onSubmit()"
	[nzAutoTips]="explainErrorsService.autoTips"
>
	<nz-affix>
		<div class="py-4 border-bottom bg-white">
			<div class="container">
				<div class="row">
					<div
						class="col-12 d-flex align-items-center justify-content-between"
					>
						<!-- Title -->
						<div>
							<h1 class="mb-0">
								<span *ngIf="rolePermission.isNew()">{{
									'role-permission_create-form-title'
										| translate
								}}</span>
								<span *ngIf="!rolePermission.isNew()">{{
									rolePermission.getLabel()
								}}</span>
							</h1>
							<small
								*ngIf="!rolePermission.isNew()"
								class="text-black-50"
								>{{
									'common_id'
										| translate
											: { id: rolePermission.getId() }
								}}</small
							>
						</div>
						<!-- /Title -->

						<!-- Actions -->
						<div>
							<button
								nz-button
								[disabled]="form.invalid"
								[nzLoading]="saving"
								nzType="primary"
								*appRestrictedByScope="{
									model: 'role_permission',
									action: 'update'
								}"
							>
								{{ 'common_save' | translate }}
							</button>
							<ng-container
								*appRestrictedByScope="{
									model: 'role_permission',
									action: 'delete'
								}"
							>
								<button
									*ngIf="!rolePermission.isNew() && deletable"
									class="ml-4"
									nz-button
									nzShape="circle"
									[title]="'common_delete' | translate"
									[nzLoading]="deleting"
									(click)="deleteModal = true"
									type="button"
								>
									<i nz-icon nzType="delete"></i>
								</button>
							</ng-container>
						</div>
						<!-- /Actions -->
					</div>
				</div>
			</div>
		</div>
	</nz-affix>
	<div class="container">
		<nz-card class="round-border my-5">
			<div *ngIf="loading" class="row">
				<div class="col-12">
					<nz-skeleton
						class="no-header"
						[nzActive]="true"
					></nz-skeleton>
				</div>
			</div>
			<div *ngIf="!loading">
				<div class="row" *ngIf="!readOnly">
					<div class="col-12 col-md-6">
						<!-- Role -->
						<nz-form-item>
							<h4>{{ 'role-permission_role' | translate }}</h4>
							<nz-form-control
								[nzValidateStatus]="form.get('role')"
							>
								<hpf-role-select
									[controlName]="'role'"
									[formGroup]="form"
									[(model)]="rolePermission.props.role"
									[filterEnabled]="true"
									[placeholder]="
										'role_common_search-placeholder'
											| translate
									"
									[emptyLabel]="'common_empty' | translate"
								>
								</hpf-role-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Role -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Collection -->
						<nz-form-item>
							<h4>
								{{ 'role-permission_collection' | translate }}
							</h4>
							<nz-form-control>
								<nz-select
									class="w-100"
									[nzPlaceHolder]="
										'role-permission_collection' | translate
									"
									formControlName="collection"
								>
									<nz-option
										*ngFor="
											let collection of collectionList
										"
										[nzValue]="collection"
										[nzLabel]="collection"
									></nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Collection -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Create -->
						<nz-form-item>
							<h4>{{ 'role-permission_create' | translate }}</h4>
							<nz-form-control>
								<nz-select
									class="w-100"
									[nzPlaceHolder]="
										'common_value-boolean' | translate
									"
									formControlName="create"
								>
									<nz-option
										[nzValue]="true"
										[nzLabel]="'common_true' | translate"
									></nz-option>
									<nz-option
										[nzValue]="false"
										[nzLabel]="'common_false' | translate"
									></nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Create -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Read -->
						<nz-form-item>
							<h4>{{ 'role-permission_read' | translate }}</h4>
							<nz-form-control>
								<nz-select
									class="w-100"
									[nzPlaceHolder]="
										'common_value-boolean' | translate
									"
									formControlName="read"
								>
									<nz-option
										[nzValue]="true"
										[nzLabel]="'common_true' | translate"
									></nz-option>
									<nz-option
										[nzValue]="false"
										[nzLabel]="'common_false' | translate"
									></nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Read -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Update -->
						<nz-form-item>
							<h4>{{ 'role-permission_update' | translate }}</h4>
							<nz-form-control>
								<nz-select
									class="w-100"
									[nzPlaceHolder]="
										'common_value-boolean' | translate
									"
									formControlName="update"
								>
									<nz-option
										[nzValue]="true"
										[nzLabel]="'common_true' | translate"
									></nz-option>
									<nz-option
										[nzValue]="false"
										[nzLabel]="'common_false' | translate"
									></nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Update -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Delete -->
						<nz-form-item>
							<h4>{{ 'role-permission_delete' | translate }}</h4>
							<nz-form-control>
								<nz-select
									class="w-100"
									[nzPlaceHolder]="
										'common_value-boolean' | translate
									"
									formControlName="delete"
								>
									<nz-option
										[nzValue]="true"
										[nzLabel]="'common_true' | translate"
									></nz-option>
									<nz-option
										[nzValue]="false"
										[nzLabel]="'common_false' | translate"
									></nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Delete -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Search -->
						<nz-form-item>
							<h4>{{ 'role-permission_search' | translate }}</h4>
							<nz-form-control>
								<nz-select
									class="w-100"
									[nzPlaceHolder]="
										'common_value-boolean' | translate
									"
									formControlName="search"
								>
									<nz-option
										[nzValue]="true"
										[nzLabel]="'common_true' | translate"
									></nz-option>
									<nz-option
										[nzValue]="false"
										[nzLabel]="'common_false' | translate"
									></nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Search -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Count -->
						<nz-form-item>
							<h4>{{ 'role-permission_count' | translate }}</h4>
							<nz-form-control>
								<nz-select
									class="w-100"
									[nzPlaceHolder]="
										'common_value-boolean' | translate
									"
									formControlName="count"
								>
									<nz-option
										[nzValue]="true"
										[nzLabel]="'common_true' | translate"
									></nz-option>
									<nz-option
										[nzValue]="false"
										[nzLabel]="'common_false' | translate"
									></nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Count -->
					</div>
				</div>

				<nz-divider
					*ngIf="!rolePermission.isNew() && !readOnly"
				></nz-divider>
				<div *ngIf="!rolePermission.isNew()" class="row">
					<div class="col-12 col-md-6">
						<!-- _Id -->
						<div class="mb-4">
							<h4>{{ 'role-permission_-id' | translate }}</h4>
							<div>{{ rolePermission.props._id }}</div>
						</div>
						<!-- /_Id -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Created At -->
						<div class="mb-4">
							<h4>
								{{ 'role-permission_created-at' | translate }}
							</h4>
							<div>
								{{
									rolePermission.props.created_at
										| date: 'medium'
								}}
							</div>
						</div>
						<!-- /Created At -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Role -->
						<div class="mb-4">
							<h4>{{ 'role-permission_role' | translate }}</h4>
							<div *ngIf="rolePermission.roleExists()">
								<a
									[routerLink]="[
										'/role',
										rolePermission.props.role.getId()
									]"
								>
									{{ rolePermission.props.role.getLabel() }}
								</a>
							</div>
						</div>
						<!-- /Role -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Collection -->
						<div class="mb-4">
							<h4>
								{{ 'role-permission_collection' | translate }}
							</h4>
							<div>{{ rolePermission.props.collection }}</div>
						</div>
						<!-- /Collection -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Create -->
						<div class="mb-4">
							<h4>{{ 'role-permission_create' | translate }}</h4>
							<div>
								<span
									*ngIf="
										rolePermission.props.create === true ||
										rolePermission.props.create === false
									"
								>
									{{
										(rolePermission.props.create
											? 'common_true'
											: 'common_false'
										) | translate
									}}
								</span>
							</div>
						</div>
						<!-- /Create -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Read -->
						<div class="mb-4">
							<h4>{{ 'role-permission_read' | translate }}</h4>
							<div>
								<span
									*ngIf="
										rolePermission.props.read === true ||
										rolePermission.props.read === false
									"
								>
									{{
										(rolePermission.props.read
											? 'common_true'
											: 'common_false'
										) | translate
									}}
								</span>
							</div>
						</div>
						<!-- /Read -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Update -->
						<div class="mb-4">
							<h4>{{ 'role-permission_update' | translate }}</h4>
							<div>
								<span
									*ngIf="
										rolePermission.props.update === true ||
										rolePermission.props.update === false
									"
								>
									{{
										(rolePermission.props.update
											? 'common_true'
											: 'common_false'
										) | translate
									}}
								</span>
							</div>
						</div>
						<!-- /Update -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Delete -->
						<div class="mb-4">
							<h4>{{ 'role-permission_delete' | translate }}</h4>
							<div>
								<span
									*ngIf="
										rolePermission.props.delete === true ||
										rolePermission.props.delete === false
									"
								>
									{{
										(rolePermission.props.delete
											? 'common_true'
											: 'common_false'
										) | translate
									}}
								</span>
							</div>
						</div>
						<!-- /Delete -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Search -->
						<div class="mb-4">
							<h4>{{ 'role-permission_search' | translate }}</h4>
							<div>
								<span
									*ngIf="
										rolePermission.props.search === true ||
										rolePermission.props.search === false
									"
								>
									{{
										(rolePermission.props.search
											? 'common_true'
											: 'common_false'
										) | translate
									}}
								</span>
							</div>
						</div>
						<!-- /Search -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Count -->
						<div class="mb-4">
							<h4>{{ 'role-permission_count' | translate }}</h4>
							<div>
								<span
									*ngIf="
										rolePermission.props.count === true ||
										rolePermission.props.count === false
									"
								>
									{{
										(rolePermission.props.count
											? 'common_true'
											: 'common_false'
										) | translate
									}}
								</span>
							</div>
						</div>
						<!-- /Count -->
					</div>
				</div>
			</div>
		</nz-card>
	</div>
</form>
