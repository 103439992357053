import { Component } from '@angular/core';
import { EntitySelectComponent } from '@app/abstracts';
import { ErrorService } from '@app/services';
import { RolePermissionSearchParams } from '../role-permission-search-params';
import { RolePermissionService } from '../role-permission.service';
import { RolePermission } from '../role-permission';
import { QuickFormService } from '@app/plugins/quick-form';
@Component({
	selector: 'hpf-role-permission-select',
	templateUrl:
		'../../../../abstracts/entity-select/entity-select.component.html',
})
export class RolePermissionSelectComponent extends EntitySelectComponent<
	RolePermission
> {
	/**
	 * Constructor
	 * @param {RolePermissionService} rolePermissionService
	 * @param {ErrorService} errorService
	 */
	constructor(
		private rolePermissionService: RolePermissionService,
		protected errorService: ErrorService,
		protected quickFormService: QuickFormService
	) {
		super(errorService, quickFormService);
	}
	/** @inheritDoc */
	protected async getList(): Promise<RolePermission[]> {
		const params = new RolePermissionSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		return (await this.rolePermissionService.list(params.toObject())).items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<RolePermission> {
		return await this.rolePermissionService.get(id);
	}
}
