import {Injectable} from '@angular/core';
import {About, AboutInterface, AboutPayload} from './about';
import {AboutSearchParamsInterface} from './about-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class AboutService extends BaseModelService< About, AboutInterface, AboutPayload, AboutSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'about';
  }
  /** @inheritDoc */
  protected new(object: AboutInterface): About {
    return new About(object);
  }
}
