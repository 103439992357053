class ModelLink {
	isCategory: boolean;
	label: string;
	link: string[];
	model: string;
}
export const ModelsLinks: ModelLink[] = [
	{
		label: 'Point Of Interest',
		link: ['/point-of-interest'],
		model: 'point_of_interest',
		isCategory: false,
	},
	{
		label: 'Testing',
		link: ['/point-of-interest'],
		model: 'point_of_interest',
		isCategory: true,
	},
	{
		label: 'User',
		link: ['/user'],
		model: 'user',
		isCategory: false,
	},
	{
		label: 'Video',
		link: ['/video'],
		model: 'video',
		isCategory: false,
	},
	{
		label: 'Audio',
		link: ['/audio'],
		model: 'audio',
		isCategory: false,
	},
	{
		label: 'Text Translated',
		link: ['/text-translated'],
		model: 'text_translated',
		isCategory: false,
	},
	{
		label: 'Image',
		link: ['/image'],
		model: 'image',
		isCategory: false,
	},
	{
		label: 'Language',
		link: ['/language'],
		model: 'language',
		isCategory: false,
	},
	{
		label: 'Category',
		link: ['/category'],
		model: 'category',
		isCategory: false,
	},
	{
		label: 'Route',
		link: ['/route'],
		model: 'route',
		isCategory: false,
	},
	{
		label: 'Plateau',
		link: ['/plateau'],
		model: 'plateau',
		isCategory: false,
	},
	{
		label: 'Audio Description',
		link: ['/audio-description'],
		model: 'audio_description',
		isCategory: false,
	},
	{
		label: 'Diaporama',
		link: ['/diaporama'],
		model: 'diaporama',
		isCategory: false,
	},
	{
		label: 'About',
		link: ['/about'],
		model: 'about',
		isCategory: false,
	},
	{
		label: 'Credit',
		link: ['/credit'],
		model: 'credit',
		isCategory: false,
	},
	{
		label: 'Marker',
		link: ['/marker'],
		model: 'marker',
		isCategory: false,
	},
];
