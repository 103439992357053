<nz-modal
    [(nzVisible)]="deleteModal"
    (nzOnCancel)="deleteModal = false"
    (nzOnOk)="onDelete()"
    [nzTitle]="'common_deletion' | translate"
    [nzContent]="'common_delete-one' | translate"
    [nzOkLoading]="deleting"
></nz-modal>
<form nz-form
      class="video-form w-100"
      *ngIf="form"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      [nzAutoTips]="explainErrorsService.autoTips">
    <nz-affix>
        <div class="py-4 border-bottom bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex align-items-center justify-content-between">
                        <!-- Title -->
                        <div>
                            <h1 class="mb-0">
                                <span *ngIf="video.isNew()">{{ 'video_create-form-title' | translate }}</span>
                                <span *ngIf="!video.isNew()">{{ video.getLabel() }}</span>
                            </h1>
                            <small *ngIf="!video.isNew()" class="text-black-50">{{ 'common_id' | translate: { id : video.getId() } }}</small>
                        </div>
                        <!-- /Title -->
                        
                        <!-- Actions -->
                        <div>
                            <button
                                nz-button
                                [disabled]="form.invalid"
                                [nzLoading]="saving"
                                nzType="primary"
                                *appRestrictedByScope="{
                                    model: 'video',
                                    action: 'update'
                                }"
                            >
                                {{ 'common_save' | translate }}
                            </button>
                            <ng-container *appRestrictedByScope="{
                                model: 'video',
                                action: 'delete'
                            }">
                                <button
                                    *ngIf="!video.isNew() && deletable"
                                    class="ml-4"
                                    nz-button
                                    nzShape="circle"
                                    [title]="'common_delete' | translate"
                                    [nzLoading]="deleting"
                                    (click)="deleteModal = true"
                                    type="button"
                                >
                                    <i nz-icon nzType="delete"></i>
                                </button>
                            </ng-container>
                        </div>
                        <!-- /Actions -->
                    </div>
                </div>
            </div>
        </div>
    </nz-affix>
    <div class="container">
    <nz-card class="round-border my-5">
    <div *ngIf="loading" class="row">
        <div class="col-12">
            <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
    </div>
    <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">
            <div class="col-12 col-md-6">
                <!-- Name -->
                <nz-form-item>
                    <h4>{{ 'video_name' | translate }}</h4>
                    <nz-form-control>
                        <input nz-input
                               class="w-100"
                               type="text"
                               [placeholder]="'common_value-string' | translate"
                               formControlName="name">
                    </nz-form-control>
                </nz-form-item>
                <!-- /Name -->
            </div>
        
                <!-- Uri -->
                <div
             
                class="col-12 col-md-6 d-flex align-items-center"
            >
                <!-- Uri -->
                <input type="file" name="fileUpload" (input)="fileUploaded($event)" id="fileUpload" class="form-control"> 
                <!-- /Uri -->
            </div>
                <!-- /Uri -->
            
          
         
            <div class="col-12 col-md-6">
                <!-- Copyright -->
               
                    <h4>{{ 'video_copyright' | translate }}</h4>
                 
                    <app-language-tab [name]="'copyright'" [model]="video"></app-language-tab>
                        
                <!-- /Copyright -->
            </div>
        </div>

        <nz-divider *ngIf="!video.isNew() && !readOnly"></nz-divider>
        <div *ngIf="!video.isNew()" class="row">
            <div class="col-12 col-md-6" >
                <!-- _Id -->
                <div class="mb-4">
                    <h4>{{ 'video_-id' | translate }}</h4>
                    <div>{{ video.props._id }}</div>
                </div>
                <!-- /_Id -->
            </div>
            <div class="col-12 col-md-6" >
                <!-- Created At -->
                <div class="mb-4">
                    <h4>{{ 'video_created-at' | translate }}</h4>
                    <div>{{ video.props.created_at | date:'medium' }}</div>
                </div>
                <!-- /Created At -->
            </div>
            <div class="col-12 col-md-6" >
                <!-- Owner -->
                <div class="mb-4">
                    <h4>{{ 'video_owner' | translate }}</h4>
                    <div *ngIf="video.ownerExists()">
                        <a [routerLink]="['/user', video.props.owner.getId()]">
                            {{ video.props.owner.getLabel() }}
                        </a>
                    </div>
                </div>
                <!-- /Owner -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Name -->
                <div class="mb-4">
                    <h4>{{ 'video_name' | translate }}</h4>
                    <div>{{ video.props.name }}</div>
                </div>
                <!-- /Name -->
            </div>
            <div class="col-12 col-md-6">
                <!-- Media preview -->
                <div class="mb-4">
                    <h4>{{ 'video_preview' | translate }}</h4>
                    <video  controls>
                        <source src="{{video.getUri()}}">
                   
                      Your browser does not support the video tag.
                      </video>
                </div>
                <!-- /Media preview -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Audio -->
                <div class="mb-4">
                    <h4>{{ 'video_audio' | translate }}</h4>
                    <div *ngIf="video.audioExists()">
                        <div *ngFor="let item of video.props.audio">
                            <a class="mr-2" [routerLink]="['/audio', item.getId()]">
                                {{ item.getLabel() }}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- /Audio -->
            </div>
           
            
        </div>
    </div>
    </nz-card>
    </div>
</form>