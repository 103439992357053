
import {Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChildren, QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService, ExplainErrorsService, SessionService} from '@app/services';
import { environment } from '@env/environment';
import {AudioDescription} from '../audio-description';
import {AudioDescriptionService} from '../audio-description.service';
import { LanguageTabComponent } from '@app/models/language';
@Component({
  selector: 'hpf-audio-description-form',
  templateUrl: './audio-description-form.component.html'
})
export class AudioDescriptionFormComponent implements OnInit, OnDestroy {
  /** The model subscription */
  private modelSubscription: RxJsSubscription;
  /** The audio description to inject in the form */
  @Input() audioDescription: AudioDescription;
  /** Called the save button is clicked for a new instance */
  @Output() create = new EventEmitter<AudioDescription>();
  /** Called the save button is clicked for an existing instance */
  @Output() update = new EventEmitter<void>();
  /** Called the delete button is clicked */
  @Output() delete = new EventEmitter<void>();
  /** Set loading state of the form */
  @Input() loading = false;
  /** Enable the deletion button */
  @Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
  /** Denotes if deltion in progress */
  deleting = false;
  /** Show delete modal */
  deleteModal = false;
  /** The form group to use */
  form: FormGroup;
  /** Denotes if the form is pending */
  saving = false;
  /** Check permission of update **/
  readOnly = false;
	@ViewChildren(LanguageTabComponent) languageTabs: QueryList<
		LanguageTabComponent
	>;
  /** Constructor */
  constructor(private formBuilder: FormBuilder,
              private errorService: ErrorService,
              public explainErrorsService: ExplainErrorsService,
              private audioDescriptionService: AudioDescriptionService,
              private sessionService: SessionService) {
  }
  /** Init */
  async ngOnInit() {
    this.readOnly = !(await this.sessionService.checkPermission(
			'audio_description',
			'update'
		));

    // Init model
    if(!this.audioDescription) this.audioDescription = new AudioDescription();

    // Create form
    this.form = this.formBuilder.group({
    name: new FormControl(this.audioDescription.props.name, [Validators.required]),
    audio: new FormControl(this.audioDescription.props.audio, [Validators.required]),

    
    });
    // Update form when model loads or changes
    this.modelSubscription = this.audioDescription.subscribe(() => {
        this.updateForm();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.modelSubscription.unsubscribe();
  }
  /** Called on form submit */
  async onSubmit(): Promise<void> {
    // Saving flag
    this.saving = true;
    try {
        // Update model
        this.updateModel();
		for (let element of this.languageTabs) {
				await element.submit();
			}
        // Creation or update ?
        if (this.audioDescription.isNew()) {
            // Creation
            const audioDescription: AudioDescription = await this.audioDescriptionService.create(this.audioDescription.toPayload());
            this.create.next(audioDescription);
        } else {
            // Update
            await this.audioDescriptionService.update(this.audioDescription.getId(), this.audioDescription.toPayload());
            this.update.next();
        }
    } catch (error) {
        this.errorService.handle(error);
    }
    // Saving flag
    this.saving = false
  }
  /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.audioDescription.props[key] = this.form.get(key).value;
    }
  }
  /** Update inputs values from models properties */
  private updateForm(): void {
    this.form.setValue({
      'name': this.audioDescription.props.name,   
      'audio': this.audioDescription.props.audio,   

         
    });
  }
  /** Called on deletion */
  onDelete(): void {
    this.deleting = true;
    this.audioDescriptionService.remove(this.audioDescription.getId())
      .then(() => {
        this.delete.next();
      })
      .catch((error) => this.errorService.handle(error))
      .then(() => this.deleting = false);
  }
}
