import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {User} from '../../user';

@Component({
  selector: 'hpf-user-create',
  templateUrl: './user-create.component.html'
})
export class UserCreateComponent implements OnInit {
  /** The user */
  user = new User();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the user is created */
  onCreate(user: User): void {
    this.router.navigateByUrl(`/user/${user.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
