import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import { Plateau, PlateauInterface } from '../plateau/plateau';
import { Category, CategoryInterface } from '../category/category';
import {
	TextTranslated,
	TextTranslatedInterface,
} from '../text-translated/text-translated';
import { Diaporama, DiaporamaInterface } from '../diaporama/diaporama';
import { Image, ImageInterface } from '../image/image';
import { Language } from '../language/language';
export interface PointOfInterestInterface extends BaseModelInterface {
	created_at: number | Date;
	owner: string | User | UserInterface;
	name: string;
	plateau: string | Plateau | PlateauInterface;
	categories?: string | Category | CategoryInterface;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	description?: (string | TextTranslated | TextTranslatedInterface)[];
	url?: string;
	diaporama?: string | Diaporama | DiaporamaInterface;
	top_image: string | Image | ImageInterface;
	thumbnail?: string | Image | ImageInterface;
	published: boolean;
	deleted: boolean;
}
export interface PointOfInterestPayload {
	name: string;
	plateau: string | Plateau | PlateauInterface;
	categories?: string | Category | CategoryInterface;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	description?: (string | TextTranslated | TextTranslatedInterface)[];
	url?: string;
	diaporama?: string | Diaporama | DiaporamaInterface;
	top_image: string | Image | ImageInterface;
	thumbnail?: string | Image | ImageInterface;
	published: boolean;
}
type PointOfInterestPayloadKey = keyof PointOfInterestPayload;
export class PointOfInterest extends BaseModel<
	PointOfInterestInterface,
	PointOfInterestPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	getTitle(): string {
		var regex = /(<([^>]+)>)/gi;

		let lng = localStorage.getItem('lang');
		if (!this.titleExists()) return '';
		var tt = this.props.title.find(function (params: TextTranslated) {
			var l = params.props.lang as Language;
			return lng.toLowerCase() == l.props.name.toLowerCase();
		}) as TextTranslated;
		if (tt == null) {
			return '';
		}
		return tt.props.text.replace(regex, '');
	}
	getCategoryTitle(): string {
		if (!this.categoriesExists()) {
			return '';
		}
		if (this.props.categories instanceof Category) {
			var s = this.props.categories as Category;
			return s.getTitle();
		}
		return '';
	}
	getPlateauTitle(): string {
		if (!this.plateauExists()) {
			return '';
		}
		if (this.props.plateau instanceof Plateau) {
			var s = this.props.plateau as Plateau;
			return s.getTitle();
		}
		return '';
	}
	/** Denotes if the instance of owner has been populated */
	ownerExists(): boolean {
		return (
			!!this.props &&
			this.props.owner instanceof User &&
			this.props.owner.exists()
		);
	}
	/** Denotes if the instance of plateau has been populated */
	plateauExists(): boolean {
		return (
			!!this.props &&
			this.props.plateau instanceof Plateau &&
			this.props.plateau.exists()
		);
	}
	/** Denotes if the instance of categories has been populated */
	categoriesExists(): boolean {
		return (
			!!this.props &&
			this.props.categories instanceof Category &&
			this.props.categories.exists()
		);
	}
	/** Denotes if the instance of title has been populated */
	titleExists(): boolean {
		return (
			!!this.props &&
			this.props.title instanceof Array &&
			(<TextTranslated[]>this.props.title).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Denotes if the instance of description has been populated */
	descriptionExists(): boolean {
		return (
			!!this.props &&
			this.props.description instanceof Array &&
			(<TextTranslated[]>this.props.description).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Denotes if the instance of diaporama has been populated */
	diaporamaExists(): boolean {
		return (
			!!this.props &&
			this.props.diaporama instanceof Diaporama &&
			this.props.diaporama.exists()
		);
	}
	/** Denotes if the instance of top image has been populated */
	topImageExists(): boolean {
		return (
			!!this.props &&
			this.props.top_image instanceof Image &&
			this.props.top_image.exists()
		);
	}
	/** Denotes if the instance of thumbnail has been populated */
	thumbnailExists(): boolean {
		return (
			!!this.props &&
			this.props.thumbnail instanceof Image &&
			this.props.thumbnail.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: PointOfInterestInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.owner === 'object') {
			this.props.owner = new User(<UserInterface>this.props.owner);
		}
		if (typeof this.props.plateau === 'object') {
			this.props.plateau = new Plateau(
				<PlateauInterface>this.props.plateau
			);
		}
		if (
			this.props.categories !== null &&
			typeof this.props.categories === 'object'
		) {
			this.props.categories = new Category(
				<CategoryInterface>this.props.categories
			);
		}
		if (this.props.title instanceof Array) {
			this.props.title = (<TextTranslatedInterface[]>(
				this.props.title
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (this.props.description instanceof Array) {
			this.props.description = (<TextTranslatedInterface[]>(
				this.props.description
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (
			this.props.diaporama !== null &&
			typeof this.props.diaporama === 'object'
		) {
			this.props.diaporama = new Diaporama(
				<DiaporamaInterface>this.props.diaporama
			);
		}
		if (typeof this.props.top_image === 'object') {
			this.props.top_image = new Image(
				<ImageInterface>this.props.top_image
			);
		}
		if (
			this.props.thumbnail !== null &&
			typeof this.props.thumbnail === 'object'
		) {
			this.props.thumbnail = new Image(
				<ImageInterface>this.props.thumbnail
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): PointOfInterestInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.categories === 'undefined') {
			props.categories = null;
		}
		if (typeof props.title === 'undefined') {
			props.title = [];
		}
		if (typeof props.description === 'undefined') {
			props.description = [];
		}
		if (typeof props.url !== 'string' || props.url.length === 0) {
			props.url = null;
		}
		if (typeof props.diaporama === 'undefined') {
			props.diaporama = null;
		}
		if (typeof props.thumbnail === 'undefined') {
			props.thumbnail = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.owner instanceof User) {
			props.owner = props.owner.toObject();
		}
		if (props.plateau instanceof Plateau) {
			props.plateau = props.plateau.toObject();
		}
		if (props.categories !== null && props.categories instanceof Category) {
			props.categories = props.categories.toObject();
		}
		if (this.props.title instanceof Array) {
			props.title = (<TextTranslated[]>props.title).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		if (this.props.description instanceof Array) {
			props.description = (<TextTranslated[]>props.description).map(
				(item) => {
					return item instanceof TextTranslated
						? item.toObject()
						: item;
				}
			);
		}
		if (props.diaporama !== null && props.diaporama instanceof Diaporama) {
			props.diaporama = props.diaporama.toObject();
		}
		if (props.top_image instanceof Image) {
			props.top_image = props.top_image.toObject();
		}
		if (props.thumbnail !== null && props.thumbnail instanceof Image) {
			props.thumbnail = props.thumbnail.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): PointOfInterestPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as PointOfInterestInterface);
		payload.plateau = payload.plateau
			? this.extractId(payload.plateau)
			: null;
		payload.categories = payload.categories
			? this.extractId(payload.categories)
			: null;
		payload.title = payload.title
			? payload.title.map((i) => this.extractId(i))
			: null;
		payload.description = payload.description
			? payload.description.map((i) => this.extractId(i))
			: null;
		payload.diaporama = payload.diaporama
			? this.extractId(payload.diaporama)
			: null;
		payload.top_image = payload.top_image
			? this.extractId(payload.top_image)
			: null;
		payload.thumbnail = payload.thumbnail
			? this.extractId(payload.thumbnail)
			: null;
		return payload as PointOfInterestPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): PointOfInterestPayloadKey[] {
		return [
			'name',
			'plateau',
			'categories',
			'title',
			'description',
			'url',
			'diaporama',
			'top_image',
			'thumbnail',
			'published',
		];
	}
}
