import { Component } from '@angular/core';
import { EntitySelectComponent } from '@app/abstracts';
import { ErrorService } from '@app/services';
import { ActivityLogSearchParams } from '../activity-log-search-params';
import { ActivityLogService } from '../activity-log.service';
import { ActivityLog } from '../activity-log';
import { QuickFormService } from '@app/plugins/quick-form';
@Component({
	selector: 'hpf-activity-log-select',
	templateUrl:
		'../../../abstracts/entity-select/entity-select.component.html',
})
export class ActivityLogSelectComponent extends EntitySelectComponent<
	ActivityLog
> {
	/**
	 * Constructor
	 * @param {ActivityLogService} activityLogService
	 * @param {ErrorService} errorService
	 */
	constructor(
		private activityLogService: ActivityLogService,
		protected errorService: ErrorService,
		protected quickFormService: QuickFormService
	) {
		super(errorService, quickFormService);
	}
	/** @inheritDoc */
	protected async getList(): Promise<ActivityLog[]> {
		const params = new ActivityLogSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		return (await this.activityLogService.list(params.toObject())).items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<ActivityLog> {
		return await this.activityLogService.get(id);
	}
}
