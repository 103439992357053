<hpf-user-form
	[user]="user"
	[loading]="user.isNew()"
	(update)="onUpdate()"
	(delete)="onDelete()"
></hpf-user-form>
<div class="container">
	<div
		class="row"
		*appRestrictedByScope="{
			model: 'point_of_interest',
			action: 'search'
		}"
	>
		<div class="py-4 pb-5 col-12">
			<div class="py-3">
				<h3>
					<a
						[routerLink]="['/point-of-interest']"
						[queryParams]="
							pointOfInterestAsOwnerSearchParams.toObject()
						"
					>
						{{ 'user_point_of_interest-as-owner' | translate }}
					</a>
				</h3>
			</div>
			<hpf-point-of-interest-table
				[searchParams]="pointOfInterestAsOwnerSearchParams"
				(select)="onSelectPointOfInterestAsOwner($event)"
			>
			</hpf-point-of-interest-table>
		</div>
	</div>
	<div
		class="row"
		*appRestrictedByScope="{
			model: 'video',
			action: 'search'
		}"
	>
		<div class="py-4 pb-5 col-12">
			<div class="py-3">
				<h3>
					<a
						[routerLink]="['/video']"
						[queryParams]="videoAsOwnerSearchParams.toObject()"
					>
						{{ 'user_video-as-owner' | translate }}
					</a>
				</h3>
			</div>
			<hpf-video-table
				[searchParams]="videoAsOwnerSearchParams"
				(select)="onSelectVideoAsOwner($event)"
			>
			</hpf-video-table>
		</div>
	</div>
	<div
		class="row"
		*appRestrictedByScope="{
			model: 'audio',
			action: 'search'
		}"
	>
		<div class="py-4 pb-5 col-12">
			<div class="py-3">
				<h3>
					<a
						[routerLink]="['/audio']"
						[queryParams]="audioAsOwnerSearchParams.toObject()"
					>
						{{ 'user_audio-as-owner' | translate }}
					</a>
				</h3>
			</div>
			<hpf-audio-table
				[searchParams]="audioAsOwnerSearchParams"
				(select)="onSelectAudioAsOwner($event)"
			>
			</hpf-audio-table>
		</div>
	</div>

	<div
		class="row"
		*appRestrictedByScope="{
			model: 'image',
			action: 'search'
		}"
	>
		<div class="py-4 pb-5 col-12">
			<div class="py-3">
				<h3>
					<a
						[routerLink]="['/image']"
						[queryParams]="imageAsOwnerSearchParams.toObject()"
					>
						{{ 'user_image-as-owner' | translate }}
					</a>
				</h3>
			</div>
			<hpf-image-table
				[searchParams]="imageAsOwnerSearchParams"
				(select)="onSelectImageAsOwner($event)"
			>
			</hpf-image-table>
		</div>
	</div>
	<div
		class="row"
		*appRestrictedByScope="{
			model: 'route',
			action: 'search'
		}"
	>
		<div class="py-4 pb-5 col-12">
			<div class="py-3">
				<h3>
					<a
						[routerLink]="['/route']"
						[queryParams]="routeAsOwnerSearchParams.toObject()"
					>
						{{ 'user_route-as-owner' | translate }}
					</a>
				</h3>
			</div>
			<hpf-route-table
				[searchParams]="routeAsOwnerSearchParams"
				(select)="onSelectRouteAsOwner($event)"
			>
			</hpf-route-table>
		</div>
	</div>

	<hpf-activity-history
		*ngIf="historySearchParams"
		[historySearchParams]="historySearchParams"
	></hpf-activity-history>
</div>
