import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-point-of-interest',
  templateUrl: './point-of-interest.component.html'
})
export class PointOfInterestComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
