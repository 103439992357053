import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import {
	TextTranslated,
	TextTranslatedInterface,
} from '../text-translated/text-translated';
import { environment } from '@env/environment';
export interface ImageInterface extends BaseModelInterface {
	created_at: number | Date;
	owner: string | User | UserInterface;
	uri: string;
	name: string;
	copyright?: (string | TextTranslated | TextTranslatedInterface)[];
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	fileUpload: {};
	ext: string;
}
export interface ImagePayload {
	uri: string;
	name: string;
	copyright?: (string | TextTranslated | TextTranslatedInterface)[];
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	fileUpload: {};
}
type ImagePayloadKey = keyof ImagePayload;
export class Image extends BaseModel<ImageInterface, ImagePayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}

	getUri(): string {
		return `${environment.api.uri}/image/view/${this.props._id}`;
	}
	getThumbnail(): string {
		return `${environment.api.uri}/image/thumbnail/${this.props._id}`;
	}

	getMediaType(): string {
		return 'image';
	}
	/** Denotes if the instance of owner has been populated */
	ownerExists(): boolean {
		return (
			!!this.props &&
			this.props.owner instanceof User &&
			this.props.owner.exists()
		);
	}
	/** Denotes if the instance of copyright has been populated */
	copyrightExists(): boolean {
		return (
			!!this.props &&
			this.props.copyright instanceof Array &&
			(<TextTranslated[]>this.props.copyright).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: ImageInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.owner === 'object') {
			this.props.owner = new User(<UserInterface>this.props.owner);
		}
		if (this.props.copyright instanceof Array) {
			this.props.copyright = (<TextTranslatedInterface[]>(
				this.props.copyright
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (this.props.title instanceof Array) {
			this.props.title = (<TextTranslatedInterface[]>(
				this.props.title
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ImageInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.copyright === 'undefined') {
			props.copyright = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.owner instanceof User) {
			props.owner = props.owner.toObject();
		}
		if (this.props.copyright instanceof Array) {
			props.copyright = (<TextTranslated[]>props.copyright).map(
				(item) => {
					return item instanceof TextTranslated
						? item.toObject()
						: item;
				}
			);
		}
		if (this.props.title instanceof Array) {
			props.title = (<TextTranslated[]>props.title).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ImagePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ImageInterface);
		payload.copyright = payload.copyright
			? payload.copyright.map((i) => this.extractId(i))
			: null;
		payload.title = payload.title
			? payload.title.map((i) => this.extractId(i))
			: null;
		return payload as ImagePayload;
	}

	toFormData(): FormData {
		var payload = this.toPayload();
		const formData = new FormData();

		for (const key in payload) {
			formData.append(key, payload[key]);
		}

		return formData;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ImagePayloadKey[] {
		return ['name', 'copyright', 'title', 'fileUpload'];
	}
}
