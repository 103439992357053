import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmailConfirmationService {
	/** Base route for this module */
	private _uri = `${environment.api.uri}/email-confirmation`;

	/** Constructor */
	constructor(private http: HttpClient) {}

	/** Confirm the email address */
	async confirm(userId: string, confirmationCode: string): Promise<void> {
		const body = {
			_id: userId,
			code: confirmationCode,
		};
		await this.http.put(this._uri, body).toPromise();
	}
}
