<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<form
	nz-form
	class="user-form w-100"
	*ngIf="form"
	[formGroup]="form"
	(ngSubmit)="onSubmit()"
	[nzAutoTips]="explainErrorsService.autoTips"
>
	<nz-affix>
		<div class="py-4 border-bottom bg-white">
			<div class="container">
				<div class="row">
					<div
						class="col-12 d-flex align-items-center justify-content-between"
					>
						<!-- Title -->
						<div>
							<h1 class="mb-0">
								<span *ngIf="user.isNew()">{{
									'user_create-form-title' | translate
								}}</span>
								<span *ngIf="!user.isNew()">{{
									user.getLabel()
								}}</span>
							</h1>
							<small
								*ngIf="!user.isNew()"
								class="text-black-50"
								>{{
									'common_id'
										| translate: { id: user.getId() }
								}}</small
							>
						</div>
						<!-- /Title -->

						<!-- Actions -->
						<div>
							<button
								nz-button
								[disabled]="form.invalid"
								[nzLoading]="saving"
								nzType="primary"
								*appRestrictedByScope="{
									model: 'user',
									action: 'update'
								}"
							>
								{{ 'common_save' | translate }}
							</button>
							<ng-container
								*appRestrictedByScope="{
									model: 'user',
									action: 'delete'
								}"
							>
								<button
									*ngIf="!user.isNew() && deletable"
									class="ml-4"
									nz-button
									nzShape="circle"
									[title]="'common_delete' | translate"
									[nzLoading]="deleting"
									(click)="deleteModal = true"
									type="button"
								>
									<i nz-icon nzType="delete"></i>
								</button>
							</ng-container>
						</div>
						<!-- /Actions -->
					</div>
				</div>
			</div>
		</div>
	</nz-affix>
	<div class="container">
		<nz-card class="round-border my-5">
			<div *ngIf="loading" class="row">
				<div class="col-12">
					<nz-skeleton
						class="no-header"
						[nzActive]="true"
					></nz-skeleton>
				</div>
			</div>
			<div *ngIf="!loading">
				<div class="row" *ngIf="!readOnly">
					<div class="col-12 col-md-6">
						<!-- Name -->
						<nz-form-item>
							<h4>{{ 'user_name' | translate }}</h4>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'common_value-string' | translate
									"
									formControlName="name"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Name -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Email -->
						<nz-form-item>
							<h4>{{ 'user_email' | translate }}</h4>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="email"
									[placeholder]="
										'common_value-string' | translate
									"
									formControlName="email"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Email -->
					</div>
					<div class="col-12 col-md-6" *ngIf="user.isNew()">
						<!-- Password -->
						<nz-form-item>
							<h4>{{ 'user_password' | translate }}</h4>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="password"
									autocomplete="new-password"
									[placeholder]="
										'common_value-string' | translate
									"
									formControlName="password"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Password -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Role -->
						<nz-form-item>
							<h4>{{ 'user_role' | translate }}</h4>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'common_value-string' | translate
									"
									formControlName="role"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Role -->
					</div>
				</div>

				<nz-divider *ngIf="!user.isNew() && !readOnly"></nz-divider>
				<div *ngIf="!user.isNew()" class="row">
					<div class="col-12 col-md-6">
						<!-- _Id -->
						<div class="mb-4">
							<h4>{{ 'user_-id' | translate }}</h4>
							<div>{{ user.props._id }}</div>
						</div>
						<!-- /_Id -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Created At -->
						<div class="mb-4">
							<h4>{{ 'user_created-at' | translate }}</h4>
							<div>
								{{ user.props.created_at | date: 'medium' }}
							</div>
						</div>
						<!-- /Created At -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Name -->
						<div class="mb-4">
							<h4>{{ 'user_name' | translate }}</h4>
							<div>{{ user.props.name }}</div>
						</div>
						<!-- /Name -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Email -->
						<div class="mb-4">
							<h4>{{ 'user_email' | translate }}</h4>
							<div>{{ user.props.email }}</div>
						</div>
						<!-- /Email -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Role -->
						<div class="mb-4">
							<h4>{{ 'user_role' | translate }}</h4>
							<div>{{ user.props.role }}</div>
						</div>
						<!-- /Role -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Last Connected At -->
						<div class="mb-4">
							<h4>{{ 'user_last-connected-at' | translate }}</h4>
							<div>
								{{
									user.props.last_connected_at
										| date: 'medium'
								}}
							</div>
						</div>
						<!-- /Last Connected At -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Email Confirmed -->
						<div class="mb-4">
							<h4>{{ 'user_email-confirmed' | translate }}</h4>
							<div>
								<span
									*ngIf="
										user.props.email_confirmed === true ||
										user.props.email_confirmed === false
									"
								>
									{{
										(user.props.email_confirmed
											? 'common_true'
											: 'common_false'
										) | translate
									}}
								</span>
							</div>
						</div>
						<!-- /Email Confirmed -->
					</div>
				</div>
			</div>
		</nz-card>
	</div>
</form>
