import {Injectable} from '@angular/core';
import {TextTranslated, TextTranslatedInterface, TextTranslatedPayload} from './text-translated';
import {TextTranslatedSearchParamsInterface} from './text-translated-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class TextTranslatedService extends BaseModelService< TextTranslated, TextTranslatedInterface, TextTranslatedPayload, TextTranslatedSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'text-translated';
  }
  /** @inheritDoc */
  protected new(object: TextTranslatedInterface): TextTranslated {
    return new TextTranslated(object);
  }
}
