<div
	*ngIf="media?.props?.uri"
	class="d-flex justify-content-start align-items-center h-100 flex-wrap"
>
	<div>
		<div *ngIf="media.getMediaType() === 'video'">
			<video controls>
				<source [src]="bypassUrl(getSourceUrl())" />
			</video>
		</div>
		<div *ngIf="media.getMediaType() === 'image'">
			<img [src]="bypassUrl(getSourceUrl())" />
		</div>
		<div *ngIf="media.getMediaType() === 'audio'">
			<audio controls>
				<source [src]="bypassUrl(getSourceUrl())" />
			</audio>
		</div>
	</div>
</div>
