<div class="container">
	<div class="row justify-content-center py-5">
		<div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
			<form class="d-block border p-4">
				<nz-form-control class="w-100 mb-3">
					<input
						name="email"
						nz-input
						placeholder="{{ 'session_email' | translate }}"
						[(ngModel)]="email"
					/>
				</nz-form-control>
				<nz-form-control class="w-100 mb-3">
					<input
						name="password"
						nz-input
						placeholder="{{ 'session_password' | translate }}"
						type="password"
						[(ngModel)]="password"
					/>
				</nz-form-control>
				<div class="w-100 d-flex justify-content-end">
					<button
						nz-button
						nzType="primary"
						[disabled]="!(email && password)"
						(click)="onSignIn()"
					>
						{{ 'session_sign-in' | translate }}
					</button>
				</div>
				<div class="w-100 d-flex justify-content-center">
					<button
						nz-button
						nzType="link"
						(click)="router.navigate(['/session/lost-password'])"
					>
						{{ 'session_lost-password' | translate }}
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
