import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { Language } from '../../language';
import { LanguageService } from '../../language.service';
import { AudioSearchParams } from '../../../audio/audio-search-params';
import { Audio } from '../../../audio/audio';

import { VideoSearchParams } from '../../../video/video-search-params';
import { Video } from '../../../video/video';
import { ActivityLogSearchParams } from '@app/plugins/activity';
@Component({
	selector: 'hpf-language-edit',
	templateUrl: './language-edit.component.html',
})
export class LanguageEditComponent implements OnInit, OnDestroy {
	/** The language */
	language = new Language();
	/**	History search params */
	historySearchParams: ActivityLogSearchParams;
	/** The language */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** The filter for audio as lang */
	audioAsLangSearchParams = new AudioSearchParams();
	/** The filter for poi text as lang */

	/** The filter for video as lang */
	videoAsLangSearchParams = new VideoSearchParams();
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private languageService: LanguageService
	) {}
	/** Init */
	ngOnInit() {
		// Get the language after reading the id
		this.idSubscription = this.route.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
			// Filter by lang for audio
			this.audioAsLangSearchParams.fromObject({ lang: this.id });
			// Filter by lang for poi text

			// Filter by lang for video
			//	this.videoAsLangSearchParams.fromObject({ lang: this.id });
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch language from API */
	private refresh() {
		this.languageService
			.get(this.id, { _populate: ['*'] })
			.then((language: Language) => {
				this.language.fromObject(language.toObject());

				// Refresh history
				const params = {
					entity_id: this.id,
					entity_type: 'language',
				};
				if (!this.historySearchParams) {
					this.historySearchParams = new ActivityLogSearchParams(
						params
					);
				} else {
					this.historySearchParams.fromObject(params);
				}
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the language is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the language is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/language')
			.catch((error) => this.errorService.handle(error));
	}
	/** Called on when user select a audio as lang */
	onSelectAudioAsLang(item: Audio): void {
		this.router.navigateByUrl(`/audio/${item.getId()}`);
	}
	/** Called on when user select a poi text as lang */

	/** Called on when user select a video as lang */
	onSelectVideoAsLang(item: Video): void {
		this.router.navigateByUrl(`/video/${item.getId()}`);
	}
}
