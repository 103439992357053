<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<form
	nz-form
	class="activity-log-form w-100"
	[formGroup]="form"
	(ngSubmit)="onSubmit()"
	[nzAutoTips]="explainErrorsService.autoTips"
>
	<nz-affix>
		<div class="py-4 border-bottom bg-white">
			<div class="container">
				<div class="row">
					<div
						class="col-12 d-flex align-items-center justify-content-between"
					>
						<!-- Title -->
						<div>
							<h1 class="mb-0">
								<span *ngIf="activityLog.isNew()">{{
									'activity-log_create-form-title' | translate
								}}</span>
								<span *ngIf="!activityLog.isNew()">{{
									activityLog.getLabel()
								}}</span>
							</h1>
							<small
								*ngIf="!activityLog.isNew()"
								class="text-black-50"
								>{{
									'common_id'
										| translate: { id: activityLog.getId() }
								}}</small
							>
						</div>
						<!-- /Title -->
					</div>
				</div>
			</div>
		</div>
	</nz-affix>
	<div class="container">
		<nz-card class="round-border my-5">
			<div *ngIf="loading" class="row">
				<div class="col-12">
					<nz-skeleton
						class="no-header"
						[nzActive]="true"
					></nz-skeleton>
				</div>
			</div>
			<div *ngIf="!loading">
				<div *ngIf="!activityLog.isNew()" class="row">
					<div class="col-12 col-md-6">
						<!-- Created At -->
						<div class="mb-4">
							<h4>{{ 'activity-log_created-at' | translate }}</h4>
							<div>
								{{
									activityLog.props.created_at
										| date: 'medium'
								}}
							</div>
						</div>
						<!-- /Created At -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Initiator -->
						<div class="mb-4">
							<h4>{{ 'activity-log_initiator' | translate }}</h4>
							<div *ngIf="activityLog.initiatorExists()">
								<a
									[routerLink]="[
										'/user',
										activityLog.props.initiator.getId()
									]"
								>
									{{ activityLog.props.initiator.getLabel() }}
								</a>
							</div>
						</div>
						<!-- /Initiator -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Name -->
						<div class="mb-4">
							<h4>{{ 'activity-log_name' | translate }}</h4>
							<div>{{ activityLog.props.name }}</div>
						</div>
						<!-- /Name -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Entity Type -->
						<div class="mb-4">
							<h4>
								{{ 'activity-log_entity-type' | translate }}
							</h4>
							<div>{{ activityLog.props.entity_type }}</div>
						</div>
						<!-- /Entity Type -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Entity Id -->
						<div class="mb-4">
							<h4>{{ 'activity-log_entity-id' | translate }}</h4>
							<div>{{ activityLog.props.entity_id }}</div>
						</div>
						<!-- /Entity Id -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Action -->
						<div class="mb-4">
							<h4>{{ 'activity-log_action' | translate }}</h4>
							<div>{{ activityLog.props.action }}</div>
						</div>
						<!-- /Action -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Fields -->
						<div class="mb-4">
							<h4>{{ 'activity-log_fields' | translate }}</h4>
							<div>{{ activityLog.props.fields }}</div>
						</div>
						<!-- /Fields -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Data -->
						<div class="mb-4">
							<h4>{{ 'activity-log_data' | translate }}</h4>
							<small>
								<pre>{{ activityLog.props.data | json }}</pre>
							</small>
						</div>
						<!-- /Data -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Previous -->
						<div class="mb-4">
							<h4>{{ 'activity-log_previous' | translate }}</h4>
							<div *ngIf="activityLog.previousExists()">
								<a
									[routerLink]="[
										'/activity-log',
										activityLog.props.previous.getId()
									]"
								>
									{{ activityLog.props.previous.getLabel() }}
								</a>
							</div>
						</div>
						<!-- /Previous -->
					</div>
				</div>
			</div>
		</nz-card>
	</div>
</form>
