<nz-card class="round-border my-5">

  <label class="btn btn-default">
    <input type="file" multiple (change)="selectFiles($event)" />
  </label>
  <hr>
  <div>
    <div *ngFor="let progress of progressFiles" class="row">
      <div class="col-3">
        {{progress.name}}
      </div>
      <div class="col-3">
        <img src="{{progress.img}}" width="100px">
      </div>
      <div class="col-3">
        <button (click)="remove(progress)" nz-button nzType="default" nzShape="circle"
          [title]="'playlist-table_delete-button' | translate" class="ml-3"> <i nz-icon nzType="delete"></i></button>
      </div>
    </div>
  </div>
  <hr>
  <button class="ant-btn ant-btn-primary ng-star-inserted" (click)="upload()">{{'common_upload'|translate}}</button>
</nz-card>



<div class="bgBlack" [hidden]="!processing"></div>

<div class="box" [hidden]="!processing">
  <div class="percent">
    <svg>
      <circle cx="20" cy="70" r="70"></circle>
      <circle cx="70" cy="70" r="70" [style.stroke-dashoffset]="'calc(440 - (440 * '+progression+') / 100)'"></circle>
    </svg>
    <div class="num">
      <h2>{{progression}}<span>%</span></h2>
    </div>
  </div>

</div>
