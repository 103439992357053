<!-- Name -->
<nz-form-item>
	<nz-form-label>{{ 'role_name' | translate }}</nz-form-label>
	<input
		nz-input
		class="w-100"
		(keyup)="onChangeDebounced()"
		[placeholder]="'common_value-string' | translate"
		[(ngModel)]="searchParams.props.name"
	/>
</nz-form-item>
<!-- /Name -->
<!-- Admin -->
<nz-form-item>
	<nz-form-label>{{ 'role_admin' | translate }}</nz-form-label>
	<nz-select
		class="w-100"
		[nzPlaceHolder]="'common_value-boolean' | translate"
		[(ngModel)]="searchParams.props.admin"
		(ngModelChange)="onChange()"
		[nzAllowClear]="true"
	>
		<nz-option
			[nzValue]="true"
			[nzLabel]="'common_true' | translate"
		></nz-option>
		<nz-option
			[nzValue]="false"
			[nzLabel]="'common_false' | translate"
		></nz-option>
	</nz-select>
</nz-form-item>
<!-- /Admin -->
<button nz-button nzType="default" (click)="resetSearchParams()">
	{{ 'common_reset-filters' | translate }}
</button>
