import {Component, Input, OnInit} from '@angular/core';
import {AboutSearchParams} from '../about-search-params';
@Component({
  selector: 'hpf-about-filters',
  templateUrl: './about-filters.component.html'
})
export class AboutFiltersComponent implements OnInit {
  /** The filters */
  @Input() searchParams: AboutSearchParams = new AboutSearchParams();
  /** Denotes if the page number should be set to 0 when filters change */
  @Input() resetPage = false;
  /** Constructor */
  constructor() {}
  /** Init */
  ngOnInit() {
  }
  /** Called when an input is changed */
  onChange() {
    if (this.resetPage) {
        this.searchParams.props._page = 0;
    }
    this.searchParams.next();
  }
  /** Clear all filters */
  public resetSearchParams() {
    this.searchParams.fromObject({});
  }
}
