<nz-drawer [nzClosable]="false" [(nzVisible)]="filtersDrawer" nzPlacement="right" (nzOnClose)="filtersDrawer = false"
  [nzTitle]="'common_filters-title' | translate" nzWidth="300">
  <hpf-marker-filters [searchParams]="searchParams" [resetPage]="true"></hpf-marker-filters>
</nz-drawer>
<hpf-marker-list-top-bar [title]="'marker_common_name' | translate" (filterEvent)="filtersDrawer = true"
  [searchParams]="searchParams" (createEvent)="onCreate()" (createBulkEvent)="onCreateBulk()"
  [createButton]="'common_create' | translate"></hpf-marker-list-top-bar>
<div class="container-fluid px-5">
  <div class="row my-5">
    <div class="col-12">
      <hpf-marker-table [searchParams]="searchParams" [enableMultiSelect]="true" [showActions]="true"
        (select)="onSelect($event)"></hpf-marker-table>
    </div>
  </div>
</div>
