
<!-- Name -->
<nz-form-item>
    <nz-form-label>{{ 'user_name' | translate }}</nz-form-label>
    <input nz-input
           class="w-100"
           (keyup)="onChangeDebounced();"
           [placeholder]="'common_value-string' | translate"
           [(ngModel)]="searchParams.props.name">
</nz-form-item>
<!-- /Name -->
<!-- Role -->
<nz-form-item>
    <nz-form-label>{{ 'user_role' | translate }}</nz-form-label>
    <input nz-input
           class="w-100"
           (keyup)="onChangeDebounced();"
           [placeholder]="'common_value-string' | translate"
           [(ngModel)]="searchParams.props.role">
</nz-form-item>
<!-- /Role -->
<!-- Email -->
<nz-form-item>
    <nz-form-label>{{ 'user_email' | translate }}</nz-form-label>
    <input nz-input
           class="w-100"
           (keyup)="onChangeDebounced();"
           [placeholder]="'common_value-string' | translate"
           [(ngModel)]="searchParams.props.email">
</nz-form-item>
<!-- /Email -->
<!-- Email Confirmed -->
<nz-form-item>
    <nz-form-label>{{ 'user_email-confirmed' | translate }}</nz-form-label>
    <nz-select  class="w-100"
                [nzPlaceHolder]="'common_value-boolean' | translate"
                [(ngModel)]="searchParams.props.email_confirmed"
                (ngModelChange)="onChange();"
                [nzAllowClear]="true">
        <nz-option [nzValue]="true" [nzLabel]="'common_true' | translate"></nz-option>
        <nz-option [nzValue]="false" [nzLabel]="'common_false' | translate"></nz-option>
    </nz-select>
</nz-form-item>
<!-- /Email Confirmed -->
<button
	nz-button
	nzType="default"
	(click)="resetSearchParams()"
>
	{{ 'common_reset-filters' | translate }}
</button>
