import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {PointOfInterestSearchParams} from '../point-of-interest-search-params';

@Component({
  selector: 'hpf-point-of-interest-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class PointOfInterestListTopBarComponent extends EntityListTopBarComponent< PointOfInterestSearchParams> {
}
 