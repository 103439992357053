import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {About} from '../../about';

@Component({
  selector: 'hpf-about-create',
  templateUrl: './about-create.component.html'
})
export class AboutCreateComponent implements OnInit {
  /** The about */
  about = new About();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the about is created */
  onCreate(about: About): void {
    this.router.navigateByUrl(`/about/${about.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
