<ng-container *ngIf="config.enabled">
	<nz-modal
		[nzVisible]="visible"
		nzWidth="50%"
		(nzOnCancel)="visible = false"
		[nzFooter]="null"
	>
		<div class="my-5" *ngIf="model">
			<ng-container [ngSwitch]="model">
				<!-- about -->
				<hpf-about-form
					*ngSwitchCase="'about'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-about-form>
				<!-- /about -->
				<!-- audio -->
				<hpf-audio-form
					*ngSwitchCase="'audio'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-audio-form>
				<!-- /audio -->
				<!-- audioDescription -->
				<hpf-audio-description-form
					*ngSwitchCase="'audio_description'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-audio-description-form>
				<!-- /audioDescription -->
				<!-- category -->
				<hpf-category-form
					*ngSwitchCase="'category'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-category-form>
				<!-- /category -->
				<!-- credit -->
				<hpf-credit-form
					*ngSwitchCase="'credit'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-credit-form>
				<!-- /credit -->
				<!-- diaporama -->
				<hpf-diaporama-form
					*ngSwitchCase="'diaporama'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-diaporama-form>
				<!-- /diaporama -->
				<!-- diaporama -->
				<hpf-diaporama-form
					*ngSwitchCase="'diaporama'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-diaporama-form>
				<!-- /diaporama -->
				<!-- image -->
				<hpf-image-form
					*ngSwitchCase="'image'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-image-form>
				<!-- /image -->
				<!-- language -->
				<hpf-language-form
					*ngSwitchCase="'language'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-language-form>
				<!-- /language -->
				<!-- marker -->
				<hpf-marker-form
					*ngSwitchCase="'marker'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-marker-form>
				<!-- /marker -->
				<!-- plateau -->
				<hpf-plateau-form
					*ngSwitchCase="'plateau'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-plateau-form>
				<!-- /plateau -->
				<!-- pointOfInterest -->
				<hpf-point-of-interest-form
					*ngSwitchCase="'point_of_interest'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-point-of-interest-form>
				<!-- /pointOfInterest -->
				<!-- route -->
				<hpf-route-form
					*ngSwitchCase="'route'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-route-form>
				<!-- /route -->
				<!-- textTranslated -->
				<hpf-text-translated-form
					*ngSwitchCase="'text_translated'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-text-translated-form>
				<!-- /textTranslated -->
				<!-- user -->
				<hpf-user-form
					*ngSwitchCase="'user'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-user-form>
				<!-- /user -->
				<!-- video -->
				<hpf-video-form
					*ngSwitchCase="'video'"
					(create)="onCreate($event)"
					[quickFormEnabled]="false"
				></hpf-video-form>
				<!-- /video -->
			</ng-container>
		</div>
	</nz-modal>
</ng-container>
