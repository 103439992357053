import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {LanguageSearchParams} from '../language-search-params';

@Component({
  selector: 'hpf-language-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class LanguageListTopBarComponent extends EntityListTopBarComponent< LanguageSearchParams> {
}
 