import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { Video } from '../video';
import { VideoService } from '../video.service';
import { LanguageTabComponent } from '@app/models/language/language-tab/language-tab.component';
@Component({
	selector: 'hpf-video-form',
	templateUrl: './video-form.component.html',
})
export class VideoFormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The video to inject in the form */
	@Input() video: Video;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<Video>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	/** Constructor */
	@ViewChildren(LanguageTabComponent) languageTabs: QueryList<
		LanguageTabComponent
	>;
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private videoService: VideoService,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'video',
			'update'
		));

		// Init model
		if (!this.video) this.video = new Video();

		// Create form
		this.form = this.formBuilder.group({
			name: new FormControl(this.video.props.name, [Validators.required]),
		});
		// Update form when model loads or changes
		this.modelSubscription = this.video.subscribe(() => {
			this.updateForm();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();
			for (let element of this.languageTabs) {
				await element.submit();
			}

			// Creation or update ?
			if (this.video.isNew()) {
				// Creation
				const video: Video = await this.videoService.postWithFile(
					this.video.toFormData()
				);
				this.create.next(video);
			} else {
				// Update
				var up = this.video.toFormData();
				delete up['uri'];

				await this.videoService.updateWithFile(this.video.getId(), up);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.video.props[key] = this.form.get(key).value;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			name: this.video.props.name,
		});
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.videoService
			.remove(this.video.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
	fileUploaded(data) {
		this.video.props.fileUpload = data.target.files[0];
		console.log(data);
	}
}
