import { NgModule } from '@angular/core';
import {
	NzAffixModule,
	NzAlertModule,
	NzAnchorModule,
	NzAutocompleteModule,
	NzAvatarModule,
	NzBackTopModule,
	NzBadgeModule,
	NzBreadCrumbModule,
	NzButtonModule,
	NzCalendarModule,
	NzCardModule,
	NzCarouselModule,
	NzCascaderModule,
	NzCheckboxModule,
	NzCollapseModule,
	NzCommentModule,
	NzDatePickerModule,
	NzDescriptionsModule,
	NzDividerModule,
	NzDrawerModule,
	NzDropDownModule,
	NzEmptyModule,
	NzFormModule,
	NzGridModule,
	NzI18nModule,
	NzIconModule,
	NzInputModule,
	NzInputNumberModule,
	NzLayoutModule,
	NzListModule,
	NzMentionModule,
	NzMenuModule,
	NzMessageModule,
	NzModalModule,
	NzNotificationModule,
	NzPageHeaderModule,
	NzPaginationModule,
	NzPopconfirmModule,
	NzPopoverModule,
	NzProgressModule,
	NzRadioModule,
	NzRateModule,
	NzResultModule,
	NzSelectModule,
	NzSkeletonModule,
	NzSliderModule,
	NzSpinModule,
	NzStatisticModule,
	NzStepsModule,
	NzSwitchModule,
	NzTableModule,
	NzTabsModule,
	NzTagModule,
	NzTimePickerModule,
	NzTimelineModule,
	NzToolTipModule,
	NzTransferModule,
	NzTreeModule,
	NzTreeSelectModule,
	NzTypographyModule,
	NzUploadModule,
	NzMessageServiceModule,
} from 'ng-zorro-antd/';

@NgModule({
	imports: [
		NzAffixModule,
		NzAlertModule,
		NzAnchorModule,
		NzAutocompleteModule,
		NzAvatarModule,
		NzBackTopModule,
		NzBadgeModule,
		NzBreadCrumbModule,
		NzButtonModule,
		NzCalendarModule,
		NzCardModule,
		NzCarouselModule,
		NzCascaderModule,
		NzCheckboxModule,
		NzCollapseModule,
		NzCommentModule,
		NzDatePickerModule,
		NzDescriptionsModule,
		NzDividerModule,
		NzDrawerModule,
		NzDropDownModule,
		NzEmptyModule,
		NzFormModule,
		NzGridModule,
		NzI18nModule,
		NzIconModule,
		NzInputModule,
		NzInputNumberModule,
		NzLayoutModule,
		NzListModule,
		NzMentionModule,
		NzMenuModule,
		NzMessageModule,
		NzModalModule,
		NzNotificationModule,
		NzPageHeaderModule,
		NzPaginationModule,
		NzPopconfirmModule,
		NzPopoverModule,
		NzProgressModule,
		NzRadioModule,
		NzRateModule,
		NzResultModule,
		NzSelectModule,
		NzSkeletonModule,
		NzSliderModule,
		NzSpinModule,
		NzStatisticModule,
		NzStepsModule,
		NzSwitchModule,
		NzTableModule,
		NzTabsModule,
		NzTagModule,
		NzTimePickerModule,
		NzTimelineModule,
		NzToolTipModule,
		NzTransferModule,
		NzTreeModule,
		NzTreeSelectModule,
		NzTypographyModule,
		NzUploadModule,
		NzMessageServiceModule,
	],
	exports: [
		NzAffixModule,
		NzAlertModule,
		NzAnchorModule,
		NzAutocompleteModule,
		NzAvatarModule,
		NzBackTopModule,
		NzBadgeModule,
		NzBreadCrumbModule,
		NzButtonModule,
		NzCalendarModule,
		NzCardModule,
		NzCarouselModule,
		NzCascaderModule,
		NzCheckboxModule,
		NzCollapseModule,
		NzCommentModule,
		NzDatePickerModule,
		NzDescriptionsModule,
		NzDividerModule,
		NzDrawerModule,
		NzDropDownModule,
		NzEmptyModule,
		NzFormModule,
		NzGridModule,
		NzI18nModule,
		NzIconModule,
		NzInputModule,
		NzInputNumberModule,
		NzLayoutModule,
		NzListModule,
		NzMentionModule,
		NzMenuModule,
		NzMessageModule,
		NzModalModule,
		NzNotificationModule,
		NzPageHeaderModule,
		NzPaginationModule,
		NzPopconfirmModule,
		NzPopoverModule,
		NzProgressModule,
		NzRadioModule,
		NzRateModule,
		NzResultModule,
		NzSelectModule,
		NzSkeletonModule,
		NzSliderModule,
		NzSpinModule,
		NzStatisticModule,
		NzStepsModule,
		NzSwitchModule,
		NzTableModule,
		NzTabsModule,
		NzTagModule,
		NzTimePickerModule,
		NzTimelineModule,
		NzToolTipModule,
		NzTransferModule,
		NzTreeModule,
		NzTreeSelectModule,
		NzTypographyModule,
		NzUploadModule,
		NzMessageServiceModule,
	],
})
export class AntComponentsModule {}
