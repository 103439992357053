import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ErrorService} from '@app/services';
import {ImageSearchParams} from '../image-search-params';
import {ImageService} from '../image.service';
@Component({
  selector: 'hpf-image-count',
  templateUrl: './image-count.component.html'
})
export class ImageCountComponent implements OnInit {
  /** The filters */
  @Input() searchParams: ImageSearchParams = new ImageSearchParams();
  /** Count result */
  count: number;
  /** Load flag */
  loaded = false;
  /** Constructor */
  constructor(private imageService: ImageService,
              private errorService: ErrorService) {}
  /** Init */
  ngOnInit() {
    this.imageService.count(this.searchParams.toObject())
    .then((count) => {
      this.count = count;
      this.loaded = true;
    })
    .catch((error) => this.errorService.handle(error))
  }
}
