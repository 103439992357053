import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {CreditSearchParams} from '../credit-search-params';
import {CreditService} from '../credit.service';
import {Credit} from '../credit';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-credit-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class CreditSelectComponent extends EntitySelectComponent< Credit> {
  protected modelName = 'credit';
  /**
   * Constructor
   * @param {CreditService} creditService
   * @param {ErrorService} errorService
   */
  constructor(private creditService: CreditService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Credit)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Credit[]>this.model).push(<Credit>model);
          } else {
            this.model = <Credit>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Credit[]> {
      const params = new CreditSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      return (await this.creditService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Credit> {
      return await this.creditService.get(id);
  }
}
