import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {TextTranslated, TextTranslatedInterface} from '../text-translated/text-translated';
export interface CreditInterface extends BaseModelInterface {
  created_at: number | Date;
  title?: (string | TextTranslated | TextTranslatedInterface)[];
  description?: (string | TextTranslated | TextTranslatedInterface)[];
}
export interface CreditPayload {
  title?: (string | TextTranslated | TextTranslatedInterface)[];
  description?: (string | TextTranslated | TextTranslatedInterface)[];
}
type CreditPayloadKey = keyof CreditPayload;
export class Credit extends BaseModel< CreditInterface, CreditPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return this.props._id;
  }
  /** Denotes if the instance of title has been populated */
  titleExists(): boolean {
    return !!this.props &&
        this.props.title instanceof Array &&
        (< TextTranslated[]>this.props.title).every((item) => {
            return item instanceof TextTranslated && item.exists();
        });
  }
  /** Denotes if the instance of description has been populated */
  descriptionExists(): boolean {
    return !!this.props &&
        this.props.description instanceof Array &&
        (< TextTranslated[]>this.props.description).every((item) => {
            return item instanceof TextTranslated && item.exists();
        });
  }
  /** Populate the current instance from an object */
  fromObject(object: CreditInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.title instanceof Array) {
        this.props.title = (< TextTranslatedInterface[]>this.props.title).map((item) => {
            return typeof item === 'object' ? new TextTranslated(item) : item;
        });
    }
    if (this.props.description instanceof Array) {
        this.props.description = (< TextTranslatedInterface[]>this.props.description).map((item) => {
            return typeof item === 'object' ? new TextTranslated(item) : item;
        });
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): CreditInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.title === 'undefined') {
        props.title = [];
    }
    if (typeof props.description === 'undefined') {
        props.description = [];
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (this.props.title instanceof Array) {
        props.title = (< TextTranslated[]>props.title).map((item) => {
            return item instanceof TextTranslated ? item.toObject() : item;
        });
    }
    if (this.props.description instanceof Array) {
        props.description = (< TextTranslated[]>props.description).map((item) => {
            return item instanceof TextTranslated ? item.toObject() : item;
        });
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): CreditPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as CreditInterface);
    payload.title = payload.title ? payload.title.map(i => this.extractId(i)) : null;
    payload.description = payload.description ? payload.description.map(i => this.extractId(i)) : null;
    return payload as CreditPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): CreditPayloadKey[] {
  	return [
      'title',
      'description',
  	];
  }
}
