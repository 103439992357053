import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { TranslateModuleLoad } from '@app/translate-import';
import { ActivityRoutingModule } from './activity-routing.module';
import {
	ActivityLogService,
	ActivityLogFormComponent,
	ActivityLogFiltersComponent,
	ActivityLogTableComponent,
	ActivityLogSelectComponent,
	ActivityLogListTopBarComponent,
	ActivityLogCountComponent,
} from './';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { ActivityLogCreateComponent } from './views/activity-log-create/activity-log-create.component';
import { ActivityLogEditComponent } from './views/activity-log-edit/activity-log-edit.component';
import { ActivityLogListComponent } from './views/activity-log-list/activity-log-list.component';
import { ModelsModule } from '@app/models';
import { ActivityHistoryComponent } from './activity-history/activity-history.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ModelsModule,
		TranslateModuleLoad(),
		ActivityRoutingModule,
	],
	declarations: [
		ActivityLogComponent,
		ActivityLogFormComponent,
		ActivityLogCreateComponent,
		ActivityLogEditComponent,
		ActivityLogListComponent,
		ActivityLogFiltersComponent,
		ActivityLogTableComponent,
		ActivityLogSelectComponent,
		ActivityLogListTopBarComponent,
		ActivityLogCountComponent,
		ActivityHistoryComponent,
	],
	providers: [ActivityLogService],
	exports: [ActivityHistoryComponent],
})
export class ActivityModule {}
