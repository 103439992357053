import { Injectable } from '@angular/core';
import { Plateau, PlateauInterface, PlateauPayload } from './plateau';
import { PlateauSearchParamsInterface } from './plateau-search-params';
import { BaseModelService } from '@app/abstracts';
import { environment } from '@env/environment';
@Injectable()
export class PlateauService extends BaseModelService<
	Plateau,
	PlateauInterface,
	PlateauPayload,
	PlateauSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'plateau';
	}
	/** @inheritDoc */
	protected new(object: PlateauInterface): Plateau {
		return new Plateau(object);
	}

	moveToPublic(plateau: Plateau): Promise<void> {
		const options = {
			withCredentials: true,
		};

		return this.http
			.get<void>(
				`${environment.api.uri}${environment.api.adminPath}/assetbundle/move/` +
					plateau.props._id,
				options
			)
			.toPromise();
	}

	createAssetBundle(plateau: Plateau): Promise<void> {
		const options = {
			withCredentials: true,
		};

		return this.http
			.get<void>(
				`${environment.api.uri}${environment.api.adminPath}/assetbundle/` +
					plateau.props._id,
				options
			)
			.toPromise();
	}
}
