<table class="table table-bordered">
  <thead>
    <tr>
      <th>{{'slide_time'|translate}}</th>
      <th>{{'slide_image'|translate}}</th>

      <th (click)="AddSlide()"><span class="col-12 btn btn-primary">{{'slide_add'|translate}}</span></th>
    </tr>
  </thead>
  <tbody *ngIf="items.length">
    <tr *ngFor="let slide of items">
      <td><input type="number" [value]="slide.time" (input)="slide.time=$event.target.value;UpdateModel()"
          (focusout)="sortItems()"></td>
      <td>

        <app-image-modal-button [value]="slide.image" (imageSelectedEvent)="imageSelected(slide,$event)">
        </app-image-modal-button>
      </td>

      <td><span class="btn btn-secondary" (click)="RemoveSlide(slide)">{{'slide_remove'|translate}}</span>
    </tr>
  </tbody>
  <tbody *ngIf="items.length===0">
    <tr>
      <td colspan="3"><span (click)="AddSlide()" class="col-12 btn btn-primary">{{'slide_add'|translate}}</span></td>
    </tr>
  </tbody>
</table>
