import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {About} from '../../about';
import {AboutService} from '../../about.service';
@Component({
  selector: 'hpf-about-edit',
  templateUrl: './about-edit.component.html'
})
export class AboutEditComponent implements OnInit, OnDestroy {
  /** The about */
  about = new About();
  /** The about */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private aboutService: AboutService) {}
  /** Init */
  ngOnInit() {
    // Get the about after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch about from API */
  private refresh() {
    this.aboutService.get(this.id, { _populate: ['*'] })
      .then((about: About) => {
        this.about.fromObject(about.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the about is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the about is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/about')
      .catch((error) => this.errorService.handle(error));
  }
}
