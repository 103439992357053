import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ErrorService} from '@app/services';
import {CategorySearchParams} from '../category-search-params';
import {CategoryService} from '../category.service';
@Component({
  selector: 'hpf-category-count',
  templateUrl: './category-count.component.html'
})
export class CategoryCountComponent implements OnInit {
  /** The filters */
  @Input() searchParams: CategorySearchParams = new CategorySearchParams();
  /** Count result */
  count: number;
  /** Load flag */
  loaded = false;
  /** Constructor */
  constructor(private categoryService: CategoryService,
              private errorService: ErrorService) {}
  /** Init */
  ngOnInit() {
    this.categoryService.count(this.searchParams.toObject())
    .then((count) => {
      this.count = count;
      this.loaded = true;
    })
    .catch((error) => this.errorService.handle(error))
  }
}
