import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { TranslateModuleLoad } from '@app/translate-import';
import { LanguageTabComponent } from './language';
import {
	PointOfInterestService,
	PointOfInterestFormComponent,
	PointOfInterestFiltersComponent,
	PointOfInterestTableComponent,
	PointOfInterestSelectComponent,
	PointOfInterestListTopBarComponent,
	PointOfInterestCountComponent,
} from './point-of-interest';
import {
	UserService,
	UserFormComponent,
	UserFiltersComponent,
	UserTableComponent,
	UserSelectComponent,
	UserListTopBarComponent,
	UserCountComponent,
} from './user';
import {
	VideoService,
	VideoFormComponent,
	VideoFiltersComponent,
	VideoTableComponent,
	VideoSelectComponent,
	VideoListTopBarComponent,
	VideoCountComponent,
} from './video';
import {
	AudioService,
	AudioFormComponent,
	AudioFiltersComponent,
	AudioTableComponent,
	AudioSelectComponent,
	AudioListTopBarComponent,
	AudioCountComponent,
} from './audio';
import {
	TextTranslatedService,
	TextTranslatedFormComponent,
	TextTranslatedFiltersComponent,
	TextTranslatedTableComponent,
	TextTranslatedSelectComponent,
	TextTranslatedListTopBarComponent,
	TextTranslatedCountComponent,
} from './text-translated';
import {
	ImageService,
	ImageFormComponent,
	ImageFiltersComponent,
	ImageTableComponent,
	ImageSelectComponent,
	ImageListTopBarComponent,
	ImageCountComponent,
	ImageMultipleFormComponent,
	ImageModalButtonComponent,
} from './image';
import {
	LanguageService,
	LanguageFormComponent,
	LanguageFiltersComponent,
	LanguageTableComponent,
	LanguageSelectComponent,
	LanguageListTopBarComponent,
	LanguageCountComponent,
} from './language';
import {
	CategoryService,
	CategoryFormComponent,
	CategoryFiltersComponent,
	CategoryTableComponent,
	CategorySelectComponent,
	CategoryListTopBarComponent,
	CategoryCountComponent,
} from './category';
import {
	RouteService,
	RouteFormComponent,
	RouteFiltersComponent,
	RouteTableComponent,
	RouteSelectComponent,
	RouteListTopBarComponent,
	RouteCountComponent,
} from './route';
import {
	PlateauService,
	PlateauFormComponent,
	PlateauFiltersComponent,
	PlateauTableComponent,
	PlateauSelectComponent,
	PlateauListTopBarComponent,
	PlateauCountComponent,
} from './plateau';
import {
	AudioDescriptionService,
	AudioDescriptionFormComponent,
	AudioDescriptionFiltersComponent,
	AudioDescriptionTableComponent,
	AudioDescriptionSelectComponent,
	AudioDescriptionListTopBarComponent,
	AudioDescriptionCountComponent,
} from './audio-description';
import {
	DiaporamaService,
	DiaporamaFormComponent,
	DiaporamaFiltersComponent,
	DiaporamaTableComponent,
	DiaporamaSelectComponent,
	DiaporamaListTopBarComponent,
	DiaporamaCountComponent,
	DiaporamaSliderComponent,
} from './diaporama';
import {
	AboutService,
	AboutFormComponent,
	AboutFiltersComponent,
	AboutTableComponent,
	AboutSelectComponent,
	AboutListTopBarComponent,
	AboutCountComponent,
} from './about';
import {
	CreditService,
	CreditFormComponent,
	CreditFiltersComponent,
	CreditTableComponent,
	CreditSelectComponent,
	CreditListTopBarComponent,
	CreditCountComponent,
} from './credit';
import {
	MarkerService,
	MarkerFormComponent,
	MarkerFiltersComponent,
	MarkerTableComponent,
	MarkerSelectComponent,
	MarkerListTopBarComponent,
	MarkerCountComponent,
	MarkerBulkFormComponent,
} from './marker';
import { ImageModalComponent } from './image/image-modal/image-modal.component';
@NgModule({
	imports: [CommonModule, SharedModule, TranslateModuleLoad()],
	declarations: [
		PointOfInterestFormComponent,
		PointOfInterestFiltersComponent,
		PointOfInterestTableComponent,
		PointOfInterestSelectComponent,
		PointOfInterestListTopBarComponent,
		PointOfInterestCountComponent,
		UserFormComponent,
		UserFiltersComponent,
		UserTableComponent,
		UserSelectComponent,
		UserListTopBarComponent,
		UserCountComponent,
		VideoFormComponent,
		VideoFiltersComponent,
		VideoTableComponent,
		VideoSelectComponent,
		VideoListTopBarComponent,
		VideoCountComponent,
		AudioFormComponent,
		AudioFiltersComponent,
		AudioTableComponent,
		AudioSelectComponent,
		AudioListTopBarComponent,
		AudioCountComponent,
		TextTranslatedFormComponent,
		TextTranslatedFiltersComponent,
		TextTranslatedTableComponent,
		TextTranslatedSelectComponent,
		TextTranslatedListTopBarComponent,
		TextTranslatedCountComponent,
		ImageFormComponent,
		ImageFiltersComponent,
		ImageTableComponent,
		ImageSelectComponent,
		ImageListTopBarComponent,
		ImageCountComponent,
		ImageMultipleFormComponent,
		LanguageFormComponent,
		LanguageFiltersComponent,
		LanguageTableComponent,
		LanguageSelectComponent,
		LanguageListTopBarComponent,
		LanguageCountComponent,
		CategoryFormComponent,
		CategoryFiltersComponent,
		CategoryTableComponent,
		CategorySelectComponent,
		CategoryListTopBarComponent,
		CategoryCountComponent,
		RouteFormComponent,
		RouteFiltersComponent,
		RouteTableComponent,
		RouteSelectComponent,
		RouteListTopBarComponent,
		RouteCountComponent,
		PlateauFormComponent,
		PlateauFiltersComponent,
		PlateauTableComponent,
		PlateauSelectComponent,
		PlateauListTopBarComponent,
		PlateauCountComponent,
		AudioDescriptionFormComponent,
		AudioDescriptionFiltersComponent,
		AudioDescriptionTableComponent,
		AudioDescriptionSelectComponent,
		AudioDescriptionListTopBarComponent,
		AudioDescriptionCountComponent,
		DiaporamaFormComponent,
		DiaporamaFiltersComponent,
		DiaporamaTableComponent,
		DiaporamaSelectComponent,
		DiaporamaListTopBarComponent,
		DiaporamaCountComponent,
		AboutFormComponent,
		AboutFiltersComponent,
		AboutTableComponent,
		AboutSelectComponent,
		AboutListTopBarComponent,
		AboutCountComponent,
		CreditFormComponent,
		CreditFiltersComponent,
		CreditTableComponent,
		CreditSelectComponent,
		CreditListTopBarComponent,
		CreditCountComponent,
		MarkerFormComponent,
		MarkerFiltersComponent,
		MarkerTableComponent,
		MarkerSelectComponent,
		MarkerListTopBarComponent,
		MarkerBulkFormComponent,
		MarkerCountComponent,
		LanguageTabComponent,
		ImageModalButtonComponent,
		DiaporamaSliderComponent,
	],
	providers: [
		PointOfInterestService,
		UserService,
		VideoService,
		AudioService,
		TextTranslatedService,
		ImageService,
		LanguageService,
		CategoryService,
		RouteService,
		PlateauService,
		AudioDescriptionService,
		DiaporamaService,
		AboutService,
		CreditService,
		MarkerService,
	],
	exports: [
		PointOfInterestFormComponent,
		PointOfInterestFiltersComponent,
		PointOfInterestTableComponent,
		PointOfInterestSelectComponent,
		PointOfInterestListTopBarComponent,
		PointOfInterestCountComponent,
		UserFormComponent,
		UserFiltersComponent,
		UserTableComponent,
		UserSelectComponent,
		UserListTopBarComponent,
		UserCountComponent,
		VideoFormComponent,
		VideoFiltersComponent,
		VideoTableComponent,
		VideoSelectComponent,
		VideoListTopBarComponent,
		VideoCountComponent,
		AudioFormComponent,
		AudioFiltersComponent,
		AudioTableComponent,
		AudioSelectComponent,
		AudioListTopBarComponent,
		AudioCountComponent,
		TextTranslatedFormComponent,
		TextTranslatedFiltersComponent,
		TextTranslatedTableComponent,
		TextTranslatedSelectComponent,
		TextTranslatedListTopBarComponent,
		TextTranslatedCountComponent,
		ImageFormComponent,
		ImageFiltersComponent,
		ImageTableComponent,
		ImageSelectComponent,
		ImageListTopBarComponent,
		ImageCountComponent,
		ImageMultipleFormComponent,
		LanguageFormComponent,
		LanguageFiltersComponent,
		LanguageTableComponent,
		LanguageSelectComponent,
		LanguageListTopBarComponent,
		LanguageCountComponent,
		CategoryFormComponent,
		CategoryFiltersComponent,
		CategoryTableComponent,
		CategorySelectComponent,
		CategoryListTopBarComponent,
		CategoryCountComponent,
		RouteFormComponent,
		RouteFiltersComponent,
		RouteTableComponent,
		RouteSelectComponent,
		RouteListTopBarComponent,
		RouteCountComponent,
		PlateauFormComponent,
		PlateauFiltersComponent,
		PlateauTableComponent,
		PlateauSelectComponent,
		PlateauListTopBarComponent,
		PlateauCountComponent,
		AudioDescriptionFormComponent,
		AudioDescriptionFiltersComponent,
		AudioDescriptionTableComponent,
		AudioDescriptionSelectComponent,
		AudioDescriptionListTopBarComponent,
		AudioDescriptionCountComponent,
		DiaporamaFormComponent,
		DiaporamaFiltersComponent,
		DiaporamaTableComponent,
		DiaporamaSelectComponent,
		DiaporamaListTopBarComponent,
		DiaporamaCountComponent,
		AboutFormComponent,
		AboutFiltersComponent,
		AboutTableComponent,
		AboutSelectComponent,
		AboutListTopBarComponent,
		AboutCountComponent,
		CreditFormComponent,
		CreditFiltersComponent,
		CreditTableComponent,
		CreditSelectComponent,
		CreditListTopBarComponent,
		CreditCountComponent,
		MarkerFormComponent,
		MarkerFiltersComponent,
		MarkerTableComponent,
		MarkerSelectComponent,
		MarkerBulkFormComponent,
		MarkerListTopBarComponent,
		MarkerCountComponent,
		LanguageTabComponent,
		ImageModalButtonComponent,
	],
})
export class ModelsModule {}
