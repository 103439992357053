import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseModelInterface, BaseModel } from '@app/abstracts';

@Injectable()
export class QuickFormService {
	create = new Subject<string>();

	refresh = new Subject<BaseModel<BaseModelInterface, {}>>();

	constructor() {}
}
