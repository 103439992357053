import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
export interface UserInterface extends BaseModelInterface {
  created_at: number | Date;
  name: string;
  role: string;
  email: string;
  password: string;
  last_connected_at?: number | Date;
  email_confirmed: boolean;
}
export interface UserPayload {
  name: string;
  role: string;
  email: string;
  password: string;
}
type UserPayloadKey = keyof UserPayload;
export class User extends BaseModel< UserInterface, UserPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Populate the current instance from an object */
  fromObject(object: UserInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.last_connected_at !== null) {
        this.props.last_connected_at = Helpers.convertToDate(this.props.last_connected_at);
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): UserInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.last_connected_at === 'undefined') {
        props.last_connected_at = null;
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (props.last_connected_at !== null) {
        props.last_connected_at = Helpers.convertToTimestamp(props.last_connected_at);
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): UserPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as UserInterface);
    if (payload.password === null) { delete payload.password; }
    return payload as UserPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): UserPayloadKey[] {
  	return [
      'name',
      'role',
      'email',
      'password',
  	];
  }
}
