import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-plateau',
  templateUrl: './plateau.component.html'
})
export class PlateauComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
