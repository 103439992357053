import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {User, UserInterface} from '../user/user';
import {Language, LanguageInterface} from '../language/language';
export interface TextTranslatedInterface extends BaseModelInterface {
  created_at: number | Date;
  owner: (string | User | UserInterface);
  name: string;
  text: string;
  lang: (string | Language | LanguageInterface);
}
export interface TextTranslatedPayload {
  name: string;
  text: string;
  lang: (string | Language | LanguageInterface);
}
type TextTranslatedPayloadKey = keyof TextTranslatedPayload;
export class TextTranslated extends BaseModel< TextTranslatedInterface, TextTranslatedPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Denotes if the instance of owner has been populated */
  ownerExists(): boolean {
    return !!this.props &&
        this.props.owner instanceof User &&
        this.props.owner.exists();
  }
  /** Denotes if the instance of lang has been populated */
  langExists(): boolean {
    return !!this.props &&
        this.props.lang instanceof Language &&
        this.props.lang.exists();
  }
  /** Populate the current instance from an object */
  fromObject(object: TextTranslatedInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (typeof this.props.owner === 'object') {
        this.props.owner = new User(< UserInterface>this.props.owner);
    }
    if (typeof this.props.lang === 'object') {
        this.props.lang = new Language(< LanguageInterface>this.props.lang);
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): TextTranslatedInterface {
    const props = Object.assign({}, this.props);
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (props.owner instanceof User) {
        props.owner = props.owner.toObject();
    }
    if (props.lang instanceof Language) {
        props.lang = props.lang.toObject();
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): TextTranslatedPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as TextTranslatedInterface);
    payload.lang = payload.lang ? this.extractId(payload.lang) : null;
    return payload as TextTranslatedPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): TextTranslatedPayloadKey[] {
  	return [
      'name',
      'text',
      'lang',
  	];
  }
}
