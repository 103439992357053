import {
	Component,
	EventEmitter,
	ChangeDetectorRef,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscriber, Subscription as RxJsSubscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';

import {
	ImageSearchParams,
	LanguageTabComponent,
	MarkerSearchParams,
	MarkerService,
	Plateau,
	PlateauSearchParams,
	PlateauService,
} from '@app/models';
import { arraysEqual } from 'ng-zorro-antd';
import { subscribeOn } from 'rxjs/operators';
import { promise } from 'selenium-webdriver';
import { resolve } from 'path';
import { Marker, MarkerPayload } from '../marker';
import { Image } from '../../image/image';
import { ImageService } from '../../image/image.service';

@Component({
	selector: 'hpf-marker-bulk-form',
	templateUrl: './marker-form-bulk.component.html',
})
export class MarkerBulkFormComponent implements OnInit, OnDestroy {
	markers: MarkerPayload[];
	images: Image[];
	plateaux: Plateau[];
	selectedImage: Image = null;
	selectedPlateau: Plateau;
	separator: string = ';';
	constructor(
		private imageService: ImageService,
		private markerService: MarkerService,
		private plateauService: PlateauService,
		private route: Router
	) {}

	csvJSON(csv) {
		var lines = csv.split('\n');
		console.log(lines);
		var result = [];

		// NOTE: If your columns contain commas in their values, you'll need
		// to deal with those before doing the next step
		// (you might convert them to &&& or something, then covert them back later)
		// jsfiddle showing the issue https://jsfiddle.net/
		var headers = lines[0].split(this.separator);
		console.log(headers);
		for (var i = 1; i < lines.length; i++) {
			var obj = {};
			var currentline = lines[i].split(this.separator);

			for (var j = 0; j < headers.length; j++) {
				obj[headers[j].trim()] = currentline[j];
			}

			result.push(obj);
		}

		console.log(result);
		//return result; //JavaScript object
		return result; //JSON
	}
	protected async getList(): Promise<Image[]> {
		const params = new ImageSearchParams({
			_limit: 100,
			_page: 0,
		});

		return await (
			await this.imageService.list(params.toObject())
		).items;
	}
	imageChanged(index) {
		if (index < 0) {
			this.selectedImage = null;
			return;
		}
		this.selectedImage = this.images[index];
	}
	selectFile(event): void {
		var file = event.target.files[0];
		var reader = new FileReader();
		var $this = this;
		reader.onload = function () {
			$this.markers = $this.csvJSON(reader.result);
		};
		reader.readAsText(file);
	}
	async upload(): Promise<void> {
		for (let i = 0; i < this.markers.length; i++) {
			var m = this.markers[i];
			var marker = new Marker();
			marker.props.image = this.selectedImage.props._id;
			marker.props.latitude = m.latitude;
			marker.props.longitude = m.longitude;
			marker.props.label = m.label;
			marker.props.plateau = this.selectedPlateau.props._id;
			await this.markerService.create(marker.props);
		}
		this.route.navigateByUrl('/marker');
	}
	plateauChanged(index): void {
		if (index < 0) {
			this.selectedPlateau = null;
			return;
		}
		this.selectedPlateau = this.plateaux[index];
	}
	remove(m) {
		var index = this.markers.indexOf(m);
		this.markers.splice(index, 1);
	}
	valid() {
		return !(
			Array.isArray(this.markers) &&
			this.markers.length &&
			this.selectedImage &&
			this.selectedPlateau
		);
	}
	ngOnDestroy(): void {}
	async ngOnInit(): Promise<void> {
		this.plateaux = await (
			await this.plateauService.list(
				new PlateauSearchParams({ _limit: 100, _page: 0 }).toObject()
			)
		).items;
		this.images = await this.getList();
	}
}
