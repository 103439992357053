import {
	ChangeDetectorRef,
	Component,
	HostBinding,
	NgZone,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '@env/environment';
import { Title } from '@angular/platform-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { ModelsLinks } from '@app/shared';
import { SessionService, ErrorService, Self } from '@app/services';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {
	/** CSS */
	@HostBinding('class') componentCssClass;
	/** Observables unsubscriber*/
	private unsubscribe: Subject<void> = new Subject<void>();
	/** Side links*/
	navigationSideMenu = ModelsLinks;
	/** Routes where the header should not be displayed  */
	private routesNoHeader: string[] = ['session'];
	/** Denotes if the header must be displayed */
	displayHeader = false;
	/** App name in tab title */
	appName = environment.appName;

	currentLang: string;

	public self: Self;

	constructor(
		private sessionService: SessionService,
		private translateService: TranslateService,
		private errorService: ErrorService,
		private router: Router,
		private titleService: Title,
		private zone: NgZone,
		private cd: ChangeDetectorRef
	) {
		this.initLang();
	}

	ngOnInit() {
		// Animation on navigation
		this.router.events
			.pipe(filter((event) => event instanceof ActivationEnd))
			.subscribe((event: ActivationEnd) => {
				let lastChild = event.snapshot;
				while (lastChild.children.length) {
					lastChild = lastChild.children[0];
				}
				const { title } = lastChild.data;
				this.titleService.setTitle(
					title ? `${title} - ${this.appName}` : this.appName
				);
				// Show header
				const url = event.snapshot.url.toString();
				this.displayHeader = !this.routesNoHeader.some(
					(r) => r === url
				);
			});

		this.sessionService
			.getSelf()
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((self) => {
				this.self = self;
			});

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(
				() => (this.currentLang = this.translateService.currentLang)
			);
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
	}

	initLang() {
		// Setup translate service
		this.translateService.addLangs(environment.languages);
		const availableLangs = this.translateService.getLangs();
		const defaultLang = this.translateService.getLangs()[0];
		this.translateService.setDefaultLang(defaultLang);
		const browserLang = this.translateService.getBrowserLang();
		const userLang = availableLangs.includes(browserLang)
			? browserLang
			: defaultLang;

		// Change lang if storage change
		window.addEventListener('storage', (event: StorageEvent) => {
			if (event.key === 'lang') {
				this.translateService.use(localStorage.getItem('lang'));
			}
		});

		// Sync lang to storage & check for wrong lang
		if (
			!localStorage.getItem('lang') ||
			!availableLangs.includes(localStorage.getItem('lang'))
		) {
			localStorage.setItem('lang', userLang); // This will change lang thanks to event listener
		}

		// Init with stored language
		this.translateService.use(localStorage.getItem('lang'));
		this.currentLang = localStorage.getItem('lang');
	}

	setLang(lang: string) {
		this.translateService.use(lang);

		localStorage.setItem('lang', lang);
		this.cd.detectChanges();
	}

	/** Logout */
	logout() {
		this.sessionService
			.logout()
			.then((self) =>
				this.router.navigate(environment.logout.redirection)
			)
			.catch((error) => this.errorService.handle(error));
	}

	trackItemMenu(index: number, item): string {
		return item.model;
	}
}
