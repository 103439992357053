import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@app/services';
import { Marker } from '../../marker';

@Component({
	templateUrl: './marker-create-bulk.component.html',
})
export class MarkerBulkCreateComponent implements OnInit {
	/** The marker */
	marker = new Marker();

	/** Constructor */
	constructor(private router: Router, private errorService: ErrorService) {}
	/** Init */
	ngOnInit() {}
	/** Called when the marker is created */
	onCreate(marker: Marker): void {
		this.router
			.navigateByUrl(`/marker/${marker.getId()}`)
			.catch((error) => this.errorService.handle(error));
	}
}
