//somwwdede
import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	QueryList,
	ViewChildren,
	NgModule,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { Route, RoutePoint } from '../route';
import { RouteService } from '../route.service';
import {
	tileLayer,
	LatLng,
	latLngBounds,
	FeatureGroup,
	featureGroup,
	DrawEvents,
	Polyline,
	Marker,
	Icon,
	point,
} from 'leaflet';
import { Language, LanguageTabComponent } from '@app/models/language';
import { LanguageComponent } from '@app/models/language/language/language.component';

import { getPosition } from 'ng-zorro-antd';
import { map } from 'rxjs/operators';
import { ColorPickerModule } from 'ngx-color-picker';
import {
	MarkerSearchParams,
	MarkerService,
	Marker as sMarker,
} from '@app/models/marker';
import { Plateau } from '@app/models';

@Component({
	selector: 'hpf-route-form',
	templateUrl: './route-form.component.html',
})
export class RouteFormComponent implements OnInit, OnDestroy {
	mapDisplay = false;
	icon = new Icon({
		iconSize: [25, 41],
		iconAnchor: [13, 41],
		iconUrl: 'assets/marker-icon.png',
		shadowUrl: 'assets/marker-shadow.png',
	});
	mapOptions = {
		layers: [
			tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				minZoom: 1,
				attribution: '...',
			}),
		],
		zoom: 1,
		center: new LatLng(46.879966, -121.726909),
		maxBounds: latLngBounds(new LatLng(90, -168), new LatLng(-90, 190)),
	};
	mapDrawnItems: FeatureGroup = featureGroup();
	mapPoiItems: FeatureGroup = featureGroup();
	mapDrawOptions = {
		position: 'topleft',
		draw: {
			marker: false,
			polyline: true,
			circle: false,
			rectangle: false,
			polygon: false,
			circlemarker: false,
		},
		edit: {
			featureGroup: this.mapDrawnItems,
			edit: true,
			remove: false,
		},
	};
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The route to inject in the form */
	@Input() route: Route;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<Route>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;

	color: string = '#FFFFFF';

	@ViewChildren(LanguageTabComponent) languageTabs: QueryList<
		LanguageTabComponent
	>;

	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private routeService: RouteService,
		private sessionService: SessionService,
		private markerService: MarkerService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'route',
			'update'
		));

		// Init model
		if (!this.route) this.route = new Route();

		// Create form
		this.form = this.formBuilder.group({
			name: new FormControl(this.route.props.name, [Validators.required]),
			itinerary: new FormControl(this.route.props.itinerary, []),
			published: new FormControl(this.route.props.published, [
				Validators.required,
			]),

			duration: new FormControl(this.route.props.duration, [
				Validators.required,
			]),
			color: new FormControl(this.route.props.color, [
				Validators.required,
			]),
			plateau: new FormControl(this.route.props.plateau, []),
		});
		// Update form when model loads or changes
		this.modelSubscription = this.route.subscribe(() => {
			this.updateForm();
		});
		setTimeout(() => this.initMap(), 500);
	}

	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}

	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();
			for (let element of this.languageTabs) {
				await element.submit();
			}

			console.log(this.route);
			// Creation or update ?
			if (this.route.isNew()) {
				// Creation
				const route: Route = await this.routeService.create(
					this.route.toPayload()
				);
				console.log(route);

				this.create.next(route);
			} else {
				// Update
				await this.routeService.update(
					this.route.getId(),
					this.route.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}

	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			if (key === 'itinerary' || key == 'color') continue;
			this.route.props[key] = this.form.get(key).value;
		}
	}

	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			name: this.route.props.name,
			itinerary: this.route.props.itinerary,
			published: this.route.props.published,
			plateau: this.route.props.plateau,
			duration: this.route.props.duration,
			color: this.route.props.color,
		});
		this.color = '#' + this.route.props.color;
	}

	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.routeService
			.remove(this.route.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}

	initMap() {
		if (this.route.props.itinerary?.length) {
			const latLngs = this.pointsToLatLngs(this.route.props.itinerary);
			this.mapDrawnItems.addLayer(new Polyline(latLngs));
		}
		this.mapDisplay = true;
	}

	/* Clear layers before adding a new one */
	onDrawStart() {
		if (this.isLayerExistent()) this.mapDrawnItems.clearLayers();
	}

	/* Add an itinerary layer to the mapn on drawCreated event*/
	onDrawCreated(e: DrawEvents.Created) {
		const polyline = e.layer as Polyline;
		this.mapDrawnItems.addLayer(polyline);
		this.route.props.itinerary = this.latLngToPoints(
			polyline.getLatLngs() as LatLng[]
		);
		console.log(this.route.props.itinerary);
	}

	/* Update itinerary property of the model on drawEdited event*/
	onDrawEdited(e: DrawEvents.Edited) {
		const polyline = e.layers.getLayers()[0] as Polyline;
		this.route.props.itinerary = this.latLngToPoints(
			polyline.getLatLngs() as LatLng[]
		);
	}

	/* Map an array of LatLng to an array of RoutePoint*/
	latLngToPoints(latLngs: LatLng[]): RoutePoint[] {
		return latLngs.map((latLng) => {
			return { latitude: latLng.lat, longitude: latLng.lng };
		});
	}

	/* Map an array of RoutePoint to an array of LatLng*/
	pointsToLatLngs(points: RoutePoint[]): LatLng[] {
		return points.map(
			(point) => new LatLng(point.latitude, point.longitude)
		);
	}

	/* Check if a layer already exists */
	isLayerExistent(): boolean {
		return this.mapDrawnItems.getLayers().length !== 0;
	}

	updateMapCenter(lat: number, lng: number) {
		this.mapOptions.center = new LatLng(lat, lng);
	}

	// Update POI markers on the map
	updatePoiMap(): void {
		this.mapPoiItems.clearLayers();
		this.getPois().then((pois) => {
			pois.map((poi) => {
				const latLng = new LatLng(
					poi.props.latitude,
					poi.props.longitude
				);
				const marker = new Marker(latLng, {
					icon: this.icon,
					title: poi.props.label,
				});
				this.mapPoiItems.addLayer(marker);
			});
		});
	}

	protected async getPois(): Promise<sMarker[]> {
		console.log(this.route.props.plateau);

		var p = this.route.props.plateau as Plateau;
		const params = new MarkerSearchParams({
			_limit: 100,
			_page: 0,
			plateau: p.props._id,
		});

		return (await this.markerService.list(params.toObject())).items;
	}

	private onChangedColor(color: string) {
		this.form.patchValue({ color: color });

		this.route.props.color = color.substring(1);
	}
}
