import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PasswordService } from '@app/services';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ExplainErrorsService } from '@app/services/explain-errors.service';

@Component({
	selector: 'app-atom-lost-password',
	templateUrl: './atom-lost-password.component.html',
	styleUrls: ['./atom-lost-password.component.less'],
})
export class AtomLostPasswordComponent implements OnInit {
	/** @type {FormGroup} The form group to use */
	form: FormGroup;
	processing = false;
	resetRequestSentTo: string;

	@Output() success = new EventEmitter<void>();
	@Output() cancel = new EventEmitter<void>();

	constructor(
		public router: Router,
		private formBuilder: FormBuilder,
		private passwordService: PasswordService,
		public explainErrorService: ExplainErrorsService
	) {}

	ngOnInit() {
		this.initForm();
	}

	// Init the sign in form
	private initForm() {
		this.form = this.formBuilder.group({
			email: new FormControl('', [Validators.email, Validators.required]),
		});
	}

	submit(): void {
		this.resetRequestSentTo = null;

		const email = this.form.get('email').value;

		this.passwordService
			.request(email)
			.then(() => {
				this.resetRequestSentTo = email;
				this.success.emit();
			})
			.catch((err) => {
				if (err.status === 404) {
					this.form.get('email').setErrors({ notFound: true });
				}
			});
	}
}
