import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {PlateauSearchParams} from '../plateau-search-params';
import {PlateauService} from '../plateau.service';
import {Plateau} from '../plateau';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-plateau-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class PlateauSelectComponent extends EntitySelectComponent< Plateau> {
  protected modelName = 'plateau';
  /**
   * Constructor
   * @param {PlateauService} plateauService
   * @param {ErrorService} errorService
   */
  constructor(private plateauService: PlateauService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Plateau)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Plateau[]>this.model).push(<Plateau>model);
          } else {
            this.model = <Plateau>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Plateau[]> {
      const params = new PlateauSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.plateauService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Plateau> {
      return await this.plateauService.get(id);
  }
}
