import {Component, Input} from '@angular/core';
import {ErrorService} from '@app/services';
import {EntityTableComponent} from '@app/abstracts';
import {VideoSearchParams} from '../video-search-params';
import {VideoService} from '../video.service';
import {Video} from '../video';

@Component({
  selector: 'hpf-video-table',
  templateUrl: './video-table.component.html'
})
export class VideoTableComponent extends EntityTableComponent< Video, VideoSearchParams> {
  /** Columns to show */
  @Input() columns = [
    'created_at',
    'name',
    'uri',
  ];
  /** Constructor */
  constructor(private videoService: VideoService,
              private errorService: ErrorService) {
    super();
  }
  /** @inheritDoc */
  protected refresh(): void {
    const params = this.searchParams.toObject();
    // Set refreshing flag
    this.refreshing = true;
    // Get list
    this.videoService.list(params)
      .then((results) => {
        // Link data
        this.items = results.items;
        this.total = results.total;
      })
      .catch((error) => this.errorService.handle(error))
      .then(() => {
        this.refreshing = false;
      });
  }

  /** Called when the user select multiple rows and delete them */
  async removeMany() {
    if (!this.selectedRows.length) {
        return;
    }

    this.deleting = true;
    for (const item of this.selectedRows) {
      await this.videoService.remove(item.getId())
        .catch(e => this.errorService.handle(e));
    }

    this.deleteMany.next(this.selectedRows);
    this.resetSelectedRows();
    this.deleting = false;
    this.deleteManyModal = false;
    this.searchParams.next();
  }
  /** Called when the user delete a single row */
  async removeOne() {
    if (!this.rowToDelete) {
        return;
    }

    this.deleting = true;
    await this.videoService.remove(this.rowToDelete.getId())
      .catch(e => this.errorService.handle(e));

    this.deleteOne.next(this.rowToDelete);
    delete this.rowToDelete;
    this.deleting = false;
    this.deleteOneModal = false;
    this.searchParams.next();
  }

}
