import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Image, ImageInterface } from '../image/image';
import {
	TextTranslated,
	TextTranslatedInterface,
} from '../text-translated/text-translated';
import { Language } from '../language/language';
export interface CategoryInterface extends BaseModelInterface {
	created_at: number | Date;
	name: string;
	image: string | Image | ImageInterface;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	order: number;
}
export interface CategoryPayload {
	name: string;
	image: string | Image | ImageInterface;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	order: number;
}
type CategoryPayloadKey = keyof CategoryPayload;
export class Category extends BaseModel<CategoryInterface, CategoryPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of image has been populated */
	imageExists(): boolean {
		return (
			!!this.props &&
			this.props.image instanceof Image &&
			this.props.image.exists()
		);
	}
	getTitle(): string {
		var regex = /(<([^>]+)>)/gi;

		let lng = localStorage.getItem('lang');
		var tt = this.props.title.find(function (params: TextTranslated) {
			var l = params.props.lang as Language;
			return lng.toLowerCase() == l.props.name.toLowerCase();
		}) as TextTranslated;
		return tt.props.text.replace(regex, '');
	}
	/** Denotes if the instance of title has been populated */
	titleExists(): boolean {
		return (
			!!this.props &&
			this.props.title instanceof Array &&
			(<TextTranslated[]>this.props.title).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: CategoryInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.image === 'object') {
			this.props.image = new Image(<ImageInterface>this.props.image);
		}
		if (this.props.title instanceof Array) {
			this.props.title = (<TextTranslatedInterface[]>(
				this.props.title
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): CategoryInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.title === 'undefined') {
			props.title = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.image instanceof Image) {
			props.image = props.image.toObject();
		}
		if (this.props.title instanceof Array) {
			props.title = (<TextTranslated[]>props.title).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): CategoryPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as CategoryInterface);
		payload.image = payload.image ? this.extractId(payload.image) : null;
		payload.title = payload.title
			? payload.title.map((i) => this.extractId(i))
			: null;
		return payload as CategoryPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): CategoryPayloadKey[] {
		return ['name', 'image', 'title', 'order'];
	}
}
