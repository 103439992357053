import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {Plateau} from '../../plateau';
import {PlateauService} from '../../plateau.service';
@Component({
  selector: 'hpf-plateau-edit',
  templateUrl: './plateau-edit.component.html'
})
export class PlateauEditComponent implements OnInit, OnDestroy {
  /** The plateau */
  plateau = new Plateau();
  /** The plateau */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private plateauService: PlateauService) {}
  /** Init */
  ngOnInit() {
    // Get the plateau after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch plateau from API */
  private refresh() {
    this.plateauService.get(this.id, { _populate: ['*'] })
      .then((plateau: Plateau) => {
        this.plateau.fromObject(plateau.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the plateau is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the plateau is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/plateau')
      .catch((error) => this.errorService.handle(error));
  }
}
