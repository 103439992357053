<form
	nz-form
	[formGroup]="form"
	(submit)="submit()"
	[class.d-none]="resetRequestSentTo"
	[nzAutoTips]="explainErrorService.autoTips"
>
	<!-- Email -->
	<nz-form-item class="w-100">
		<nz-form-control>
			<input
				nz-input
				type="email"
				placeholder="{{ 'atom-sign-in-input-email' | translate }}"
				formControlName="email"
				autocomplete="email"
			/>
		</nz-form-control>
	</nz-form-item>
	<!-- /Email -->
	<div class="w-100 d-flex justify-content-between">
		<button
			nz-button
			nzType="default"
			type="button"
			(click)="cancel.emit(); router.navigate(['/sign-in'])"
		>
			{{ 'atom-lost-password-cancel' | translate }}
		</button>
		<button
			nz-button
			nzType="primary"
			type="submit"
			[disabled]="form.invalid"
			[nzLoading]="processing"
		>
			{{ 'atom-lost-password-submit' | translate }}
		</button>
	</div>
</form>
<p *ngIf="resetRequestSentTo" class="text-center">
	{{
		'atom-sign-in-reset-password-request-sent'
			| translate: { email: resetRequestSentTo }
	}}
</p>
