import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { RolePermission } from '../../role-permission';
import { RolePermissionService } from '../../role-permission.service';
@Component({
	selector: 'hpf-role-permission-edit',
	templateUrl: './role-permission-edit.component.html',
})
export class RolePermissionEditComponent implements OnInit, OnDestroy {
	/** The role permission */
	rolePermission = new RolePermission();
	/** The role permission */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private rolePermissionService: RolePermissionService
	) {}
	/** Init */
	ngOnInit() {
		// Get the role permission after reading the id
		this.idSubscription = this.route.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch role permission from API */
	private refresh() {
		this.rolePermissionService
			.get(this.id, { _populate: ['*'] })
			.then((rolePermission: RolePermission) => {
				this.rolePermission.fromObject(rolePermission.toObject());
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the role permission is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the role permission is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/role-permission')
			.catch((error) => this.errorService.handle(error));
	}
}
