import {Injectable} from '@angular/core';
import {User, UserInterface, UserPayload} from './user';
import {UserSearchParamsInterface} from './user-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class UserService extends BaseModelService< User, UserInterface, UserPayload, UserSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'user';
  }
  /** @inheritDoc */
  protected new(object: UserInterface): User {
    return new User(object);
  }
}
