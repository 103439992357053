import {Component, Input, OnInit} from '@angular/core';
import {MarkerSearchParams} from '../marker-search-params';
@Component({
  selector: 'hpf-marker-filters',
  templateUrl: './marker-filters.component.html'
})
export class MarkerFiltersComponent implements OnInit {
  /** The filters */
  @Input() searchParams: MarkerSearchParams = new MarkerSearchParams();
  /** Denotes if the page number should be set to 0 when filters change */
  @Input() resetPage = false;
  /** Constructor */
  constructor() {}
  /** Init */
  ngOnInit() {
  }
  /** Called when an input is changed */
  onChange() {
    if (this.resetPage) {
        this.searchParams.props._page = 0;
    }
    this.searchParams.next();
  }
  /** Clear all filters */
  public resetSearchParams() {
    this.searchParams.fromObject({});
  }
}
