import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from './error.service';

@Injectable({
	providedIn: 'root',
})
export class ExplainErrorsService {
	get autoTips(): Record<string, Record<string, string>> {
		return this._autoTips;
	}
	private _autoTips: Record<string, Record<string, string>> = {};

	constructor(
		private translateService: TranslateService,
		private errorService: ErrorService
	) {
		this.defineAutoTips().catch((e) => this.errorService.handle(e));
	}

	private async defineAutoTips() {
		for (const lang of this.translateService.getLangs()) {
			const translations = await this.translateService
				.getTranslation(lang)
				.toPromise();
			this._autoTips[lang] = {
				min: translations['common_error-min'], // {{ 'common_error-min' | translate }}
				max: translations['common_error-max'], // {{ 'common_error-max' | translate }}
				required: translations['common_error-required'], // {{ 'common_error-required' | translate }}
				requiredtrue: translations['common_error-required_true'], // {{ 'common_error-required_true' | translate }}
				email: translations['common_error-email'], // {{ 'common_error-email' | translate }}
				minlength: translations['common_error-min_length'], // {{ 'common_error-min_length' | translate }}
				maxlength: translations['common_error-max_length'], // {{ 'common_error-max_length' | translate }}
				pattern: translations['common_error-pattern'], // {{ 'common_error-pattern' | translate }}
			};
		}
	}
}
