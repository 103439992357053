import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { Role } from '../../role';
import { RoleService } from '../../role.service';
import { RolePermissionSearchParams } from '../../../role-permission/role-permission-search-params';
import { RolePermission } from '../../../role-permission/role-permission';
@Component({
	selector: 'hpf-role-edit',
	templateUrl: './role-edit.component.html',
})
export class RoleEditComponent implements OnInit, OnDestroy {
	/** The role */
	role = new Role();
	/** The role */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** The filter for role permission as role */
	rolePermissionAsRoleSearchParams = new RolePermissionSearchParams();
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private roleService: RoleService
	) {}
	/** Init */
	ngOnInit() {
		// Get the role after reading the id
		this.idSubscription = this.route.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
			// Filter by role for role permission
			this.rolePermissionAsRoleSearchParams.fromObject({ role: this.id });
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch role from API */
	private refresh() {
		this.roleService
			.get(this.id, { _populate: ['*'] })
			.then((role: Role) => {
				this.role.fromObject(role.toObject());
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the role is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the role is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/role')
			.catch((error) => this.errorService.handle(error));
	}
	/** Called on when user select a role permission as role */
	onSelectRolePermissionAsRole(item: RolePermission): void {
		this.router.navigateByUrl(`/role-permission/${item.getId()}`);
	}
}
