import { OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
	BaseModelSearchParams,
	BaseModelSearchParamsInterface,
} from '@app/abstracts/base-model-search-params';
import { Subscription as RxJsSubscription, Subject as RxJsSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export abstract class EntityListTopBarComponent<
	T extends BaseModelSearchParams<BaseModelSearchParamsInterface>
> implements OnInit, OnDestroy {
	@Input() title = '';
	@Input() createButton = '';
	@Input() quickSearchPlaceholder = '';
	@Input() quickSearchTooltip = '';
	@Input() searchParams: T;
	@Input() quickSearchParam = '_text';
	@Input() showCreateButton = true;
	@Input() showQuickSearch = false;
	@Input() showAdvancedSearch = false;
	@Output() createEvent: EventEmitter<void> = new EventEmitter<void>();
	@Input() activeDisplayMode = false;
	@Output() filterEvent: EventEmitter<void> = new EventEmitter<void>();

	displayModeValue = 'table';
	@Output()
	displayModeChange = new EventEmitter();
	set displayMode(val) {
		this.displayModeValue = val;
		this.displayModeChange.emit(this.displayModeValue);
	}
	@Input() get displayMode() {
		return this.displayModeValue;
	}

	/** The debuonce delay before calling the API for filtering */
	@Input() filterDebounceTime = 300;
	/** Subject for debounced keyup event */
	private keyupSubject = new RxJsSubject<void>();
	/** Subscription of the component */
	private subscriptions: RxJsSubscription[] = [];
	/** Denotes if the page number should be set to 0 when filters change */
	@Input() resetPage = false;

	/** Show filter drawer or not */
	filterIsActivated = false;

	constructor() {}

	ngOnInit() {
		// Subscription
		this.subscriptions.push(
			this.keyupSubject
				.pipe(debounceTime(this.filterDebounceTime))
				.subscribe(() => {
					this.onChange();
				})
		);

		this.subscriptions.push(
			this.searchParams.subscribe(() => {
				this.filterIsActivated = this.isFilterActivated();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.map((sub) => {
			sub.unsubscribe();
		});
	}
	/** Called when a keyboard input is changed */
	onChangeDebounced() {
		this.keyupSubject.next();
	}

	/** Called when an input is changed */
	onChange() {
		if (this.resetPage) {
			this.searchParams.props._page = 0;
		}
		this.searchParams.next();
	}

	/** Check if an advanced filter is activated */
	private isFilterActivated(): boolean {
		const params = this.searchParams.toObject();
		const paramsKeys = Object.keys(params);
		const ignoredFilters = [
			'_id',
			'_text',
			'_limit',
			'_order',
			'_page',
			'_sort',
		];
		for (const key of paramsKeys) {
			if (
				!ignoredFilters.includes(key) &&
				typeof params[key] !== 'undefined'
			) {
				return true;
			}
		}
		return false;
	}
}
