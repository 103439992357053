import {Component, Input, OnInit} from '@angular/core';
import {ErrorService} from '@app/services';
import {MarkerSearchParams} from '../marker-search-params';
import {MarkerService} from '../marker.service';
@Component({
  selector: 'hpf-marker-count',
  templateUrl: './marker-count.component.html'
})
export class MarkerCountComponent implements OnInit {
  /** The filters */
  @Input() searchParams: MarkerSearchParams = new MarkerSearchParams();
  /** Count result */
  count: number;
  /** Load flag */
  loaded = false;
  /** Constructor */
  constructor(private markerService: MarkerService,
              private errorService: ErrorService) {}
  /** Init */
  ngOnInit() {
    this.markerService.count(this.searchParams.toObject())
    .then((count) => {
      this.count = count;
      this.loaded = true;
    })
    .catch((error) => this.errorService.handle(error))
  }
}
