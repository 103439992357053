<hpf-language-form
	[language]="language"
	[loading]="language.isNew()"
	(update)="onUpdate()"
	(delete)="onDelete()"
></hpf-language-form>
<div class="container">
	<div
		class="row"
		*appRestrictedByScope="{
			model: 'audio',
			action: 'search'
		}"
	>
		<div class="py-4 pb-5 col-12">
			<div class="py-3">
				<h3>
					<a
						[routerLink]="['/audio']"
						[queryParams]="audioAsLangSearchParams.toObject()"
					>
						{{ 'language_audio-as-lang' | translate }}
					</a>
				</h3>
			</div>
			<hpf-audio-table
				[searchParams]="audioAsLangSearchParams"
				(select)="onSelectAudioAsLang($event)"
			>
			</hpf-audio-table>
		</div>
	</div>
	<div
		class="row"
		*appRestrictedByScope="{
			model: 'text_translated',
			action: 'search'
		}"
	></div>

	<hpf-activity-history
		*ngIf="historySearchParams"
		[historySearchParams]="historySearchParams"
	></hpf-activity-history>
</div>
