import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { RolePermission } from '../role-permission';
import { RolePermissionService } from '../role-permission.service';
import { ModelsLinks } from '@app/shared';
@Component({
	selector: 'hpf-role-permission-form',
	templateUrl: './role-permission-form.component.html',
})
export class RolePermissionFormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The role permission to inject in the form */
	@Input() rolePermission: RolePermission;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<RolePermission>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	/** List of all collection */
	collectionList: string[];

	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private rolePermissionService: RolePermissionService,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'role_permission',
			'update'
		));

		this.collectionList = ModelsLinks.map((m) => m.model);

		// Create form
		this.form = this.formBuilder.group({
			role: new FormControl(this.rolePermission.props.role, [
				Validators.required,
			]),
			collection: new FormControl(this.rolePermission.props.collection, [
				Validators.required,
			]),
			create: new FormControl(this.rolePermission.props.create, [
				Validators.required,
			]),
			read: new FormControl(this.rolePermission.props.read, [
				Validators.required,
			]),
			update: new FormControl(this.rolePermission.props.update, [
				Validators.required,
			]),
			delete: new FormControl(this.rolePermission.props.delete, [
				Validators.required,
			]),
			search: new FormControl(this.rolePermission.props.search, [
				Validators.required,
			]),
			count: new FormControl(this.rolePermission.props.count, [
				Validators.required,
			]),
		});
		// Update form when model loads or changes
		this.modelSubscription = this.rolePermission.subscribe(() => {
			this.updateForm();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();
			// Creation or update ?
			if (this.rolePermission.isNew()) {
				// Creation
				const rolePermission: RolePermission = await this.rolePermissionService.create(
					this.rolePermission.toPayload()
				);
				this.create.next(rolePermission);
			} else {
				// Update
				await this.rolePermissionService.update(
					this.rolePermission.getId(),
					this.rolePermission.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.rolePermission.props[key] = this.form.get(key).value;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			role: this.rolePermission.props.role,
			collection: this.rolePermission.props.collection,
			create: this.rolePermission.props.create,
			read: this.rolePermission.props.read,
			update: this.rolePermission.props.update,
			delete: this.rolePermission.props.delete,
			search: this.rolePermission.props.search,
			count: this.rolePermission.props.count,
		});
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.rolePermissionService
			.remove(this.rolePermission.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
