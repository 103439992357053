import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ErrorService} from '@app/services';
import {AudioSearchParams} from '../audio-search-params';
import {AudioService} from '../audio.service';
@Component({
  selector: 'hpf-audio-count',
  templateUrl: './audio-count.component.html'
})
export class AudioCountComponent implements OnInit {
  /** The filters */
  @Input() searchParams: AudioSearchParams = new AudioSearchParams();
  /** Count result */
  count: number;
  /** Load flag */
  loaded = false;
  /** Constructor */
  constructor(private audioService: AudioService,
              private errorService: ErrorService) {}
  /** Init */
  ngOnInit() {
    this.audioService.count(this.searchParams.toObject())
    .then((count) => {
      this.count = count;
      this.loaded = true;
    })
    .catch((error) => this.errorService.handle(error))
  }
}
