import {Injectable} from '@angular/core';
import {Route, RouteInterface, RoutePayload} from './route';
import {RouteSearchParamsInterface} from './route-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class RouteService extends BaseModelService< Route, RouteInterface, RoutePayload, RouteSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'route';
  }
  /** @inheritDoc */
  protected new(object: RouteInterface): Route {
    return new Route(object);
  }
}
