import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-diaporama',
  templateUrl: './diaporama.component.html'
})
export class DiaporamaComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
