import {Injectable} from '@angular/core';
import {Category, CategoryInterface, CategoryPayload} from './category';
import {CategorySearchParamsInterface} from './category-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class CategoryService extends BaseModelService< Category, CategoryInterface, CategoryPayload, CategorySearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'category';
  }
  /** @inheritDoc */
  protected new(object: CategoryInterface): Category {
    return new Category(object);
  }
}
