<nz-drawer [nzClosable]="false" [(nzVisible)]="filtersDrawer" nzPlacement="right" (nzOnClose)="filtersDrawer = false"
  [nzTitle]="'common_filters-title' | translate" nzWidth="300">
  <hpf-image-filters [searchParams]="searchParams" [resetPage]="true"></hpf-image-filters>
</nz-drawer>

<hpf-image-list-top-bar [title]="'image_common_name' | translate" (filterEvent)="filtersDrawer = true"
  [searchParams]="searchParams" (createEvent)="onCreate()" (createBulkEvent)="onCreateBulk()"
  [createButton]="'common_create' | translate" [showQuickSearch]="true"
  [quickSearchTooltip]="'common_search-by' | translate: { list: 'name' }"
  [quickSearchPlaceholder]="'common_quick-search' | translate" [showAdvancedSearch]="true"></hpf-image-list-top-bar>
<div class="container-fluid px-5">
  <div class="row my-5">
    <div class="col-12">
      <hpf-image-table [searchParams]="searchParams" [enableMultiSelect]="true" [showActions]="true"
        (select)="onSelect($event)"></hpf-image-table>
    </div>
  </div>
</div>
