<hpf-category-form
	[category]="category"
	[loading]="category.isNew()"
	(update)="onUpdate()"
	(delete)="onDelete()"
></hpf-category-form>
<div class="container">
	<div
		class="row"
		*appRestrictedByScope="{
			model: 'point_of_interest',
			action: 'search'
		}"
	>
		<div class="py-4 pb-5 col-12">
			<div class="py-3">
				<h3>
					<a
						[routerLink]="['/point-of-interest']"
						[queryParams]="
							pointOfInterestAsCategoriesSearchParams.toObject()
						"
					>
						{{
							'category_point_of_interest-as-categories'
								| translate
						}}
					</a>
				</h3>
			</div>
			<hpf-point-of-interest-table
				[searchParams]="pointOfInterestAsCategoriesSearchParams"
				(select)="onSelectPointOfInterestAsCategories($event)"
			>
			</hpf-point-of-interest-table>
		</div>
	</div>

	<hpf-activity-history
		*ngIf="historySearchParams"
		[historySearchParams]="historySearchParams"
	></hpf-activity-history>
</div>
