<!-- Role -->
<nz-form-item>
	<nz-form-label>{{ 'role-permission_role' | translate }}</nz-form-label>
	<hpf-role-select
		[(id)]="searchParams.props.role"
		[nullable]="true"
		[emptyLabel]="'common_empty' | translate"
		[filterEnabled]="true"
		[placeholder]="'role_common_search-placeholder' | translate"
		(change)="onChange()"
		[quickFormEnabled]="false"
	>
	</hpf-role-select>
</nz-form-item>
<!-- /Role -->
<!-- Collection -->
<nz-form-item>
	<nz-form-label>{{
		'role-permission_collection' | translate
	}}</nz-form-label>
	<input
		nz-input
		class="w-100"
		(keyup)="onChangeDebounced()"
		[placeholder]="'common_value-string' | translate"
		[(ngModel)]="searchParams.props.collection"
	/>
</nz-form-item>
<!-- /Collection -->
<!-- Create -->
<nz-form-item>
	<nz-form-label>{{ 'role-permission_create' | translate }}</nz-form-label>
	<nz-select
		class="w-100"
		[nzPlaceHolder]="'common_value-boolean' | translate"
		[(ngModel)]="searchParams.props.create"
		(ngModelChange)="onChange()"
		[nzAllowClear]="true"
	>
		<nz-option
			[nzValue]="true"
			[nzLabel]="'common_true' | translate"
		></nz-option>
		<nz-option
			[nzValue]="false"
			[nzLabel]="'common_false' | translate"
		></nz-option>
	</nz-select>
</nz-form-item>
<!-- /Create -->
<!-- Read -->
<nz-form-item>
	<nz-form-label>{{ 'role-permission_read' | translate }}</nz-form-label>
	<nz-select
		class="w-100"
		[nzPlaceHolder]="'common_value-boolean' | translate"
		[(ngModel)]="searchParams.props.read"
		(ngModelChange)="onChange()"
		[nzAllowClear]="true"
	>
		<nz-option
			[nzValue]="true"
			[nzLabel]="'common_true' | translate"
		></nz-option>
		<nz-option
			[nzValue]="false"
			[nzLabel]="'common_false' | translate"
		></nz-option>
	</nz-select>
</nz-form-item>
<!-- /Read -->
<!-- Update -->
<nz-form-item>
	<nz-form-label>{{ 'role-permission_update' | translate }}</nz-form-label>
	<nz-select
		class="w-100"
		[nzPlaceHolder]="'common_value-boolean' | translate"
		[(ngModel)]="searchParams.props.update"
		(ngModelChange)="onChange()"
		[nzAllowClear]="true"
	>
		<nz-option
			[nzValue]="true"
			[nzLabel]="'common_true' | translate"
		></nz-option>
		<nz-option
			[nzValue]="false"
			[nzLabel]="'common_false' | translate"
		></nz-option>
	</nz-select>
</nz-form-item>
<!-- /Update -->
<!-- Delete -->
<nz-form-item>
	<nz-form-label>{{ 'role-permission_delete' | translate }}</nz-form-label>
	<nz-select
		class="w-100"
		[nzPlaceHolder]="'common_value-boolean' | translate"
		[(ngModel)]="searchParams.props.delete"
		(ngModelChange)="onChange()"
		[nzAllowClear]="true"
	>
		<nz-option
			[nzValue]="true"
			[nzLabel]="'common_true' | translate"
		></nz-option>
		<nz-option
			[nzValue]="false"
			[nzLabel]="'common_false' | translate"
		></nz-option>
	</nz-select>
</nz-form-item>
<!-- /Delete -->
<!-- Search -->
<nz-form-item>
	<nz-form-label>{{ 'role-permission_search' | translate }}</nz-form-label>
	<nz-select
		class="w-100"
		[nzPlaceHolder]="'common_value-boolean' | translate"
		[(ngModel)]="searchParams.props.search"
		(ngModelChange)="onChange()"
		[nzAllowClear]="true"
	>
		<nz-option
			[nzValue]="true"
			[nzLabel]="'common_true' | translate"
		></nz-option>
		<nz-option
			[nzValue]="false"
			[nzLabel]="'common_false' | translate"
		></nz-option>
	</nz-select>
</nz-form-item>
<!-- /Search -->
<!-- Count -->
<nz-form-item>
	<nz-form-label>{{ 'role-permission_count' | translate }}</nz-form-label>
	<nz-select
		class="w-100"
		[nzPlaceHolder]="'common_value-boolean' | translate"
		[(ngModel)]="searchParams.props.count"
		(ngModelChange)="onChange()"
		[nzAllowClear]="true"
	>
		<nz-option
			[nzValue]="true"
			[nzLabel]="'common_true' | translate"
		></nz-option>
		<nz-option
			[nzValue]="false"
			[nzLabel]="'common_false' | translate"
		></nz-option>
	</nz-select>
</nz-form-item>
<!-- /Count -->
<button nz-button nzType="default" (click)="resetSearchParams()">
	{{ 'common_reset-filters' | translate }}
</button>
