import { Helpers } from '@app/shared';
import {
	BaseModelSearchParams,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';

/** Used to export and import search params */
export interface RolePermissionSearchParamsInterface
	extends BaseModelSearchParamsInterface {
	_sort?:
		| '_id'
		| 'created_at'
		| 'role'
		| 'collection'
		| 'create'
		| 'read'
		| 'update'
		| 'delete'
		| 'search'
		| 'count';
	_id?: string[];
	_text?: string;
	role?: string;
	collection?: string;
	create?: 'true' | 'false' | boolean;
	read?: 'true' | 'false' | boolean;
	update?: 'true' | 'false' | boolean;
	delete?: 'true' | 'false' | boolean;
	search?: 'true' | 'false' | boolean;
	count?: 'true' | 'false' | boolean;
}

/** Manage role permission search params */
export class RolePermissionSearchParams extends BaseModelSearchParams<
	RolePermissionSearchParamsInterface
> {
	/** @inheritDoc */
	fromObject(input: Partial<RolePermissionSearchParamsInterface>): void {
		// Clone object with allowed keys only
		const props = {} as RolePermissionSearchParamsInterface;
		this.allowedKeys().map((key: string) => {
			if (typeof input[key] !== 'undefined') {
				props[key] = input[key];
			}
		});

		// Keep default values
		if (typeof props._page === 'undefined') {
			props._page = this.defaultPage;
		}
		if (typeof props._limit === 'undefined') {
			props._limit = this.defaultLimit;
		}
		if (typeof props._sort === 'undefined') {
			props._sort = this.defaultSort;
		}
		if (typeof props._order === 'undefined') {
			props._order = this.defaultOrder;
		}
		// Convert potentially multiple primary keys
		if (typeof props._id === 'string') {
			props._id = (<string>props._id).split(',').filter((s) => s.length);
		}

		// Convert boolean create
		if (typeof props.create !== 'undefined') {
			props.create = Helpers.convertToBoolean(props.create);
		}

		// Convert boolean read
		if (typeof props.read !== 'undefined') {
			props.read = Helpers.convertToBoolean(props.read);
		}

		// Convert boolean update
		if (typeof props.update !== 'undefined') {
			props.update = Helpers.convertToBoolean(props.update);
		}

		// Convert boolean delete
		if (typeof props.delete !== 'undefined') {
			props.delete = Helpers.convertToBoolean(props.delete);
		}

		// Convert boolean search
		if (typeof props.search !== 'undefined') {
			props.search = Helpers.convertToBoolean(props.search);
		}

		// Convert boolean count
		if (typeof props.count !== 'undefined') {
			props.count = Helpers.convertToBoolean(props.count);
		}

		// Assign values
		this.props = props;
		this.next();
	}
	/** @inheritDoc */
	toObject(): RolePermissionSearchParamsInterface {
		// Filter not allowed, undefined and null values
		const props = {} as RolePermissionSearchParamsInterface;
		this.allowedKeys().map(Helpers.prepareObjectForApi(this.props, props));

		// Convert boolean create
		if (typeof props.create !== 'undefined') {
			props.create = props.create ? 'true' : 'false';
		}

		// Convert boolean read
		if (typeof props.read !== 'undefined') {
			props.read = props.read ? 'true' : 'false';
		}

		// Convert boolean update
		if (typeof props.update !== 'undefined') {
			props.update = props.update ? 'true' : 'false';
		}

		// Convert boolean delete
		if (typeof props.delete !== 'undefined') {
			props.delete = props.delete ? 'true' : 'false';
		}

		// Convert boolean search
		if (typeof props.search !== 'undefined') {
			props.search = props.search ? 'true' : 'false';
		}

		// Convert boolean count
		if (typeof props.count !== 'undefined') {
			props.count = props.count ? 'true' : 'false';
		}

		return props;
	}
	/** @inheritDoc */
	protected allowedKeys(): string[] {
		return super
			.allowedKeys()
			.concat([
				'_id',
				'_text',
				'role',
				'collection',
				'create',
				'read',
				'update',
				'delete',
				'search',
				'count',
			]);
	}
}
