<!-- Initiator -->
<nz-form-item>
	<nz-form-label>{{ 'activity-log_initiator' | translate }}</nz-form-label>
	<hpf-user-select
		[(id)]="searchParams.props.initiator"
		[nullable]="true"
		[emptyLabel]="'common_empty' | translate"
		[filterEnabled]="true"
		[placeholder]="'user_common_search-placeholder' | translate"
		(change)="onChange()"
		[quickFormEnabled]="false"
	>
	</hpf-user-select>
</nz-form-item>
<!-- /Initiator -->
<!-- Name -->
<nz-form-item>
	<nz-form-label>{{ 'activity-log_name' | translate }}</nz-form-label>
	<input
		nz-input
		class="w-100"
		(keyup)="onChangeDebounced()"
		[placeholder]="'common_value-string' | translate"
		[(ngModel)]="searchParams.props.name"
	/>
</nz-form-item>
<!-- /Name -->
<!-- Entity Type -->
<nz-form-item>
	<nz-form-label>{{ 'activity-log_entity-type' | translate }}</nz-form-label>
	<input
		nz-input
		class="w-100"
		(keyup)="onChangeDebounced()"
		[placeholder]="'common_value-string' | translate"
		[(ngModel)]="searchParams.props.entity_type"
	/>
</nz-form-item>
<!-- /Entity Type -->
<!-- Entity Id -->
<nz-form-item>
	<nz-form-label>{{ 'activity-log_entity-id' | translate }}</nz-form-label>
	<input
		nz-input
		class="w-100"
		(keyup)="onChangeDebounced()"
		[placeholder]="'common_value-string' | translate"
		[(ngModel)]="searchParams.props.entity_id"
	/>
</nz-form-item>
<!-- /Entity Id -->
<!-- Action -->
<nz-form-item>
	<nz-form-label>{{ 'activity-log_action' | translate }}</nz-form-label>
	<input
		nz-input
		class="w-100"
		(keyup)="onChangeDebounced()"
		[placeholder]="'common_value-string' | translate"
		[(ngModel)]="searchParams.props.action"
	/>
</nz-form-item>
<!-- /Action -->
<!-- Previous -->
<nz-form-item>
	<nz-form-label>{{ 'activity-log_previous' | translate }}</nz-form-label>
	<hpf-activity-log-select
		[(id)]="searchParams.props.previous"
		[nullable]="true"
		[emptyLabel]="'common_empty' | translate"
		[filterEnabled]="true"
		[placeholder]="'activity-log_common_search-placeholder' | translate"
		(change)="onChange()"
		[quickFormEnabled]="false"
	>
	</hpf-activity-log-select>
</nz-form-item>
<!-- /Previous -->
<button nz-button nzType="default" (click)="resetSearchParams()">
	{{ 'common_reset-filters' | translate }}
</button>
