import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignInComponent } from './sign-in/sign-in.component';
import { SessionComponent } from './session/session.component';
import { MoleculeLostPasswordComponent } from './molecule-lost-password/molecule-lost-password.component';
import { MoleculePasswordResetComponent } from './molecule-password-reset/molecule-password-reset.component';
import { IsNotLoggedGuard } from '@app/guards';

const routes: Routes = [
	{
		path: 'session',
		component: SessionComponent,
		children: [
			{
				path: '',
				redirectTo: 'sign-in',
				pathMatch: 'full',
			},
			{
				path: 'sign-in',
				component: SignInComponent,
				data: {
					title: 'Sign In',
				},
			},
			{
				path: 'lost-password',
				component: MoleculeLostPasswordComponent,
				canActivate: [IsNotLoggedGuard],
			},
			{
				path: 'password/reset/:id/:code',
				component: MoleculePasswordResetComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SessionRoutingModule {}
