<span (click)="open()" class="btn btn-light main">


  <i class="iconAdd" *ngIf="value==null" nz-icon nzType="plus-square" nzTheme="outline"></i>


  <div *ngIf="value!=null">
    <div class="imgPreview" [style.background-image]="'url('+value.getThumbnail()+ ')'">

      <i nz-icon class="edit" (click)="openEdit(value) ;$event.stopPropagation()" nzType="edit" nzTheme="twotone"></i>
      <span class="imgLabel">{{value.getLabel()}}</span>

    </div>

  </div>

</span>
