import { Component, OnInit } from '@angular/core';
import { NavigationService } from '@app/services';

@Component({
	selector: 'app-molecule-lost-password',
	templateUrl: './molecule-lost-password.component.html',
	styleUrls: ['./molecule-lost-password.component.less'],
})
export class MoleculeLostPasswordComponent implements OnInit {
	constructor(private navigation: NavigationService) {}

	ngOnInit() {}

	submit(): void {
		this.navigation.delayed(['/session/sign-in']);
	}

	onCancel(): void {
		this.navigation.go(['/session/sign-in']);
	}

	onSuccess(): void {
		this.navigation.delayed(['/session/sign-in']);
	}
}
