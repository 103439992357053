import { computeMsgId } from '@angular/compiler';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import {
	Component,
	OnInit,
	Input,
	EventEmitter,
	Output,
	ContentChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
	FormArray,
} from '@angular/forms';
import { environment } from '@env/environment';
import { Image, ImageSearchParams, ImageService } from '@app/models/image';
import { DecimalPipe } from '@angular/common';
import { Subject, timer } from 'rxjs';

export class DiaporamaSlide {
	time: number = 0.0;
	image: Image;

	public getViewImage(): string {
		return `${environment.api.uri}/image/view/${this.image}`;
	}

	public getEditImage(): string {
		return `/image/${this.image}`;
	}

	public hasImage(): boolean {
		return this.image != null;
	}

	constructor(time, image) {
		this.time = time;
		this.image = image;
	}
}
@Component({
	selector: 'app-diaporama-slider-tab',
	templateUrl: './diaporama-slider.component.html',
})
export class DiaporamaSliderComponent implements OnInit {
	@Input() name: string;
	@Input() model;
	items: DiaporamaSlide[] = [];

	async ngOnInit(): Promise<void> {
		if (this.model.props[this.name] != null) {
			this.model.props[this.name]
				.sort(function (a, b) {
					if (parseInt(a.time) < parseInt(b.time)) return -1;
					if (parseInt(a.time) > parseInt(b.time)) return 1;
					return 0;
				})
				.map(async (slide) => {
					this.items.push(
						new DiaporamaSlide(
							parseInt(slide.time),
							await this.imageService.get(slide.image)
						)
					);
				});
			this.sortItems();
		}
	}

	UpdateModel() {
		var temp = [];
		this.items.map((slide) => {
			if (slide.hasImage()) {
				temp.push({ time: slide.time, image: slide.image.getId() });
			}
		});
		this.model.props[this.name] = temp;
	}

	sortItems() {
		this.items.sort(function (a: DiaporamaSlide, b: DiaporamaSlide) {
			if (a.time < b.time) return -1;
			if (a.time > b.time) return 1;
			return 0;
		});
	}
	changeTime(slide, value) {
		slide.time = parseInt(value);
		this.UpdateModel();
	}
	imageSelected(slide, img) {
		slide.image = img;
		this.UpdateModel();
	}
	AddSlide() {
		this.items.push(new DiaporamaSlide(0.0, null));
		this.UpdateModel();
	}
	RemoveSlide(slide: DiaporamaSlide) {
		const index: number = this.items.indexOf(slide);
		if (index !== -1) {
			this.items.splice(index, 1);
		}
		this.UpdateModel();
	}
	constructor(
		private formBuilder: FormBuilder,
		private imageService: ImageService
	) {}
}
