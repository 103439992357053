import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-marker',
  templateUrl: './marker.component.html'
})
export class MarkerComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
