import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { TranslateModuleLoad } from '@app/translate-import';
import { RolePermissionModule } from './role-permission/role-permission.module';
import { QuickFormModule } from './quick-form/quick-form.module';
import { QuickFormComponent, QuickFormService } from './quick-form';
import { ActivityModule } from './activity/activity.module';
import { ActivityHistoryComponent } from './activity/activity-history/activity-history.component';
import { ImageModalModule } from '@app/models/image/image-modal/image-modal.module';
import { ImageModalService } from '@app/models/image/image-modal/image-modal.service';
import { ImageModalComponent } from '@app/models/image/image-modal/image-modal.component';
@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		TranslateModuleLoad(),
		ActivityModule,
		RolePermissionModule,
		QuickFormModule,
		ImageModalModule,
	],
	providers: [QuickFormService, ImageModalService],
	exports: [
		QuickFormComponent,
		ActivityHistoryComponent,
		ImageModalComponent,
	],
})
export class PluginsModule {}
