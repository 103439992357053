import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-session',
	templateUrl: './session.component.html',
})
export class SessionComponent implements OnInit {
	/** Constructor */
	constructor() {}

	/** On init */
	ngOnInit() {}
}
