import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {Plateau, PlateauInterface} from '../plateau/plateau';
import {PointOfInterest, PointOfInterestInterface} from '../point-of-interest/point-of-interest';
import {Image, ImageInterface} from '../image/image';
export interface MarkerInterface extends BaseModelInterface {
  created_at: number | Date;
  label: string;
  plateau: (string | Plateau | PlateauInterface);
  point_of_interests?: (string | PointOfInterest | PointOfInterestInterface)[];
  image: (string | Image | ImageInterface);
  latitude: number;
  longitude: number;
}
export interface MarkerPayload {
  label: string;
  plateau: (string | Plateau | PlateauInterface);
  point_of_interests?: (string | PointOfInterest | PointOfInterestInterface)[];
  image: (string | Image | ImageInterface);
  latitude: number;
  longitude: number;
}
type MarkerPayloadKey = keyof MarkerPayload;
export class Marker extends BaseModel< MarkerInterface, MarkerPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.label}`;
  }
  /** Denotes if the instance of plateau has been populated */
  plateauExists(): boolean {
    return !!this.props &&
        this.props.plateau instanceof Plateau &&
        this.props.plateau.exists();
  }
  /** Denotes if the instance of point of interests has been populated */
  pointOfInterestsExists(): boolean {
    return !!this.props &&
        this.props.point_of_interests instanceof Array &&
        (< PointOfInterest[]>this.props.point_of_interests).every((item) => {
            return item instanceof PointOfInterest && item.exists();
        });
  }
  /** Denotes if the instance of image has been populated */
  imageExists(): boolean {
    return !!this.props &&
        this.props.image instanceof Image &&
        this.props.image.exists();
  }
  /** Populate the current instance from an object */
  fromObject(object: MarkerInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (typeof this.props.plateau === 'object') {
        this.props.plateau = new Plateau(< PlateauInterface>this.props.plateau);
    }
    if (this.props.point_of_interests instanceof Array) {
        this.props.point_of_interests = (< PointOfInterestInterface[]>this.props.point_of_interests).map((item) => {
            return typeof item === 'object' ? new PointOfInterest(item) : item;
        });
    }
    if (typeof this.props.image === 'object') {
        this.props.image = new Image(< ImageInterface>this.props.image);
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): MarkerInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.point_of_interests === 'undefined') {
        props.point_of_interests = [];
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (props.plateau instanceof Plateau) {
        props.plateau = props.plateau.toObject();
    }
    if (this.props.point_of_interests instanceof Array) {
        props.point_of_interests = (< PointOfInterest[]>props.point_of_interests).map((item) => {
            return item instanceof PointOfInterest ? item.toObject() : item;
        });
    }
    if (props.image instanceof Image) {
        props.image = props.image.toObject();
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): MarkerPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as MarkerInterface);
    payload.plateau = payload.plateau ? this.extractId(payload.plateau) : null;
    payload.point_of_interests = payload.point_of_interests ? payload.point_of_interests.map(i => this.extractId(i)) : null;
    payload.image = payload.image ? this.extractId(payload.image) : null;
    return payload as MarkerPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): MarkerPayloadKey[] {
  	return [
      'label',
      'plateau',
      'point_of_interests',
      'image',
      'latitude',
      'longitude',
  	];
  }
}
