import { Helpers } from '@app/shared';
import {
	BaseModelSearchParams,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';

/** Used to export and import search params */
export interface RoleSearchParamsInterface
	extends BaseModelSearchParamsInterface {
	_sort?: '_id' | 'created_at' | 'name' | 'admin';
	_id?: string[];
	_text?: string;
	name?: string;
	admin?: 'true' | 'false' | boolean;
}

/** Manage role search params */
export class RoleSearchParams extends BaseModelSearchParams<
	RoleSearchParamsInterface
> {
	/** @inheritDoc */
	fromObject(input: Partial<RoleSearchParamsInterface>): void {
		// Clone object with allowed keys only
		const props = {} as RoleSearchParamsInterface;
		this.allowedKeys().map((key: string) => {
			if (typeof input[key] !== 'undefined') {
				props[key] = input[key];
			}
		});

		// Keep default values
		if (typeof props._page === 'undefined') {
			props._page = this.defaultPage;
		}
		if (typeof props._limit === 'undefined') {
			props._limit = this.defaultLimit;
		}
		if (typeof props._sort === 'undefined') {
			props._sort = this.defaultSort;
		}
		if (typeof props._order === 'undefined') {
			props._order = this.defaultOrder;
		}
		// Convert potentially multiple primary keys
		if (typeof props._id === 'string') {
			props._id = (<string>props._id).split(',').filter((s) => s.length);
		}

		// Convert boolean admin
		if (typeof props.admin !== 'undefined') {
			props.admin = Helpers.convertToBoolean(props.admin);
		}

		// Assign values
		this.props = props;
		this.next();
	}
	/** @inheritDoc */
	toObject(): RoleSearchParamsInterface {
		// Filter not allowed, undefined and null values
		const props = {} as RoleSearchParamsInterface;
		this.allowedKeys().map(Helpers.prepareObjectForApi(this.props, props));

		// Convert boolean admin
		if (typeof props.admin !== 'undefined') {
			props.admin = props.admin ? 'true' : 'false';
		}

		return props;
	}
	/** @inheritDoc */
	protected allowedKeys(): string[] {
		return super.allowedKeys().concat(['_id', '_text', 'name', 'admin']);
	}
}
