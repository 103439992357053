import { Component } from '@angular/core';
import { EntityListTopBarComponent } from '@app/abstracts';
import { RolePermissionSearchParams } from '../role-permission-search-params';

@Component({
	selector: 'hpf-role-permission-list-top-bar',
	templateUrl:
		'../../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html',
})
export class RolePermissionListTopBarComponent extends EntityListTopBarComponent<
	RolePermissionSearchParams
> {}
