import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { Route } from '../../route';
import { RouteService } from '../../route.service';
@Component({
	selector: 'hpf-route-edit',
	templateUrl: './route-edit.component.html',
})
export class RouteEditComponent implements OnInit, OnDestroy {
	/** The route */
	route = new Route();
	/** The route */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** Constructor */
	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private routeService: RouteService
	) {}
	/** Init */
	ngOnInit() {
		// Get the route after reading the id
		this.idSubscription = this.activatedRoute.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch route from API */
	private refresh() {
		this.routeService
			.get(this.id, { _populate: ['*'] })
			.then((route: Route) => {
				this.route.fromObject(route.toObject());
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the route is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the route is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/route')
			.catch((error) => this.errorService.handle(error));
	}
}
