
<!-- Owner -->
<nz-form-item>
    <nz-form-label>{{ 'text-translated_owner' | translate }}</nz-form-label>
    <hpf-user-select
        [(id)]="searchParams.props.owner"
        [nullable]="true"
        [emptyLabel]="'common_empty' | translate"
        [filterEnabled]="true"
        [placeholder]="'user_common_search-placeholder' | translate"
        (change)="onChange();">
    </hpf-user-select>
</nz-form-item>
<!-- /Owner -->
<!-- Name -->
<nz-form-item>
    <nz-form-label>{{ 'text-translated_name' | translate }}</nz-form-label>
    <input nz-input
           class="w-100"
           (keyup)="onChangeDebounced();"
           [placeholder]="'common_value-string' | translate"
           [(ngModel)]="searchParams.props.name">
</nz-form-item>
<!-- /Name -->
<!-- Lang -->
<nz-form-item>
    <nz-form-label>{{ 'text-translated_lang' | translate }}</nz-form-label>
    <hpf-language-select
        [(id)]="searchParams.props.lang"
        [nullable]="true"
        [emptyLabel]="'common_empty' | translate"
        [filterEnabled]="true"
        [placeholder]="'language_common_search-placeholder' | translate"
        (change)="onChange();">
    </hpf-language-select>
</nz-form-item>
<!-- /Lang -->
<button
	nz-button
	nzType="default"
	(click)="resetSearchParams()"
>
	{{ 'common_reset-filters' | translate }}
</button>
