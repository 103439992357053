import {Injectable} from '@angular/core';
import {Image, ImageInterface, ImagePayload} from './image';
import {ImageSearchParamsInterface} from './image-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class ImageService extends BaseModelService< Image, ImageInterface, ImagePayload, ImageSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'image';
  }
  /** @inheritDoc */
  protected new(object: ImageInterface): Image {
    return new Image(object);
  }
}
