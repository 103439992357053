import {Injectable} from '@angular/core';
import {AudioDescription, AudioDescriptionInterface, AudioDescriptionPayload} from './audio-description';
import {AudioDescriptionSearchParamsInterface} from './audio-description-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class AudioDescriptionService extends BaseModelService< AudioDescription, AudioDescriptionInterface, AudioDescriptionPayload, AudioDescriptionSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'audio-description';
  }
  /** @inheritDoc */
  protected new(object: AudioDescriptionInterface): AudioDescription {
    return new AudioDescription(object);
  }
}
