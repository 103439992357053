import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {Diaporama} from '../../diaporama';
import {DiaporamaService} from '../../diaporama.service';
@Component({
  selector: 'hpf-diaporama-edit',
  templateUrl: './diaporama-edit.component.html'
})
export class DiaporamaEditComponent implements OnInit, OnDestroy {
  /** The diaporama */
  diaporama = new Diaporama();
  /** The diaporama */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private diaporamaService: DiaporamaService) {}
  /** Init */
  ngOnInit() {
    // Get the diaporama after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch diaporama from API */
  private refresh() {
    this.diaporamaService.get(this.id, { _populate: ['*'] })
      .then((diaporama: Diaporama) => {
        this.diaporama.fromObject(diaporama.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the diaporama is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the diaporama is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/diaporama')
      .catch((error) => this.errorService.handle(error));
  }
}
