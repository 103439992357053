import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@app/services';
import { Route } from '../../route';

@Component({
	selector: 'hpf-route-create',
	templateUrl: './route-create.component.html',
})
export class RouteCreateComponent implements OnInit {
	/** The route */
	route = new Route();

	/** Constructor */
	constructor(private router: Router, private errorService: ErrorService) {}
	/** Init */
	ngOnInit() {}
	/** Called when the route is created */
	onCreate(route: Route): void {
		this.router
			.navigateByUrl(`/route/${route.getId()}`)
			.catch((error) => this.errorService.handle(error));
	}
}
