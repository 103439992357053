import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {AudioDescriptionSearchParams} from '../audio-description-search-params';

@Component({
  selector: 'hpf-audio-description-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class AudioDescriptionListTopBarComponent extends EntityListTopBarComponent< AudioDescriptionSearchParams> {
}
 