import {Injectable} from '@angular/core';
import {PointOfInterest, PointOfInterestInterface, PointOfInterestPayload} from './point-of-interest';
import {PointOfInterestSearchParamsInterface} from './point-of-interest-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class PointOfInterestService extends BaseModelService< PointOfInterest, PointOfInterestInterface, PointOfInterestPayload, PointOfInterestSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'point-of-interest';
  }
  /** @inheritDoc */
  protected new(object: PointOfInterestInterface): PointOfInterest {
    return new PointOfInterest(object);
  }
}
