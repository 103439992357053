import { Component, Injectable, OnInit } from '@angular/core';
import { routerTransition } from '@app/core';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.less'],
	animations: [routerTransition],
})
@Injectable()
export class HomeComponent implements OnInit {
	/** Constructor */
	constructor() {}

	/** Init */
	ngOnInit() {}
}
