<nz-upload
	nzAction=""
	[nzAccept]="acceptedFileTypes"
	[nzSize]="sizeLimit"
	[nzMultiple]="multiple"
	[nzLimit]="multipleLimit"
	nzListType="picture-card"
	[nzCustomRequest]="customUpload"
	[nzHeaders]="{ authorization: 'authorization-text' }"
	[(nzFileList)]="fileList"
	[nzShowButton]="fileList.length < multipleLimit"
	[nzShowUploadList]="uploadListSettings"
	[nzRemove]="cleanMediaUri"
>
	<i nz-icon nzType="plus"></i>
	<div class="ant-upload-text">
		{{ placeholder ? placeholder : ('common-upload' | translate) }}
	</div>
</nz-upload>
