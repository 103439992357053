import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {CategorySearchParams} from '../category-search-params';

@Component({
  selector: 'hpf-category-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class CategoryListTopBarComponent extends EntityListTopBarComponent< CategorySearchParams> {
}
 