import {Injectable} from '@angular/core';
import {Credit, CreditInterface, CreditPayload} from './credit';
import {CreditSearchParamsInterface} from './credit-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class CreditService extends BaseModelService< Credit, CreditInterface, CreditPayload, CreditSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'credit';
  }
  /** @inheritDoc */
  protected new(object: CreditInterface): Credit {
    return new Credit(object);
  }
}
