import { Component } from '@angular/core';
import { EntityListTopBarComponent } from '@app/abstracts';
import { RoleSearchParams } from '../role-search-params';

@Component({
	selector: 'hpf-role-list-top-bar',
	templateUrl:
		'../../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html',
})
export class RoleListTopBarComponent extends EntityListTopBarComponent<
	RoleSearchParams
> {}
