import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Video, VideoInterface } from '../video/video';
import {
	TextTranslated,
	TextTranslatedInterface,
} from '../text-translated/text-translated';
import { Audio, AudioInterface } from '../audio/audio';
import { environment } from '@env/environment';
export interface DiaporamaInterface extends BaseModelInterface {
	created_at: number | Date;
	is360: boolean;
	is_video: boolean;
	video?: string | Video | VideoInterface;
	name: string;
	subtitle?: (string | TextTranslated | TextTranslatedInterface)[];
	audios: (string | Audio | AudioInterface)[];
	slides?: any;
}
export interface DiaporamaPayload {
	is360: boolean;
	is_video: boolean;
	video?: string | Video | VideoInterface;
	name: string;
	subtitle?: (string | TextTranslated | TextTranslatedInterface)[];
	audios: (string | Audio | AudioInterface)[];
	slides?: any;
}
type DiaporamaPayloadKey = keyof DiaporamaPayload;
export class Diaporama extends BaseModel<DiaporamaInterface, DiaporamaPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of video has been populated */
	videoExists(): boolean {
		return (
			!!this.props &&
			this.props.video instanceof Video &&
			this.props.video.exists()
		);
	}
	/** Denotes if the instance of subtitle has been populated */
	subtitleExists(): boolean {
		return (
			!!this.props &&
			this.props.subtitle instanceof Array &&
			(<TextTranslated[]>this.props.subtitle).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}

	getFirstImgPath() {
		if (this.props.slides != null) {
			let slide = this.props.slides[0];
			return `${environment.api.uri}/image/view/${slide.image}`;
		}
	}
	/** Denotes if the instance of audios has been populated */
	audiosExists(): boolean {
		return (
			!!this.props &&
			this.props.audios instanceof Array &&
			(<Audio[]>this.props.audios).every((item) => {
				return item instanceof Audio && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: DiaporamaInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.video !== null && typeof this.props.video === 'object') {
			this.props.video = new Video(<VideoInterface>this.props.video);
		}
		if (this.props.subtitle instanceof Array) {
			this.props.subtitle = (<TextTranslatedInterface[]>(
				this.props.subtitle
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (this.props.audios instanceof Array) {
			this.props.audios = (<AudioInterface[]>this.props.audios).map(
				(item) => {
					return typeof item === 'object' ? new Audio(item) : item;
				}
			);
		}
		this.next();
	}

	/** Convert the current instance to an object */
	toObject(): DiaporamaInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.video === 'undefined') {
			props.video = null;
		}
		if (typeof props.subtitle === 'undefined') {
			props.subtitle = [];
		}
		if (
			typeof props.slides === 'undefined' ||
			!(props.slides instanceof Array)
		) {
			props.slides = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.video !== null && props.video instanceof Video) {
			props.video = props.video.toObject();
		}
		if (this.props.subtitle instanceof Array) {
			props.subtitle = (<TextTranslated[]>props.subtitle).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		if (this.props.audios instanceof Array) {
			props.audios = (<Audio[]>props.audios).map((item) => {
				return item instanceof Audio ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): DiaporamaPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as DiaporamaInterface);
		payload.video = payload.video ? this.extractId(payload.video) : null;
		payload.subtitle = payload.subtitle
			? payload.subtitle.map((i) => this.extractId(i))
			: null;
		payload.audios = payload.audios
			? payload.audios.map((i) => this.extractId(i))
			: null;
		return payload as DiaporamaPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): DiaporamaPayloadKey[] {
		return [
			'is360',
			'is_video',
			'video',
			'name',
			'subtitle',
			'audios',
			'slides',
		];
	}
}
