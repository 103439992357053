import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared';
import { TranslateModuleLoad } from '@app/translate-import';

import { SessionRoutingModule } from './session-routing.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { SessionComponent } from './session/session.component';
import { AtomLostPasswordComponent } from './atom-lost-password/atom-lost-password.component';
import { AtomResetPasswordComponent } from './atom-reset-password/atom-reset-password.component';
import { MoleculeLostPasswordComponent } from './molecule-lost-password/molecule-lost-password.component';
import { MoleculePasswordResetComponent } from './molecule-password-reset/molecule-password-reset.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		SessionRoutingModule,
		TranslateModuleLoad(),
	],
	declarations: [
		SignInComponent,
		SessionComponent,
		AtomLostPasswordComponent,
		AtomResetPasswordComponent,
		MoleculeLostPasswordComponent,
		MoleculePasswordResetComponent,
	],
	providers: [],
})
export class SessionModule {}
