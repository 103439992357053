import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-molecule-password-reset',
	templateUrl: './molecule-password-reset.component.html',
	styleUrls: ['./molecule-password-reset.component.less'],
})
export class MoleculePasswordResetComponent implements OnInit, OnDestroy {
	routeSubscription: Subscription;
	params: {
		id?: string;
		code?: string;
	} = {};

	/**
	 *
	 * @param {ActivatedRoute} _route
	 */
	constructor(private _route: ActivatedRoute) {}

	ngOnInit() {
		this.routeSubscription = this._route.params.subscribe((params) => {
			// Check params
			const { id, code } = params;
			if (typeof id === 'undefined' || typeof code === 'undefined') {
				throw new Error('Cannot find params from route');
			}
			// Bind params
			this.params.id = id;
			this.params.code = code;
		});
	}

	ngOnDestroy() {
		this.routeSubscription.unsubscribe();
	}
}
