import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Audio, Video, Image } from '@app/models';
import { environment } from '@env/environment';

@Component({
	selector: 'app-atom-media-player',
	templateUrl: './atom-media-player.component.html',
	styleUrls: ['./atom-media-player.component.less'],
})
export class AtomMediaPlayerComponent {
	@Input() media: Audio | Video | Image;

	constructor(private sanitizer: DomSanitizer) {}

	getSourceUrl(): string {
		return this.media.getUri();
	}

	getMediaType(): string {
		return this.media.constructor.name.toLowerCase();
	}

	bypassUrl(url: string) {
		console.log('url', url);
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
