import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleComponent } from './role/role/role.component';
import { RoleCreateComponent } from './role/views/role-create/role-create.component';
import { RoleEditComponent } from './role/views/role-edit/role-edit.component';
import { RoleListComponent } from './role/views/role-list/role-list.component';
import { RolePermissionComponent } from './role-permission/role-permission/role-permission.component';
import { RolePermissionCreateComponent } from './role-permission/views/role-permission-create/role-permission-create.component';
import { RolePermissionEditComponent } from './role-permission/views/role-permission-edit/role-permission-edit.component';
import { RolePermissionListComponent } from './role-permission/views/role-permission-list/role-permission-list.component';
import { CheckPermissionGuard } from '@app/guards';

const routes: Routes = [
	{
		path: 'role',
		component: RoleComponent,
		children: [
			{
				path: '',
				component: RoleListComponent,
				data: { title: 'List role', model: 'role', action: 'search' },
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: RoleCreateComponent,
				data: { title: 'Create role', model: 'role', action: 'create' },
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: RoleEditComponent,
				data: { title: 'Edit role', model: 'role', action: 'read' },
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'role-permission',
		component: RolePermissionComponent,
		children: [
			{
				path: '',
				component: RolePermissionListComponent,
				data: {
					title: 'List role permission',
					model: 'role_permission',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: RolePermissionCreateComponent,
				data: {
					title: 'Create role permission',
					model: 'role_permission',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: RolePermissionEditComponent,
				data: {
					title: 'Edit role permission',
					model: 'role_permission',
					action: 'read',
				},
				canActivate: [CheckPermissionGuard],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class RolePermissionRoutingModule {}
