import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { ActivityLog } from '../../activity-log';
import { ActivityLogService } from '../../activity-log.service';
import { ActivityLogSearchParams } from '../../activity-log-search-params';
@Component({
	selector: 'hpf-activity-log-edit',
	templateUrl: './activity-log-edit.component.html',
})
export class ActivityLogEditComponent implements OnInit, OnDestroy {
	/** The activity log */
	activityLog = new ActivityLog();
	/** The activity log */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** The filter for activity log as previous */
	activityLogAsPreviousSearchParams = new ActivityLogSearchParams();
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private activityLogService: ActivityLogService
	) {}
	/** Init */
	ngOnInit() {
		// Get the activity log after reading the id
		this.idSubscription = this.route.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
			// Filter by previous for activity log
			this.activityLogAsPreviousSearchParams.fromObject({
				previous: this.id,
			});
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch activity log from API */
	private refresh() {
		this.activityLogService
			.get(this.id, { _populate: ['*'] })
			.then((activityLog: ActivityLog) => {
				this.activityLog.fromObject(activityLog.toObject());
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the activity log is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the activity log is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/activity-log')
			.catch((error) => this.errorService.handle(error));
	}
	/** Called on when user select a activity log as previous */
	onSelectActivityLogAsPrevious(item: ActivityLog): void {
		this.router.navigateByUrl(`/activity-log/${item.getId()}`);
	}
}
