import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { Category } from '../../category';
import { CategoryService } from '../../category.service';
import { PointOfInterestSearchParams } from '../../../point-of-interest/point-of-interest-search-params';
import { PointOfInterest } from '../../../point-of-interest/point-of-interest';
import { ActivityLogSearchParams } from '@app/plugins/activity';
@Component({
	selector: 'hpf-category-edit',
	templateUrl: './category-edit.component.html'
})
export class CategoryEditComponent implements OnInit, OnDestroy {
	/** The category */
	category = new Category();
	/**	History search params */
	historySearchParams: ActivityLogSearchParams;
	/** The category */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** The filter for point of interest as categories */
	pointOfInterestAsCategoriesSearchParams = new PointOfInterestSearchParams();
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private categoryService: CategoryService
	) {}
	/** Init */
	ngOnInit() {
		// Get the category after reading the id
		this.idSubscription = this.route.params.subscribe(params => {
			this.id = params['id'];
			this.refresh();
			// Filter by categories for point of interest
			this.pointOfInterestAsCategoriesSearchParams.fromObject({
				categories: this.id
			});
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch category from API */
	private refresh() {
		this.categoryService
			.get(this.id, { _populate: ['*'] })
			.then((category: Category) => {
				this.category.fromObject(category.toObject());

				// Refresh history
				const params = {
					entity_id: this.id,
					entity_type: 'category',
				};
				if (!this.historySearchParams) {
					this.historySearchParams = new ActivityLogSearchParams(
						params
					);
				} else {
					this.historySearchParams.fromObject(params);
				}
			})
			.catch(error => this.errorService.handle(error));
	}
	/** Called when the category is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the category is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/category')
			.catch(error => this.errorService.handle(error));
	}
	/** Called on when user select a point of interest as categories */
	onSelectPointOfInterestAsCategories(item: PointOfInterest): void {
		this.router.navigateByUrl(`/point-of-interest/${item.getId()}`);
	}
}
