import { Component, EventEmitter, Output } from '@angular/core';
import { EntityListTopBarComponent } from '@app/abstracts';
import { MarkerSearchParams } from '../marker-search-params';

@Component({
	selector: 'hpf-marker-list-top-bar',
	templateUrl: './marker-list-top-bar.component.html',
})
export class MarkerListTopBarComponent extends EntityListTopBarComponent<MarkerSearchParams> {
	@Output() createBulkEvent: EventEmitter<void> = new EventEmitter<void>();
}
