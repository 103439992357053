import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';

import { Image } from '@app/models/image';

import { ImageModalService } from '../image-modal/image-modal.service';
@Component({
	selector: 'app-image-modal-button',
	templateUrl: './image-modal-button.component.html',
	styleUrls: ['./image-modal-button.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: ImageModalButtonComponent,
		},
	],
})
export class ImageModalButtonComponent implements ControlValueAccessor {
	@Output() imageSelectedEvent = new EventEmitter<Image>();
	onChange = (t) => {};
	onTouched = () => {};
	@Input() value: Image;
	disabled = false;
	touched = false;
	constructor(
		private imageModalService: ImageModalService,
		private router: Router
	) {}

	writeValue(obj: Image): void {
		this.value = obj;
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
	public open() {
		//this.imageModal.open(this);
		this.imageModalService.create.next(this);
	}
	public openEdit(img) {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`image/${img.getId()}`])
		);
		window.open(url, '_blank');
	}
	public imageSelected(img) {
		this.value = img;
		this.onChange(img);
		this.imageSelectedEvent.emit(img);
	}
}
