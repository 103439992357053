import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import { Audio, AudioInterface } from '../audio/audio';
import { environment } from '@env/environment';
import {
	TextTranslated,
	TextTranslatedInterface,
} from '../text-translated/text-translated';
export interface VideoInterface extends BaseModelInterface {
	created_at: number | Date;
	owner: string | User | UserInterface;
	name: string;
	uri: string;
	copyright?: (string | TextTranslated | TextTranslatedInterface)[];
	fileUpload: {};
	ext: string;
}
export interface VideoPayload {
	name: string;
	uri: string;
	copyright?: (string | TextTranslated | TextTranslatedInterface)[];
	fileUpload: {};
}
type VideoPayloadKey = keyof VideoPayload;
export class Video extends BaseModel<VideoInterface, VideoPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of owner has been populated */
	ownerExists(): boolean {
		return (
			!!this.props &&
			this.props.owner instanceof User &&
			this.props.owner.exists()
		);
	}
	getUri(): string {
		return `${environment.api.uri}/video/${this.props._id}.${this.props.ext}`;
	}
	getMediaType(): string {
		return 'video';
	}

	/** Denotes if the instance of copyright has been populated */
	copyrightExists(): boolean {
		return (
			!!this.props &&
			this.props.copyright instanceof Array &&
			(<TextTranslated[]>this.props.copyright).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: VideoInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.owner === 'object') {
			this.props.owner = new User(<UserInterface>this.props.owner);
		}

		if (this.props.copyright instanceof Array) {
			this.props.copyright = (<TextTranslatedInterface[]>(
				this.props.copyright
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): VideoInterface {
		const props = Object.assign({}, this.props);

		if (typeof props.copyright === 'undefined') {
			props.copyright = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.owner instanceof User) {
			props.owner = props.owner.toObject();
		}

		if (this.props.copyright instanceof Array) {
			props.copyright = (<TextTranslated[]>props.copyright).map(
				(item) => {
					return item instanceof TextTranslated
						? item.toObject()
						: item;
				}
			);
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): VideoPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as VideoInterface);

		payload.copyright = payload.copyright
			? payload.copyright.map((i) => this.extractId(i))
			: null;
		return payload as VideoPayload;
	}

	toFormData(): FormData {
		var payload = this.toPayload();
		const formData = new FormData();

		for (const key in payload) {
			console.log(payload[key]);
			formData.append(key, payload[key]);
		}
		console.log(formData);
		return formData;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): VideoPayloadKey[] {
		return ['name', 'copyright', 'fileUpload'];
	}
}
