<nz-card class="round-border my-5">


  <div class="row">

    <div class="col-3">
      <label class="btn btn-default">
        <input type="file" (change)="selectFile($event)" />
      </label>
    </div>
    <div class="col-3 form-group">

      <label>{{'image_common_name'|translate}} </label>
      <select class="form-control" (change)="imageChanged($event.target.value)">
        <option value="-1">
        </option>
        <option *ngFor="let img of images;let i =index" value="{{i}}">
          {{img.props.name}}</option>
      </select>

      <img *ngIf="this.selectedImage!=null" [src]="this.selectedImage.getUri()" width="100%">
    </div>
    <div class="col-3 form-group">

      <label>{{'plateau_common_name'|translate}}</label>
      <select class="form-control" (change)="plateauChanged($event.target.value)">
        <option value="-1">
        </option>
        <option *ngFor="let plateau of plateaux;let i =index" value="{{i}}">
          {{plateau.props.name}}</option>
      </select>
    </div>
    <div class="col-3 form-group">

      <label>{{'common_separator'}}</label>
      <input class="form-control" [(ngModel)]="this.separator">
    </div>
  </div>


  <hr>
  <div>
    <div class="row">
      <div class="col-3">
        <b> {{"marker_name" | translate}}</b>
      </div>
      <div class="col-3">
        <b> {{"point-of-interest_latitude" | translate}}</b>
      </div>
      <div class="col-3">
        <b> {{"point-of-interest_longitude" | translate}}</b>
      </div>

    </div>
    <hr>
    <div *ngFor="let m of markers" class="row">
      <div class="col-3">
        <input class="form-control" [(ngModel)]="m.label">
      </div>
      <div class=" col-3">
        <input class="form-control" [(ngModel)]="m.latitude">
      </div>
      <div class="col-3">
        <input class="form-control" [(ngModel)]="m.longitude">
      </div>
      <div class="col-3">
        <button (click)="remove(m)" nz-button nzType="default" nzShape="circle"
          [title]="'playlist-table_delete-button' | translate" class="ml-3"> <i nz-icon nzType="delete"></i></button>
      </div>
    </div>
  </div>
  <hr>
  <button [disabled]="valid()" class="ant-btn ant-btn-primary ng-star-inserted" (click)="upload()">upload</button>
</nz-card>
