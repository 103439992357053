import {Injectable} from '@angular/core';
import {Language, LanguageInterface, LanguagePayload} from './language';
import {LanguageSearchParamsInterface} from './language-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class LanguageService extends BaseModelService< Language, LanguageInterface, LanguagePayload, LanguageSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'language';
  }
  /** @inheritDoc */
  protected new(object: LanguageInterface): Language {
    return new Language(object);
  }
}
