import { Component } from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import { ErrorService } from '@app/services';
import { AudioSearchParams } from '../audio-search-params';
import { AudioService } from '../audio.service';
import { Audio } from '../audio';
import { QuickFormService } from '../../../plugins/quick-form';
import { environment } from '@env/environment';
@Component({
	selector: 'hpf-audio-select',
	templateUrl: './audio-select.component.html',
})
export class AudioSelectComponent extends EntitySelectComponent<Audio> {
	protected modelName = 'audio';
	/**
	 * Constructor
	 * @param {AudioService} audioService
	 * @param {ErrorService} errorService
	 */
	constructor(
		private audioService: AudioService,
		protected errorService: ErrorService,
		protected quickFormService: QuickFormService
	) {
		super(errorService, quickFormService);
	}
	ngOnInit() {
		super.ngOnInit();
		// Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if (!(model instanceof Audio)) return;
					if (this.multiple) {
						if (!this.model) this.model = [];
						(<Audio[]>this.model).push(<Audio>model);
					} else {
						this.model = <Audio>model;
					}
					this.refresh();
				}
			)
		);
	}

	public getEditUrl(): string {
		return `/image/${this.idValue}`;
	}
	public getUrl(): string {
		return `${environment.api.uri}/audio/view/${this.idValue}`;
	}
	public async getAudio() {
		return await this.getOne(this.idValue.toString());
	}
	public hasSelected(): boolean {
		return this.modelValue != null;
	}
	/** @inheritDoc */
	protected async getList(): Promise<Audio[]> {
		const params = new AudioSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		return (await this.audioService.list(params.toObject())).items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<Audio> {
		return await this.audioService.get(id);
	}
}
