import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'hpf-activity-log',
	templateUrl: './activity-log.component.html',
})
export class ActivityLogComponent implements OnInit {
	/** Constructor */
	constructor() {}

	/** Init */
	ngOnInit() {}
}
