<nz-modal [(nzVisible)]="deleteModal" (nzOnCancel)="deleteModal = false" (nzOnOk)="onDelete()"
  [nzTitle]="'common_deletion' | translate" [nzContent]="'common_delete-one' | translate" [nzOkLoading]="deleting">
</nz-modal>
<form nz-form class="category-form w-100" *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()"
  [nzAutoTips]="explainErrorsService.autoTips">
  <nz-affix>
    <div class="py-4 border-bottom bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <!-- Title -->
            <div>
              <h1 class="mb-0">
                <span *ngIf="category.isNew()">{{ 'category_create-form-title' | translate }}</span>
                <span *ngIf="!category.isNew()">{{ category.getLabel() }}</span>
              </h1>
              <small *ngIf="!category.isNew()"
                class="text-black-50">{{ 'common_id' | translate: { id : category.getId() } }}</small>
            </div>
            <!-- /Title -->

            <!-- Actions -->
            <div>
              <button nz-button [disabled]="form.invalid" [nzLoading]="saving" nzType="primary" *appRestrictedByScope="{
                                    model: 'category',
                                    action: 'update'
                                }">
                {{ 'common_save' | translate }}
              </button>
              <ng-container *appRestrictedByScope="{
                                model: 'category',
                                action: 'delete'
                            }">
                <button *ngIf="!category.isNew() && deletable" class="ml-4" nz-button nzShape="circle"
                  [title]="'common_delete' | translate" [nzLoading]="deleting" (click)="deleteModal = true"
                  type="button">
                  <i nz-icon nzType="delete"></i>
                </button>
              </ng-container>
            </div>
            <!-- /Actions -->
          </div>
        </div>
      </div>
    </div>
  </nz-affix>
  <div class="container">
    <nz-card class="round-border my-5">
      <div *ngIf="loading" class="row">
        <div class="col-12">
          <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
      </div>
      <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">
          <div class="col-12 col-md-6">
            <!-- Name -->


            <nz-form-item>
              <h4>{{ 'category_name' | translate }}</h4>
              <nz-form-control>


                <input nz-input class="w-100" type="text" [placeholder]="'common_value-string' | translate"
                  formControlName="name">
              </nz-form-control>
            </nz-form-item>
            <!-- /Name -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Image -->


            <nz-form-item>
              <h4>{{ 'category_image' | translate }}</h4>
              <nz-form-control [nzValidateStatus]="form.get('image')">

                <app-image-modal-button formControlName="image"></app-image-modal-button>

              </nz-form-control>
            </nz-form-item>
            <!-- /Image -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Title -->

            <h4>{{ 'category_title' | translate }}</h4>
            <app-language-tab [name]="'title'" [model]="category"></app-language-tab>
          </div>

          <div class="col-12 col-md-6">
            <!-- Order -->


            <nz-form-item>
              <h4>{{ 'category_order' | translate }}</h4>
              <nz-form-control>


                <nz-input-number class="w-100" formControlName="order" [nzStep]="1"
                  [nzPlaceHolder]="'common_value-number' | translate"></nz-input-number>
              </nz-form-control>
            </nz-form-item>
            <!-- /Order -->
          </div>
        </div>

        <nz-divider *ngIf="!category.isNew() && !readOnly"></nz-divider>
        <div *ngIf="!category.isNew()" class="row">
          <div class="col-12 col-md-6">
            <!-- _Id -->
            <div class="mb-4">
              <h4>{{ 'category_-id' | translate }}</h4>
              <div>{{ category.props._id }}</div>
            </div>
            <!-- /_Id -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Created At -->
            <div class="mb-4">
              <h4>{{ 'category_created-at' | translate }}</h4>
              <div>{{ category.props.created_at | date:'medium' }}</div>
            </div>
            <!-- /Created At -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Name -->
            <div class="mb-4">
              <h4>{{ 'category_name' | translate }}</h4>
              <div>{{ category.props.name }}</div>
            </div>
            <!-- /Name -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Image -->
            <div class="mb-4">
              <h4>{{ 'category_image' | translate }}</h4>
              <div *ngIf="category.imageExists()">
                <a [routerLink]="['/image', category.props.image.getId()]">
                  {{ category.props.image.getLabel() }}
                </a>
              </div>
            </div>
            <!-- /Image -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Title -->
            <div class="mb-4">
              <h4>{{ 'category_title' | translate }}</h4>
              <div *ngIf="category.titleExists()">
                <div *ngFor="let item of category.props.title">
                  <a class="mr-2" [routerLink]="['/text-translated', item.getId()]">
                    {{ item.getLabel() }}
                  </a>
                </div>
              </div>
            </div>
            <!-- /Title -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Order -->
            <div class="mb-4">
              <h4>{{ 'category_order' | translate }}</h4>
              <div>{{ category.props.order }}</div>
            </div>
            <!-- /Order -->
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</form>
