import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseModelInterface, BaseModel } from '@app/abstracts';
import { ImageModalButtonComponent } from '../image-modal-button/image-modal-button.component';

@Injectable()
export class ImageModalService {
	create = new Subject<ImageModalButtonComponent>();

	constructor() {}
}
