import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@app/services';
import { ActivityLog } from '../../activity-log';

@Component({
	selector: 'hpf-activity-log-create',
	templateUrl: './activity-log-create.component.html',
})
export class ActivityLogCreateComponent implements OnInit {
	/** The activity log */
	activityLog = new ActivityLog();

	/** Constructor */
	constructor(private router: Router, private errorService: ErrorService) {}
	/** Init */
	ngOnInit() {}
	/** Called when the activity log is created */
	onCreate(activityLog: ActivityLog): void {
		this.router
			.navigateByUrl(`/activity-log/${activityLog.getId()}`)
			.catch((error) => this.errorService.handle(error));
	}
}
