import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {TextTranslatedSearchParams} from '../text-translated-search-params';
import {TextTranslatedService} from '../text-translated.service';
import {TextTranslated} from '../text-translated';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-text-translated-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class TextTranslatedSelectComponent extends EntitySelectComponent< TextTranslated> {
  protected modelName = 'text_translated';
  /**
   * Constructor
   * @param {TextTranslatedService} textTranslatedService
   * @param {ErrorService} errorService
   */
  constructor(private textTranslatedService: TextTranslatedService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof TextTranslated)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<TextTranslated[]>this.model).push(<TextTranslated>model);
          } else {
            this.model = <TextTranslated>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< TextTranslated[]> {
      const params = new TextTranslatedSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.textTranslatedService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< TextTranslated> {
      return await this.textTranslatedService.get(id);
  }
}
