import {Injectable} from '@angular/core';
import {Diaporama, DiaporamaInterface, DiaporamaPayload} from './diaporama';
import {DiaporamaSearchParamsInterface} from './diaporama-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class DiaporamaService extends BaseModelService< Diaporama, DiaporamaInterface, DiaporamaPayload, DiaporamaSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'diaporama';
  }
  /** @inheritDoc */
  protected new(object: DiaporamaInterface): Diaporama {
    return new Diaporama(object);
  }
}
