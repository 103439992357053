import { Injectable } from '@angular/core';
import {
	RolePermission,
	RolePermissionInterface,
	RolePermissionPayload,
} from './role-permission';
import { RolePermissionSearchParamsInterface } from './role-permission-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class RolePermissionService extends BaseModelService<
	RolePermission,
	RolePermissionInterface,
	RolePermissionPayload,
	RolePermissionSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'role-permission';
	}
	/** @inheritDoc */
	protected new(object: RolePermissionInterface): RolePermission {
		return new RolePermission(object);
	}
}
