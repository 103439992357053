<!-- Owner -->
<nz-form-item>
	<nz-form-label>{{ 'route_owner' | translate }}</nz-form-label>
	<hpf-user-select
		[(id)]="searchParams.props.owner"
		[nullable]="true"
		[emptyLabel]="'common_empty' | translate"
		[filterEnabled]="true"
		[placeholder]="'user_common_search-placeholder' | translate"
		(change)="onChange()"
		[quickFormEnabled]="false"
	>
	</hpf-user-select>
</nz-form-item>
<!-- /Owner -->
<!-- Name -->
<nz-form-item>
	<nz-form-label>{{ 'route_name' | translate }}</nz-form-label>
	<input
		nz-input
		class="w-100"
		(keyup)="onChangeDebounced()"
		[placeholder]="'common_value-string' | translate"
		[(ngModel)]="searchParams.props.name"
	/>
</nz-form-item>
<!-- /Name -->
<!-- Published -->
<nz-form-item>
	<nz-form-label>{{ 'route_published' | translate }}</nz-form-label>
	<nz-select
		class="w-100"
		[nzPlaceHolder]="'common_value-boolean' | translate"
		[(ngModel)]="searchParams.props.published"
		(ngModelChange)="onChange()"
		[nzAllowClear]="true"
	>
		<nz-option
			[nzValue]="true"
			[nzLabel]="'common_true' | translate"
		></nz-option>
		<nz-option
			[nzValue]="false"
			[nzLabel]="'common_false' | translate"
		></nz-option>
	</nz-select>
</nz-form-item>
<!-- /Published -->
<button nz-button nzType="default" (click)="resetSearchParams()">
	{{ 'common_reset-filters' | translate }}
</button>
