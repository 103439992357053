import {Injectable} from '@angular/core';
import {Audio, AudioInterface, AudioPayload} from './audio';
import {AudioSearchParamsInterface} from './audio-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class AudioService extends BaseModelService< Audio, AudioInterface, AudioPayload, AudioSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'audio';
  }
  /** @inheritDoc */
  protected new(object: AudioInterface): Audio {
    return new Audio(object);
  }
}
