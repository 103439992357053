import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable()
export class AssetBundler {
	constructor(private http: HttpClient) {}
	createBundle(id): Promise<void> {
		const options = {
			withCredentials: true,
		};

		return this.http
			.get<void>(
				`${environment.api.uri}${environment.api.adminPath}/assetbundle/` +
					id,
				options
			)
			.toPromise();
	}
	isRunning(): Promise<boolean> {
		const options = {
			withCredentials: true,
		};
		return this.http
			.get<boolean>(
				`${environment.api.uri}/assetbundle/isrunning`,
				options
			)
			.toPromise();
	}
}
