import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@app/services';
import { Image } from '../../image';

@Component({
	templateUrl: './image-create-bulk.component.html',
})
export class ImageCreateBulkComponent implements OnInit {
	/** Constructor */
	constructor(private router: Router, private errorService: ErrorService) {}
	/** Init */
	ngOnInit() {}
}
