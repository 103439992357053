import { Component } from '@angular/core';
import { EntitySelectComponent } from '@app/abstracts';
import { ErrorService } from '@app/services';
import { RoleSearchParams } from '../role-search-params';
import { RoleService } from '../role.service';
import { Role } from '../role';
import { QuickFormService } from '@app/plugins/quick-form';
@Component({
	selector: 'hpf-role-select',
	templateUrl:
		'../../../../abstracts/entity-select/entity-select.component.html',
})
export class RoleSelectComponent extends EntitySelectComponent<Role> {
	/**
	 * Constructor
	 * @param {RoleService} roleService
	 * @param {ErrorService} errorService
	 */
	constructor(
		private roleService: RoleService,
		protected errorService: ErrorService,
		protected quickFormService: QuickFormService
	) {
		super(errorService, quickFormService);
	}
	/** @inheritDoc */
	protected async getList(): Promise<Role[]> {
		const params = new RoleSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		return (await this.roleService.list(params.toObject())).items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<Role> {
		return await this.roleService.get(id);
	}
}
