import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ErrorService } from '@app/services';
import { RoleSearchParams } from '../role-search-params';
import { RoleService } from '../role.service';
@Component({
	selector: 'hpf-role-count',
	templateUrl: './role-count.component.html',
})
export class RoleCountComponent implements OnInit {
	/** The filters */
	@Input() searchParams: RoleSearchParams = new RoleSearchParams();
	/** Count result */
	count: number;
	/** Load flag */
	loaded = false;
	/** Constructor */
	constructor(
		private roleService: RoleService,
		private errorService: ErrorService
	) {}
	/** Init */
	ngOnInit() {
		this.roleService
			.count(this.searchParams.toObject())
			.then((count) => {
				this.count = count;
				this.loaded = true;
			})
			.catch((error) => this.errorService.handle(error));
	}
}
