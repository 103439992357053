import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {PlateauSearchParams} from '../plateau-search-params';

@Component({
  selector: 'hpf-plateau-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class PlateauListTopBarComponent extends EntityListTopBarComponent< PlateauSearchParams> {
}
 