import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AntComponentsModule } from './ant-components.module';

import { RestrictedToRolesDirective } from '@app/directives/restricted-to-roles.directive';
import { SafeHtmlPipe } from '@app/pipes/safe-html.pipe';
import { TranslateEntryPipe } from '@app/pipes/translate-entry.pipe';
import { TranslateCutPipe } from '@app/pipes/translate-cut.pipe';
import { MinPipe } from '@app/pipes/min.pipe';
import { MaxPipe } from '@app/pipes/max.pipe';
import { StripHtmlPipe } from '@app/pipes/strip-html.pipe';
import { TranslateModuleLoad } from '@app/translate-import';

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { RestrictedByScopeDirective } from '@app/directives/restricted-by-scope.directive';
import { NZ_I18N, en_US } from 'ng-zorro-antd';
registerLocaleData(en);
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AtomUploadComponent } from './atom-upload/atom-upload.component';
import { AtomMediaPlayerComponent } from './atom-media-player/atom-media-player.component';
import { ColorPickerComponent, ColorPickerModule } from 'ngx-color-picker';
import { AssetBundler } from './assetbundler';
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule.withConfig({
			warnOnNgModelWithFormControl: 'never',
		}),
		RouterModule,
		Nl2BrPipeModule,
		EditorModule,
		TranslateModuleLoad(),
		AntComponentsModule,
		LeafletModule,
		LeafletDrawModule,
		DragDropModule,
		ColorPickerModule,
	],
	declarations: [
		SafeHtmlPipe,
		TranslateEntryPipe,
		TranslateCutPipe,
		MinPipe,
		MaxPipe,
		StripHtmlPipe,
		RestrictedToRolesDirective,
		RestrictedByScopeDirective,
		AtomUploadComponent,
		AtomMediaPlayerComponent,
	],
	providers: [{ provide: NZ_I18N, useValue: en_US }, AssetBundler],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		Nl2BrPipeModule,
		EditorModule,
		SafeHtmlPipe,
		TranslateEntryPipe,
		TranslateCutPipe,
		RestrictedByScopeDirective,
		MinPipe,
		MaxPipe,
		StripHtmlPipe,
		RestrictedToRolesDirective,
		AntComponentsModule,
		LeafletModule,
		LeafletDrawModule,
		DragDropModule,
		AtomUploadComponent,
		AtomMediaPlayerComponent,
		ColorPickerModule,
	],
})
export class SharedModule {}
