<nz-modal [(nzVisible)]="deleteModal" (nzOnCancel)="deleteModal = false" (nzOnOk)="onDelete()"
  [nzTitle]="'common_deletion' | translate" [nzContent]="'common_delete-one' | translate" [nzOkLoading]="deleting">
</nz-modal>
<form nz-form class="route-form w-100" *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()"
  [nzAutoTips]="explainErrorsService.autoTips">
  <nz-affix>
    <div class="py-4 border-bottom bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <!-- Title -->
            <div>
              <h1 class="mb-0">
                <span *ngIf="route.isNew()">{{
									'route_create-form-title' | translate
								}}</span>
                <span *ngIf="!route.isNew()">{{
									route.getLabel()
								}}</span>
              </h1>
              <small *ngIf="!route.isNew()" class="text-black-50">{{
									'common_id'
										| translate: { id: route.getId() }
								}}</small>
            </div>
            <!-- /Title -->

            <!-- Actions -->
            <div>
              <button nz-button [disabled]="form.invalid" [nzLoading]="saving" nzType="primary" *appRestrictedByScope="{
									model: 'route',
									action: 'update'
								}">
                {{ 'common_save' | translate }}
              </button>
              <ng-container *appRestrictedByScope="{
									model: 'route',
									action: 'delete'
								}">
                <button *ngIf="!route.isNew() && deletable" class="ml-4" nz-button nzShape="circle"
                  [title]="'common_delete' | translate" [nzLoading]="deleting" (click)="deleteModal = true"
                  type="button">
                  <i nz-icon nzType="delete"></i>
                </button>
              </ng-container>
            </div>
            <!-- /Actions -->
          </div>
        </div>
      </div>
    </div>
  </nz-affix>
  <div class="container">
    <nz-card class="round-border my-5">
      <div *ngIf="loading" class="row">
        <div class="col-12">
          <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
      </div>
      <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">
          <div class="col-12 col-md-6">
            <!-- Name -->
            <nz-form-item>
              <h4>{{ 'route_name' | translate }}</h4>
              <nz-form-control>
                <input nz-input class="w-100" type="text" [placeholder]="
										'common_value-string' | translate
									" formControlName="name" />
              </nz-form-control>
            </nz-form-item>
            <!-- /Name -->
          </div>
          <div class="col-12 col-md-6">
            <h4>{{'common_title' | translate}}</h4>
            <app-language-tab [name]="'title'" [model]="route"></app-language-tab>
          </div>


          <div class="col-12 col-md-6">
            <!-- Plateau -->
            <nz-form-item>
              <h4>
                {{ 'point-of-interest_plateau' | translate }}
              </h4>
              <nz-form-control [nzValidateStatus]="form.get('plateau')">
                <hpf-plateau-select [controlName]="'plateau'" [formGroup]="form" [quickFormEnabled]="quickFormEnabled"
                  [(model)]="route.props.plateau" [nullable]="true" [filterEnabled]="true" [placeholder]="
                    'plateau'
                    " (change)="updatePoiMap($event)" [emptyLabel]="'common_empty' | translate">
                </hpf-plateau-select>
              </nz-form-control>
            </nz-form-item>
            <!-- /Plateau -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Duration -->
            <nz-form-item>
              <h4>{{ 'route_duration' | translate }}</h4>
              <nz-form-control>
                <nz-input-number class="w-100" formControlName="duration" [nzStep]="1" [nzPlaceHolder]="
										'common_value-number' | translate
									"></nz-input-number>
              </nz-form-control>
            </nz-form-item>
            <!-- /Duration -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Color -->

            <h4>{{ 'route_color' | translate }}</h4>

            <span [cpToggle]="true" [cpDialogDisplay]="'inline'" [(colorPicker)]="color"
              (colorPickerChange)="onChangedColor($event)"></span>
            <!-- /Color -->
          </div>


          <div class="col-12 col-md-6">
            <!-- Published -->
            <nz-form-item>
              <h4>{{ 'route_published' | translate }}</h4>
              <nz-form-control>
                <nz-select class="w-100" [nzPlaceHolder]="
                  'common_value-boolean' | translate
                " formControlName="published">
                  <nz-option [nzValue]="true" [nzLabel]="'common_true' | translate"></nz-option>
                  <nz-option [nzValue]="false" [nzLabel]="'common_false' | translate"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <!-- /Published -->
          </div>

          <!-- Itinerary -->
          <div class="col-12">
            <h4>{{ 'route_itinerary' | translate }}</h4>
            <div *ngIf="mapDisplay" style="height: 500px;" leaflet leafletDraw (leafletDrawStart)="onDrawStart($event)"
              [leafletDrawOptions]="mapDrawOptions" (leafletDrawCreated)="onDrawCreated($event)"
              (leafletDrawEdited)="onDrawEdited($event)" [(leafletCenter)]="mapOptions.center"
              [(leafletLayers)]="mapOptions.layers" [(leafletZoom)]="mapOptions.zoom"
              [leafletMaxBounds]="mapOptions.maxBounds">
              <div [leafletLayer]="mapDrawnItems"></div>
              <div [leafletLayer]="mapPoiItems"></div>
            </div>
          </div>
          <!-- /Itinerary -->


        </div>

      </div>
    </nz-card>
  </div>

</form>
