import {Injectable} from '@angular/core';
import {Video, VideoInterface, VideoPayload} from './video';
import {VideoSearchParamsInterface} from './video-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class VideoService extends BaseModelService< Video, VideoInterface, VideoPayload, VideoSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'video';
  }
  /** @inheritDoc */
  protected new(object: VideoInterface): Video {
    return new Video(object);
  }
}
