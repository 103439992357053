import { Injectable } from '@angular/core';
import {
	ActivityLog,
	ActivityLogInterface,
	ActivityLogPayload,
} from './activity-log';
import { ActivityLogSearchParamsInterface } from './activity-log-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class ActivityLogService extends BaseModelService<
	ActivityLog,
	ActivityLogInterface,
	ActivityLogPayload,
	ActivityLogSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'activity-log';
	}
	/** @inheritDoc */
	protected new(object: ActivityLogInterface): ActivityLog {
		return new ActivityLog(object);
	}
}
