<hpf-role-form
	[role]="role"
	[loading]="role.isNew()"
	(update)="onUpdate()"
	(delete)="onDelete()"
></hpf-role-form>
<div class="container">
	<div
		class="row"
		*appRestrictedByScope="{
			model: 'role_permission',
			action: 'search'
		}"
	>
		<div class="py-4 pb-5 col-12">
			<div class="py-3">
				<h3>
					<a
						[routerLink]="['/role-permission']"
						[queryParams]="
							rolePermissionAsRoleSearchParams.toObject()
						"
					>
						{{ 'role_role_permission-as-role' | translate }}
					</a>
				</h3>
			</div>
			<hpf-role-permission-table
				[searchParams]="rolePermissionAsRoleSearchParams"
				(select)="onSelectRolePermissionAsRole($event)"
			>
			</hpf-role-permission-table>
		</div>
	</div>
</div>
