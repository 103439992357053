import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ErrorService } from '@app/services';
import { ActivityLogSearchParams } from '../activity-log-search-params';
import { ActivityLogService } from '../activity-log.service';
@Component({
	selector: 'hpf-activity-log-count',
	templateUrl: './activity-log-count.component.html',
})
export class ActivityLogCountComponent implements OnInit {
	/** The filters */
	@Input()
	searchParams: ActivityLogSearchParams = new ActivityLogSearchParams();
	/** Count result */
	count: number;
	/** Load flag */
	loaded = false;
	/** Constructor */
	constructor(
		private activityLogService: ActivityLogService,
		private errorService: ErrorService
	) {}
	/** Init */
	ngOnInit() {
		this.activityLogService
			.count(this.searchParams.toObject())
			.then((count) => {
				this.count = count;
				this.loaded = true;
			})
			.catch((error) => this.errorService.handle(error));
	}
}
