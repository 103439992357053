
<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<form nz-form
      class="credit-form w-100"
      *ngIf="form"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      [nzAutoTips]="explainErrorsService.autoTips">
    <nz-affix>
        <div class="py-4 border-bottom bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex align-items-center justify-content-between">
            			<!-- Title -->
            			<div>
            				<h1 class="mb-0">
            					<span *ngIf="credit.isNew()">{{ 'credit_create-form-title' | translate }}</span>
            					<span *ngIf="!credit.isNew()">{{ credit.getLabel() }}</span>
            				</h1>
            				<small *ngIf="!credit.isNew()" class="text-black-50">{{ 'common_id' | translate: { id : credit.getId() } }}</small>
            			</div>
            			<!-- /Title -->
            			
            			<!-- Actions -->
            			<div>
            				<button
            					nz-button
            					[disabled]="form.invalid"
            					[nzLoading]="saving"
            					nzType="primary"
                                *appRestrictedByScope="{
                                    model: 'credit',
                                    action: 'update'
                                }"
            				>
            					{{ 'common_save' | translate }}
            				</button>
                            <ng-container *appRestrictedByScope="{
                                model: 'credit',
                                action: 'delete'
                            }">
                                <button
                                    *ngIf="!credit.isNew() && deletable"
                                    class="ml-4"
                                    nz-button
                                    nzShape="circle"
                                    [title]="'common_delete' | translate"
                                    [nzLoading]="deleting"
                                    (click)="deleteModal = true"
                                    type="button"
                                >
                                    <i nz-icon nzType="delete"></i>
                                </button>
                            </ng-container>
            			</div>
            			<!-- /Actions -->
            		</div>
            	</div>
            </div>
        </div>
    </nz-affix>
    <div class="container">
    <nz-card class="round-border my-5">
    <div *ngIf="loading" class="row">
        <div class="col-12">
            <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
    </div>
    <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">
            <div class="col-12 col-md-6">
                <!-- Title -->

                <h4>{{ 'credit_title' | translate }}</h4>
               	<app-language-tab [name]="'title'" [model]="credit"></app-language-tab>
               	</div>

            <div class="col-12 col-md-6">
                <!-- Description -->

                <h4>{{ 'credit_description' | translate }}</h4>
               	<app-language-tab [name]="'description'" [model]="credit"></app-language-tab>
               	</div>

        </div>

        <nz-divider *ngIf="!credit.isNew() && !readOnly"></nz-divider>
        <div *ngIf="!credit.isNew()" class="row">
            <div class="col-12 col-md-6" >
                <!-- _Id -->
                <div class="mb-4">
                    <h4>{{ 'credit_-id' | translate }}</h4>
                    <div>{{ credit.props._id }}</div>
                </div>
                <!-- /_Id -->
            </div>
            <div class="col-12 col-md-6" >
                <!-- Created At -->
                <div class="mb-4">
                    <h4>{{ 'credit_created-at' | translate }}</h4>
                    <div>{{ credit.props.created_at | date:'medium' }}</div>
                </div>
                <!-- /Created At -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Title -->
                <div class="mb-4">
                    <h4>{{ 'credit_title' | translate }}</h4>
                    <div *ngIf="credit.titleExists()">
                        <div *ngFor="let item of credit.props.title">
                            <a class="mr-2" [routerLink]="['/text-translated', item.getId()]">
                                {{ item.getLabel() }}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- /Title -->
            </div>
            <div class="col-12 col-md-6" *ngIf="readOnly">
                <!-- Description -->
                <div class="mb-4">
                    <h4>{{ 'credit_description' | translate }}</h4>
                    <div *ngIf="credit.descriptionExists()">
                        <div *ngFor="let item of credit.props.description">
                            <a class="mr-2" [routerLink]="['/text-translated', item.getId()]">
                                {{ item.getLabel() }}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- /Description -->
            </div>
        </div>
    </div>
    </nz-card>
    </div>
</form>
