<hpf-audio-form
	[audio]="audio"
	[loading]="audio.isNew()"
	(update)="onUpdate()"
	(delete)="onDelete()"
></hpf-audio-form>
<div class="container">
	<hpf-activity-history
		*ngIf="historySearchParams"
		[historySearchParams]="historySearchParams"
	></hpf-activity-history>
</div>
