import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { TranslateModuleLoad } from '@app/translate-import';
import { NgxFileDropModule } from 'ngx-file-drop';
import {
	RoleService,
	RoleFormComponent,
	RoleFiltersComponent,
	RoleTableComponent,
	RoleSelectComponent,
	RoleListTopBarComponent,
	RoleCountComponent,
} from './role';
import { RoleComponent } from './role/role/role.component';
import { RoleCreateComponent } from './role/views/role-create/role-create.component';
import { RoleEditComponent } from './role/views/role-edit/role-edit.component';
import { RoleListComponent } from './role/views/role-list/role-list.component';
import {
	RolePermissionService,
	RolePermissionFormComponent,
	RolePermissionFiltersComponent,
	RolePermissionTableComponent,
	RolePermissionSelectComponent,
	RolePermissionListTopBarComponent,
	RolePermissionCountComponent,
} from './role-permission';
import { RolePermissionComponent } from './role-permission/role-permission/role-permission.component';
import { RolePermissionCreateComponent } from './role-permission/views/role-permission-create/role-permission-create.component';
import { RolePermissionEditComponent } from './role-permission/views/role-permission-edit/role-permission-edit.component';
import { RolePermissionListComponent } from './role-permission/views/role-permission-list/role-permission-list.component';
import { RolePermissionRoutingModule } from './role-permission-routing.module';
@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		TranslateModuleLoad(),
		RolePermissionRoutingModule,
		NgxFileDropModule,
	],
	declarations: [
		RoleComponent,
		RoleFormComponent,
		RoleCreateComponent,
		RoleEditComponent,
		RoleListComponent,
		RoleFiltersComponent,
		RoleTableComponent,
		RoleSelectComponent,
		RoleListTopBarComponent,
		RoleCountComponent,
		RolePermissionComponent,
		RolePermissionFormComponent,
		RolePermissionCreateComponent,
		RolePermissionEditComponent,
		RolePermissionListComponent,
		RolePermissionFiltersComponent,
		RolePermissionTableComponent,
		RolePermissionSelectComponent,
		RolePermissionListTopBarComponent,
		RolePermissionCountComponent,
	],
	providers: [RoleService, RolePermissionService],
	exports: [RoleCountComponent, RolePermissionCountComponent],
})
export class RolePermissionModule {}
