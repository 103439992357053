
import {Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChildren, QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService, ExplainErrorsService, SessionService} from '@app/services';
import { environment } from '@env/environment';
import {Language} from '../language';
import {LanguageService} from '../language.service';
import { LanguageTabComponent } from '@app/models/language';
@Component({
  selector: 'hpf-language-form',
  templateUrl: './language-form.component.html'
})
export class LanguageFormComponent implements OnInit, OnDestroy {
  /** The model subscription */
  private modelSubscription: RxJsSubscription;
  /** The language to inject in the form */
  @Input() language: Language;
  /** Called the save button is clicked for a new instance */
  @Output() create = new EventEmitter<Language>();
  /** Called the save button is clicked for an existing instance */
  @Output() update = new EventEmitter<void>();
  /** Called the delete button is clicked */
  @Output() delete = new EventEmitter<void>();
  /** Set loading state of the form */
  @Input() loading = false;
  /** Enable the deletion button */
  @Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
  /** Denotes if deltion in progress */
  deleting = false;
  /** Show delete modal */
  deleteModal = false;
  /** The form group to use */
  form: FormGroup;
  /** Denotes if the form is pending */
  saving = false;
  /** Check permission of update **/
  readOnly = false;
	@ViewChildren(LanguageTabComponent) languageTabs: QueryList<
		LanguageTabComponent
	>;
  /** Constructor */
  constructor(private formBuilder: FormBuilder,
              private errorService: ErrorService,
              public explainErrorsService: ExplainErrorsService,
              private languageService: LanguageService,
              private sessionService: SessionService) {
  }
  /** Init */
  async ngOnInit() {
    this.readOnly = !(await this.sessionService.checkPermission(
			'language',
			'update'
		));

    // Init model
    if(!this.language) this.language = new Language();

    // Create form
    this.form = this.formBuilder.group({
    name: new FormControl(this.language.props.name, [Validators.required]),
    display_name: new FormControl(this.language.props.display_name, [Validators.required]),
    language_manager_name: new FormControl(this.language.props.language_manager_name, [Validators.required]),
    });
    // Update form when model loads or changes
    this.modelSubscription = this.language.subscribe(() => {
        this.updateForm();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.modelSubscription.unsubscribe();
  }
  /** Called on form submit */
  async onSubmit(): Promise<void> {
    // Saving flag
    this.saving = true;
    try {
        // Update model
        this.updateModel();
		for (let element of this.languageTabs) {
				await element.submit();
			}
        // Creation or update ?
        if (this.language.isNew()) {
            // Creation
            const language: Language = await this.languageService.create(this.language.toPayload());
            this.create.next(language);
        } else {
            // Update
            await this.languageService.update(this.language.getId(), this.language.toPayload());
            this.update.next();
        }
    } catch (error) {
        this.errorService.handle(error);
    }
    // Saving flag
    this.saving = false
  }
  /** Update models properties from inputs values */
  private updateModel(): void {
    for (const key of Object.keys(this.form.controls)) {
        this.language.props[key] = this.form.get(key).value;
    }
  }
  /** Update inputs values from models properties */
  private updateForm(): void {
    this.form.setValue({
      'name': this.language.props.name,   
      'display_name': this.language.props.display_name,   
      'language_manager_name': this.language.props.language_manager_name,   
    });
  }
  /** Called on deletion */
  onDelete(): void {
    this.deleting = true;
    this.languageService.remove(this.language.getId())
      .then(() => {
        this.delete.next();
      })
      .catch((error) => this.errorService.handle(error))
      .then(() => this.deleting = false);
  }
}
