import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@app/services';
import { Role } from '../../role';

@Component({
	selector: 'hpf-role-create',
	templateUrl: './role-create.component.html',
})
export class RoleCreateComponent implements OnInit {
	/** The role */
	role = new Role();

	/** Constructor */
	constructor(private router: Router, private errorService: ErrorService) {}
	/** Init */
	ngOnInit() {}
	/** Called when the role is created */
	onCreate(role: Role): void {
		this.router
			.navigateByUrl(`/role/${role.getId()}`)
			.catch((error) => this.errorService.handle(error));
	}
}
