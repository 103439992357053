import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'hpf-role',
	templateUrl: './role.component.html',
})
export class RoleComponent implements OnInit {
	/** Constructor */
	constructor() {}

	/** Init */
	ngOnInit() {}
}
