import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Language} from '../../language';

@Component({
  selector: 'hpf-language-create',
  templateUrl: './language-create.component.html'
})
export class LanguageCreateComponent implements OnInit {
  /** The language */
  language = new Language();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the language is created */
  onCreate(language: Language): void {
    this.router.navigateByUrl(`/language/${language.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
