import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Audio} from '../../audio';

@Component({
  selector: 'hpf-audio-create',
  templateUrl: './audio-create.component.html'
})
export class AudioCreateComponent implements OnInit {
  /** The audio */
  audio = new Audio();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the audio is created */
  onCreate(audio: Audio): void {
    this.router.navigateByUrl(`/audio/${audio.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
