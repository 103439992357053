import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {TextTranslatedSearchParams} from '../text-translated-search-params';

@Component({
  selector: 'hpf-text-translated-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class TextTranslatedListTopBarComponent extends EntityListTopBarComponent< TextTranslatedSearchParams> {
}
 