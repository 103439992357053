import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { Audio } from '../../audio';
import { AudioService } from '../../audio.service';
import { ActivityLogSearchParams } from '@app/plugins/activity';
@Component({
	selector: 'hpf-audio-edit',
	templateUrl: './audio-edit.component.html'
})
export class AudioEditComponent implements OnInit, OnDestroy {
	/** The audio */
	audio = new Audio();
	/**	History search params */
	historySearchParams: ActivityLogSearchParams;
	/** The audio */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private audioService: AudioService
	) {}
	/** Init */
	ngOnInit() {
		// Get the audio after reading the id
		this.idSubscription = this.route.params.subscribe(params => {
			this.id = params['id'];
			this.refresh();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch audio from API */
	private refresh() {
		this.audioService
			.get(this.id, { _populate: ['*'] })
			.then((audio: Audio) => {
				this.audio.fromObject(audio.toObject());

				// Refresh history
				const params = {
					entity_id: this.id,
					entity_type: 'audio',
				};
				if (!this.historySearchParams) {
					this.historySearchParams = new ActivityLogSearchParams(
						params
					);
				} else {
					this.historySearchParams.fromObject(params);
				}
			})
			.catch(error => this.errorService.handle(error));
	}
	/** Called when the audio is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the audio is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/audio')
			.catch(error => this.errorService.handle(error));
	}
}
