import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
} from '@angular/router';
import { SessionService } from '@app/services';
import { environment } from '@env/environment';

@Injectable()
export class CheckPermissionGuard implements CanActivate {
	constructor(
		private _sessionService: SessionService,
		private _router: Router
	) {}

	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		if (await this._sessionService.isAdmin()) {
			this._sessionService.setPathAfterLogin(null);

			const { model, action } = next.data;
			if (await this._sessionService.checkPermission(model, action)) {
				return true;
			} else {
				await this._router.navigate(['/']);
			}
		} else {
			this._sessionService.setPathAfterLogin(state);
			await this._router.navigate(environment.logout.redirection);
			return false;
		}
	}
}
