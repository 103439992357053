import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ErrorService } from '@app/services';
import { RolePermissionSearchParams } from '../role-permission-search-params';
import { RolePermissionService } from '../role-permission.service';
@Component({
	selector: 'hpf-role-permission-count',
	templateUrl: './role-permission-count.component.html',
})
export class RolePermissionCountComponent implements OnInit {
	/** The filters */
	@Input()
	searchParams: RolePermissionSearchParams = new RolePermissionSearchParams();
	/** Count result */
	count: number;
	/** Load flag */
	loaded = false;
	/** Constructor */
	constructor(
		private rolePermissionService: RolePermissionService,
		private errorService: ErrorService
	) {}
	/** Init */
	ngOnInit() {
		this.rolePermissionService
			.count(this.searchParams.toObject())
			.then((count) => {
				this.count = count;
				this.loaded = true;
			})
			.catch((error) => this.errorService.handle(error));
	}
}
