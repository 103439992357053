import {Injectable} from '@angular/core';
import {Marker, MarkerInterface, MarkerPayload} from './marker';
import {MarkerSearchParamsInterface} from './marker-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class MarkerService extends BaseModelService< Marker, MarkerInterface, MarkerPayload, MarkerSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'marker';
  }
  /** @inheritDoc */
  protected new(object: MarkerInterface): Marker {
    return new Marker(object);
  }
}
