import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Category} from '../../category';

@Component({
  selector: 'hpf-category-create',
  templateUrl: './category-create.component.html'
})
export class CategoryCreateComponent implements OnInit {
  /** The category */
  category = new Category();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the category is created */
  onCreate(category: Category): void {
    this.router.navigateByUrl(`/category/${category.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
