import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-audio-description',
  templateUrl: './audio-description.component.html'
})
export class AudioDescriptionComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
