import { Helpers } from '@app/shared';
import {
	BaseModelSearchParams,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';

/** Used to export and import search params */
export interface RouteSearchParamsInterface
	extends BaseModelSearchParamsInterface {
	_sort?: '_id' | 'created_at' | 'name' | 'published';
	_id?: string[];
	_text?: string;
	owner?: string;
	name?: string;
	published?: 'true' | 'false' | boolean;
	plateau?: string;
}

/** Manage route search params */
export class RouteSearchParams extends BaseModelSearchParams<
	RouteSearchParamsInterface
> {
	/** @inheritDoc */
	fromObject(input: Partial<RouteSearchParamsInterface>): void {
		// Clone object with allowed keys only
		const props = {} as RouteSearchParamsInterface;
		this.allowedKeys().map((key: string) => {
			if (typeof input[key] !== 'undefined') {
				props[key] = input[key];
			}
		});

		// Keep default values
		if (typeof props._page === 'undefined') {
			props._page = this.defaultPage;
		}
		if (typeof props._limit === 'undefined') {
			props._limit = this.defaultLimit;
		}
		if (typeof props._sort === 'undefined') {
			props._sort = this.defaultSort;
		}
		if (typeof props._order === 'undefined') {
			props._order = this.defaultOrder;
		}
		// Convert potentially multiple primary keys
		if (typeof props._id === 'string') {
			props._id = (<string>props._id).split(',').filter((s) => s.length);
		}

		// Convert boolean published
		if (typeof props.published !== 'undefined') {
			props.published = Helpers.convertToBoolean(props.published);
		}

		// Assign values
		this.props = props;
		this.next();
	}
	/** @inheritDoc */
	toObject(): RouteSearchParamsInterface {
		// Filter not allowed, undefined and null values
		const props = {} as RouteSearchParamsInterface;
		this.allowedKeys().map(Helpers.prepareObjectForApi(this.props, props));

		// Convert boolean published
		if (typeof props.published !== 'undefined') {
			props.published = props.published ? 'true' : 'false';
		}

		return props;
	}
	/** @inheritDoc */
	protected allowedKeys(): string[] {
		return super
			.allowedKeys()
			.concat(['_id', '_text', 'owner', 'name', 'published', 'plateau']);
	}
}
