import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityLogSearchParams } from '../activity-log-search-params';
import { ActivityLog } from '../activity-log';
import { BaseModelInterface, BaseModel } from '@app/abstracts';
@Component({
	selector: 'hpf-activity-history',
	templateUrl: './activity-history.component.html',
})
export class ActivityHistoryComponent implements OnInit {
	/** Id of the entity history */
	@Input() entity: BaseModel<BaseModelInterface, {}>;
	/** Collection of the entity history */
	@Input() collection: string;
	/** The filter for history */
	@Input() historySearchParams: ActivityLogSearchParams;
	/** Constructor */
	constructor(private router: Router) {}
	/** Init */
	ngOnInit() {}

	/** Called on when user select an history */
	onSelectHistory(item: ActivityLog): void {
		this.router.navigateByUrl(`/activity-log/${item.getId()}`);
	}
}
