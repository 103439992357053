import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Role, RoleInterface } from '../role/role';
export interface RolePermissionInterface extends BaseModelInterface {
	created_at: number | Date;
	role: string | Role | RoleInterface;
	collection: string;
	create: boolean;
	read: boolean;
	update: boolean;
	delete: boolean;
	search: boolean;
	count: boolean;
}
export interface RolePermissionPayload {
	role: string | Role | RoleInterface;
	collection: string;
	create: boolean;
	read: boolean;
	update: boolean;
	delete: boolean;
	search: boolean;
	count: boolean;
}
type RolePermissionPayloadKey = keyof RolePermissionPayload;
export class RolePermission extends BaseModel<
	RolePermissionInterface,
	RolePermissionPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of role has been populated */
	roleExists(): boolean {
		return (
			!!this.props &&
			this.props.role instanceof Role &&
			this.props.role.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: RolePermissionInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.role === 'object') {
			this.props.role = new Role(<RoleInterface>this.props.role);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): RolePermissionInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.role instanceof Role) {
			props.role = props.role.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): RolePermissionPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as RolePermissionInterface);
		payload.role = payload.role ? this.extractId(payload.role) : null;
		return payload as RolePermissionPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): RolePermissionPayloadKey[] {
		return [
			'role',
			'collection',
			'create',
			'read',
			'update',
			'delete',
			'search',
			'count',
		];
	}
}
