import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { TranslateModuleLoad } from '@app/translate-import';
import { ModelsModule } from '@app/models';
import { QuickFormComponent } from './quick-form/quick-form.component';

@NgModule({
	imports: [CommonModule, SharedModule, ModelsModule, TranslateModuleLoad()],
	declarations: [QuickFormComponent],
	exports: [QuickFormComponent],
})
export class QuickFormModule {}
