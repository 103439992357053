import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { Helpers } from '@app/shared';
import { Diaporama } from '../diaporama';
import { DiaporamaService } from '../diaporama.service';
import { LanguageTabComponent } from '@app/models/language';
@Component({
	selector: 'hpf-diaporama-form',
	templateUrl: './diaporama-form.component.html',
})
export class DiaporamaFormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The diaporama to inject in the form */
	@Input() diaporama: Diaporama;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<Diaporama>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	@ViewChildren(LanguageTabComponent) languageTabs: QueryList<
		LanguageTabComponent
	>;
	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private diaporamaService: DiaporamaService,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'diaporama',
			'update'
		));

		// Init model
		if (!this.diaporama) this.diaporama = new Diaporama();

		// Create form
		this.form = this.formBuilder.group({
			name: new FormControl(this.diaporama.props.name, [
				Validators.required,
			]),
			audios: new FormControl(this.diaporama.props.audios, [
				Validators.required,
			]),
			is360: new FormControl(this.diaporama.props.is360, [
				Validators.required,
			]),
			is_video: new FormControl(this.diaporama.props.is_video, [
				Validators.required,
			]),

			video: new FormControl(this.diaporama.props.video, []),
		});
		// Update form when model loads or changes
		this.modelSubscription = this.diaporama.subscribe(() => {
			this.updateForm();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();
			for (let element of this.languageTabs) {
				await element.submit();
			}
			// Creation or update ?
			if (this.diaporama.isNew()) {
				// Creation
				const diaporama: Diaporama = await this.diaporamaService.create(
					this.diaporama.toPayload()
				);
				this.create.next(diaporama);
			} else {
				// Update
				await this.diaporamaService.update(
					this.diaporama.getId(),
					this.diaporama.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.diaporama.props[key] = this.form.get(key).value;
		}
		if (typeof this.diaporama.props.slides === 'string') {
			this.diaporama.props.slides = this.diaporama.props.slides
				? JSON.parse(this.diaporama.props.slides)
				: [];
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			name: this.diaporama.props.name,
			audios: this.diaporama.props.audios,
			is360: this.diaporama.props.is360,
			is_video: this.diaporama.props.is_video,

			video: this.diaporama.props.video,
		});
	}

	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.diaporamaService
			.remove(this.diaporama.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
