import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {LanguageSearchParams} from '../language-search-params';
import { Subscription as RxJsSubscription, Subject as RxJsSubject } from 'rxjs';
import {debounceTime} from 'rxjs/operators';
@Component({
  selector: 'hpf-language-filters',
  templateUrl: './language-filters.component.html'
})
export class LanguageFiltersComponent implements OnInit, OnDestroy {
  /** The filters */
  @Input() searchParams: LanguageSearchParams = new LanguageSearchParams();
  /** The debounce delay before calling the API for filtering */
  @Input() filterDebounceTime = 300;
  /** Subject for debounced keyup event */
  private keyupSubject = new RxJsSubject<void>();
  /** Subscription of the component */
  private subscription: RxJsSubscription;
  /** Denotes if the page number should be set to 0 when filters change */
  @Input() resetPage = false;
  /** Constructor */
  constructor() {}
  /** Init */
  ngOnInit() {
    // Subscription
    this.subscription = this.keyupSubject
      .pipe(debounceTime(this.filterDebounceTime))
      .subscribe(() => { this.onChange(); });
  }
  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  /** Called when a keyboard input is changed */
  onChangeDebounced() {
    this.keyupSubject.next();
  }
  /** Called when an input is changed */
  onChange() {
    if (this.resetPage) {
        this.searchParams.props._page = 0;
    }
    this.searchParams.next();
  }
  /** Clear all filters */
  public resetSearchParams() {
    this.searchParams.fromObject({});
  }
}
