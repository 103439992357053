import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {VideoSearchParams} from '../video-search-params';

@Component({
  selector: 'hpf-video-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class VideoListTopBarComponent extends EntityListTopBarComponent< VideoSearchParams> {
}
 