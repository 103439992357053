import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {TextTranslated} from '../../text-translated';
import {TextTranslatedService} from '../../text-translated.service';
@Component({
  selector: 'hpf-text-translated-edit',
  templateUrl: './text-translated-edit.component.html'
})
export class TextTranslatedEditComponent implements OnInit, OnDestroy {
  /** The text translated */
  textTranslated = new TextTranslated();
  /** The text translated */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private textTranslatedService: TextTranslatedService) {}
  /** Init */
  ngOnInit() {
    // Get the text translated after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch text translated from API */
  private refresh() {
    this.textTranslatedService.get(this.id, { _populate: ['*'] })
      .then((textTranslated: TextTranslated) => {
        this.textTranslated.fromObject(textTranslated.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the text translated is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the text translated is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/text-translated')
      .catch((error) => this.errorService.handle(error));
  }
}
