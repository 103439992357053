import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {CreditSearchParams} from '../credit-search-params';

@Component({
  selector: 'hpf-credit-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class CreditListTopBarComponent extends EntityListTopBarComponent< CreditSearchParams> {
}
 