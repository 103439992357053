import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Plateau} from '../../plateau';

@Component({
  selector: 'hpf-plateau-create',
  templateUrl: './plateau-create.component.html'
})
export class PlateauCreateComponent implements OnInit {
  /** The plateau */
  plateau = new Plateau();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the plateau is created */
  onCreate(plateau: Plateau): void {
    this.router.navigateByUrl(`/plateau/${plateau.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
