import { Component, Input } from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface
} from '@app/abstracts';
import { ErrorService } from '@app/services';
import { LanguageSearchParams } from '../language-search-params';
import { LanguageService } from '../language.service';
import { Language } from '../language';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
	selector: 'hpf-language-select',
	templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class LanguageSelectComponent extends EntitySelectComponent<Language> {
	protected modelName = 'language';

	private excludeValue: Language[] = [];
	@Input()
	get exclude(): Language[] {
		return this.excludeValue;
	}
	set exclude(exclude: Language[]) {
		const oldExclude = [
			...new Set(this.excludeValue.map((l) => l.getId())),
		];
		const newExclude = [...new Set(exclude.map((l) => l.getId()))];

		// Check if exclude changed
		const changed =
			oldExclude.length !== newExclude.length ||
			oldExclude.filter((e) => !newExclude.find((ne) => ne === e))
				.length > 0;

		this.excludeValue = exclude;

		if (changed) this.refresh();
	}

	/**
	 * Constructor
	 * @param {LanguageService} languageService
	 * @param {ErrorService} errorService
	 */
	constructor(
		private languageService: LanguageService,
		protected errorService: ErrorService,
		protected quickFormService: QuickFormService
	) {
		super(errorService, quickFormService);
	}
	ngOnInit() {
		super.ngOnInit();
		// Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if (!(model instanceof Language)) return;
					if (this.multiple) {
						if (!this.model) this.model = [];
						(<Language[]>this.model).push(<Language>model);
					} else {
						this.model = <Language>model;
					}
					this.refresh();
				}
			)
		);
	}
	/** @inheritDoc */
	protected async getList(): Promise<Language[]> {
		const params = new LanguageSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		return (
			await this.languageService.list(params.toObject())
		).items.filter(
			(lang) =>
				!this.exclude ||
				!this.exclude.find(
					(excludeLang) => excludeLang.getId() === lang.getId()
				)
		);
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<Language> {
		return await this.languageService.get(id);
	}
}
