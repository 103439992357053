import { Component } from '@angular/core';
import { EntityListTopBarComponent } from '@app/abstracts';
import { ActivityLogSearchParams } from '../activity-log-search-params';

@Component({
	selector: 'hpf-activity-log-list-top-bar',
	templateUrl:
		'../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html',
})
export class ActivityLogListTopBarComponent extends EntityListTopBarComponent<
	ActivityLogSearchParams
> {}
