import { OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
	BaseModel,
	BaseModelInterface,
	BaseModelSearchParams,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';
import { NzTableSortOrder } from 'ng-zorro-antd/table/src/table.types';

export abstract class EntityTableComponent<
	T extends BaseModel<BaseModelInterface, {}>,
	S extends BaseModelSearchParams<BaseModelSearchParamsInterface>
> implements OnInit, OnDestroy {
	/** The search params */
	@Input() searchParams: S;
	/** Delay to show loading spinner. Avoid blinking effect on fast APIs */
	@Input() refreshingDelay = 200;
	/** Triggered when the user select a row */
	@Output() select = new EventEmitter<T>();
	/** Raised if the list in being updated */
	refreshing = true;
	/** Subscription to the tables events */
	private subscriptions: Subscription[] = [];
	/** Object containing results */
	items: T[] = [];
	/** Length of the received results */
	total = 0;
	/** Index of the displayed page (starts at 1) */
	pageIndex = 1;

	/** Show the multiselect column */
	@Input() enableMultiSelect = false;
	/** Show the actions column */
	@Input() showActions = false;
	/** Selected rows for bulk delete */
	selectedRows: T[] = [];
	/** Single row for delete one */
	rowToDelete: T;
	/** Delete selected rows */
	@Output() deleteMany = new EventEmitter<T[]>();
	/** Delete one row */
	@Output() deleteOne = new EventEmitter<T>();
	/** Denotes if one or many items are being deleted */
	deleting = false;
	/** Show delete one modal */
	deleteOneModal = false;
	/** Show delete many modal */
	deleteManyModal = false;

	/** Init */
	ngOnInit() {
		// Subscriptions
		this.subscriptions = [
			this.searchParams.subscribe(() => {
				this.pageIndex = Number(this.searchParams.props._page) + 1;
				this.refresh();
			}),
		];
	}
	/** Destroy */
	ngOnDestroy() {
		this.subscriptions.map((s) => s.unsubscribe());
	}
	/** On select */
	onClick(item: T) {
		this.select.emit(item);
	}
	/** Update data */
	update(resetPage = false): void {
		// Set page number
		this.searchParams.props._page = resetPage ? 0 : this.pageIndex - 1;
		// Trigger update
		this.searchParams.next();
	}
	/** Sort data */
	sort(info: { key: string; value: NzTableSortOrder }): void {
		// Remove sorting
		if (info.value === null) {
			delete this.searchParams.props._sort;
			delete this.searchParams.props._order;
		}
		// Apply sorting
		else {
			this.searchParams.props._sort = info.key;
			this.searchParams.props._order =
				info.value === 'ascend' ? 'asc' : 'desc';
		}
		// Trigger update
		this.searchParams.next();
	}
	/** Refresh data from search params */
	protected abstract refresh(): void;
	/** Push or remove model */
	selectRow(selected: boolean, model: T): void {
		if (selected) {
			this.selectedRows.push(model);
		} else {
			this.selectedRows = this.selectedRows.filter(
				(m) => m.getId() !== model.getId()
			);
		}
	}
	/** Push or remove model */
	isSelected(model: T): boolean {
		return this.selectedRows.some((m) => m.getId() === model.getId());
	}
	/** Reset the array of selected rows */
	protected resetSelectedRows(): void {
		this.selectedRows = [];
	}
	/** Get the order for a column regarding the search params */
	getSortOrder(key: string): NzTableSortOrder {
		if (this.searchParams.props._sort !== key) {
			return null;
		}
		if (this.searchParams.props._order === 'asc') {
			return 'ascend';
		}
		if (this.searchParams.props._order === 'desc') {
			return 'descend';
		}
		return null;
	}
}
