import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {PointOfInterest} from '../../point-of-interest';

@Component({
  selector: 'hpf-point-of-interest-create',
  templateUrl: './point-of-interest-create.component.html'
})
export class PointOfInterestCreateComponent implements OnInit {
  /** The point of interest */
  pointOfInterest = new PointOfInterest();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the point of interest is created */
  onCreate(pointOfInterest: PointOfInterest): void {
    this.router.navigateByUrl(`/point-of-interest/${pointOfInterest.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
