<!-- Owner -->
<nz-form-item>
	<nz-form-label>{{ 'image_owner' | translate }}</nz-form-label>
	<hpf-user-select
		[(id)]="searchParams.props.owner"
		[nullable]="true"
		[emptyLabel]="'common_empty' | translate"
		[filterEnabled]="true"
		[placeholder]="'user_common_search-placeholder' | translate"
		(change)="onChange()"
		[quickFormEnabled]="false"
	>
	</hpf-user-select>
</nz-form-item>
<!-- /Owner -->
<!-- Name -->
<nz-form-item>
	<nz-form-label>{{ 'image_name' | translate }}</nz-form-label>
	<input
		nz-input
		class="w-100"
		(keyup)="onChangeDebounced()"
		[placeholder]="'common_value-string' | translate"
		[(ngModel)]="searchParams.props.name"
	/>
</nz-form-item>
<!-- /Name -->
<button nz-button nzType="default" (click)="resetSearchParams()">
	{{ 'common_reset-filters' | translate }}
</button>
