import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Credit} from '../../credit';

@Component({
  selector: 'hpf-credit-create',
  templateUrl: './credit-create.component.html'
})
export class CreditCreateComponent implements OnInit {
  /** The credit */
  credit = new Credit();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the credit is created */
  onCreate(credit: Credit): void {
    this.router.navigateByUrl(`/credit/${credit.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
