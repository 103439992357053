import { Component, Input } from '@angular/core';
import { ErrorService } from '@app/services';
import { EntityTableComponent } from '@app/abstracts';
import { PlateauSearchParams } from '../plateau-search-params';
import { PlateauService } from '../plateau.service';
import { Plateau } from '../plateau';
import { AssetBundler } from '@app/shared/assetbundler';

@Component({
	selector: 'hpf-plateau-table',
	templateUrl: './plateau-table.component.html',
})
export class PlateauTableComponent extends EntityTableComponent<
	Plateau,
	PlateauSearchParams
> {
	plateauSelected: Plateau;
	publishModal = false;
	disablePublish = true;
	moveToPublicModal = false;
	/** Columns to show */
	@Input() columns = ['created_at', 'name', 'min_lat', 'max_lat', 'min_lng'];
	/** Constructor */
	constructor(
		private plateauService: PlateauService,
		private errorService: ErrorService,
		private assetBundler: AssetBundler
	) {
		super();
	}
	async ngOnInit() {
		super.ngOnInit();
		this.disablePublish = await this.assetBundler.isRunning();
	}
	/** @inheritDoc */
	protected refresh(): void {
		const params = this.searchParams.toObject();
		params._populate = ['image_intro', 'title.lang'];
		// Set refreshing flag
		this.refreshing = true;
		// Get list
		this.plateauService
			.list(params)
			.then((results) => {
				// Link data
				this.items = results.items;
				this.total = results.total;
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => {
				this.refreshing = false;
			});
	}

	/** Called when the user select multiple rows and delete them */
	async removeMany() {
		if (!this.selectedRows.length) {
			return;
		}

		this.deleting = true;
		for (const item of this.selectedRows) {
			await this.plateauService
				.remove(item.getId())
				.catch((e) => this.errorService.handle(e));
		}

		this.deleteMany.next(this.selectedRows);
		this.resetSelectedRows();
		this.deleting = false;
		this.deleteManyModal = false;
		this.searchParams.next();
	}

	async createAssetBundle() {
		try {
			await this.plateauService.createAssetBundle(this.plateauSelected);
			window.location.reload();
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	async moveToPublic(): Promise<void> {
		try {
			await this.plateauService.moveToPublic(this.plateauSelected);
			window.location.reload();
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	/** Called when the user delete a single row */
	async removeOne() {
		if (!this.rowToDelete) {
			return;
		}

		this.deleting = true;
		await this.plateauService
			.remove(this.rowToDelete.getId())
			.catch((e) => this.errorService.handle(e));

		this.deleteOne.next(this.rowToDelete);
		delete this.rowToDelete;
		this.deleting = false;
		this.deleteOneModal = false;
		this.searchParams.next();
	}
}
