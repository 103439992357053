import { Component, EventEmitter, Output } from '@angular/core';
import { EntityListTopBarComponent } from '@app/abstracts';
import { ImageSearchParams } from '../image-search-params';

@Component({
	selector: 'hpf-image-list-top-bar',
	templateUrl: './image-list-top-bar.component.html',
})
export class ImageListTopBarComponent extends EntityListTopBarComponent<
	ImageSearchParams
> {
	@Output() createBulkEvent: EventEmitter<void> = new EventEmitter<void>();
}
