import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ErrorService} from '@app/services';
import {AudioDescriptionSearchParams} from '../audio-description-search-params';
import {AudioDescriptionService} from '../audio-description.service';
@Component({
  selector: 'hpf-audio-description-count',
  templateUrl: './audio-description-count.component.html'
})
export class AudioDescriptionCountComponent implements OnInit {
  /** The filters */
  @Input() searchParams: AudioDescriptionSearchParams = new AudioDescriptionSearchParams();
  /** Count result */
  count: number;
  /** Load flag */
  loaded = false;
  /** Constructor */
  constructor(private audioDescriptionService: AudioDescriptionService,
              private errorService: ErrorService) {}
  /** Init */
  ngOnInit() {
    this.audioDescriptionService.count(this.searchParams.toObject())
    .then((count) => {
      this.count = count;
      this.loaded = true;
    })
    .catch((error) => this.errorService.handle(error))
  }
}
