import { Injectable } from '@angular/core';
import { Role, RoleInterface, RolePayload } from './role';
import { RoleSearchParamsInterface } from './role-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class RoleService extends BaseModelService<
	Role,
	RoleInterface,
	RolePayload,
	RoleSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'role';
	}
	/** @inheritDoc */
	protected new(object: RoleInterface): Role {
		return new Role(object);
	}
}
