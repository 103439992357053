import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription as RxJsSubscription } from 'rxjs';
import { RolePermissionSearchParams } from '../../role-permission-search-params';
import { RolePermission } from '../../role-permission';
import { Helpers } from '@app/shared';
@Component({
	selector: 'hpf-role-permission-list',
	templateUrl: './role-permission-list.component.html',
	styleUrls: [],
})
export class RolePermissionListComponent implements OnInit, OnDestroy {
	/** The filters */
	searchParams = new RolePermissionSearchParams(
		this.route.snapshot.queryParams
	);
	/** The search params subscription */
	private paramsSubscription: RxJsSubscription;
	/** Used to open or close filter drawer */
	filtersDrawer = false;
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private location: Location
	) {}
	/** Init */
	ngOnInit() {
		// Update query string when params change
		this.paramsSubscription = this.searchParams.subscribe(() => {
			this.location.replaceState(
				'/role-permission',
				Helpers.toQueryString(this.searchParams.toObject())
			);
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.paramsSubscription.unsubscribe();
	}
	/** Called when the user select a row in the table */
	onSelect(item: RolePermission) {
		this.router.navigateByUrl(`/role-permission/${item.getId()}`);
	}
	/** Called when the user click on create */
	onCreate() {
		this.router.navigate(['/role-permission/create']);
	}
}
