import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-credit',
  templateUrl: './credit.component.html'
})
export class CreditComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
