import { Component, Input } from '@angular/core';
import { ErrorService } from '@app/services';
import { EntityTableComponent } from '@app/abstracts';
import { ActivityLogSearchParams } from '../activity-log-search-params';
import { ActivityLogService } from '../activity-log.service';
import { ActivityLog } from '../activity-log';

@Component({
	selector: 'hpf-activity-log-table',
	templateUrl: './activity-log-table.component.html',
})
export class ActivityLogTableComponent extends EntityTableComponent<
	ActivityLog,
	ActivityLogSearchParams
> {
	/** Columns to show */
	@Input() columns = [
		'created_at',
		'name',
		'entity_type',
		'entity_id',
		'action',
	];
	/** Constructor */
	constructor(
		private activityLogService: ActivityLogService,
		private errorService: ErrorService
	) {
		super();
	}

	/** @inheritDoc */
	protected refresh(): void {
		const params = this.searchParams.toObject();
		// Set refreshing flag
		this.refreshing = true;
		// Get list
		this.activityLogService
			.list(Object.assign({ _populate: '*' }, params))
			.then((results) => {
				// Link data
				this.items = results.items;
				this.total = results.total;
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => {
				this.refreshing = false;
			});
	}

	/** Called when the user select multiple rows and delete them */
	async removeMany() {
		if (!this.selectedRows.length) {
			return;
		}

		this.deleting = true;
		for (const item of this.selectedRows) {
			await this.activityLogService
				.remove(item.getId())
				.catch((e) => this.errorService.handle(e));
		}

		this.deleteMany.next(this.selectedRows);
		this.resetSelectedRows();
		this.deleting = false;
		this.deleteManyModal = false;
		this.searchParams.next();
	}
	/** Called when the user delete a single row */
	async removeOne() {
		if (!this.rowToDelete) {
			return;
		}

		this.deleting = true;
		await this.activityLogService
			.remove(this.rowToDelete.getId())
			.catch((e) => this.errorService.handle(e));

		this.deleteOne.next(this.rowToDelete);
		delete this.rowToDelete;
		this.deleting = false;
		this.deleteOneModal = false;
		this.searchParams.next();
	}
}
