import { Component, Input, Output, EventEmitter } from '@angular/core';
import { S3Service, ErrorService } from '@app/services';
import { UploadXHRArgs } from 'ng-zorro-antd';
import { HttpEventType, HttpResponse, HttpEvent } from '@angular/common/http';

@Component({
	selector: 'app-atom-upload',
	templateUrl: './atom-upload.component.html',
	styleUrls: ['./atom-upload.component.less'],
})
export class AtomUploadComponent {
	@Input() placeholder: string;
	@Input() acceptedFileTypes: string;
	@Input() sizeLimit: number = 20000;
	@Input() multiple: boolean = false;
	@Input() multipleLimit: number = 1;
	@Input() uploadListSettings = {
		showPreviewIcon: false,
		showDownloadIcon: false,
		showRemoveIcon: true,
	};

	@Output() previewUrl = new EventEmitter<string>();
	@Output() temporaryUrl = new EventEmitter<string>();
	@Output() remove = new EventEmitter<string>();

	fileList = [];

	constructor(
		private s3Service: S3Service,
		private errorService: ErrorService
	) {}

	// Upload file to s3 temporary bucket
	customUpload = async (item: UploadXHRArgs) => {
		const file = item.file as any; // Typescript has a conflict between File and UploadFile types
		this.previewUrl.emit(URL.createObjectURL(file));
		try {
			const res = await this.s3Service.uploadFromFileAndReportProgress(
				file
			);
			return res.request.subscribe(
				(event: HttpEvent<{}>) => {
					if (event.type === HttpEventType.UploadProgress) {
						if (event.total! > 0) {
							// tslint:disable-next-line:no-any
							(event as any).percent =
								(event.loaded / event.total!) * 100;
						}
						item.onProgress!(event, item.file!);
					} else if (event instanceof HttpResponse) {
						console.log('uploaded');
						this.temporaryUrl.emit(res.path);
						item.onSuccess!(event.body, item.file!, event);
					}
				},
				(err) => {
					item.onError!(err, item.file!);
					this.errorService.handle(err);
				}
			);
		} catch (error) {
			this.errorService.handle(error);
		}
	};

	cleanMediaUri = () => {
		this.remove.next();
		this.fileList = [];
	};
}
