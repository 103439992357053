import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { User } from '../../user';
import { UserService } from '../../user.service';
import { AudioSearchParams } from '../../../audio/audio-search-params';
import { Audio } from '../../../audio/audio';
import { ImageSearchParams } from '../../../image/image-search-params';
import { Image } from '../../../image/image';

import { PointOfInterestSearchParams } from '../../../point-of-interest/point-of-interest-search-params';
import { PointOfInterest } from '../../../point-of-interest/point-of-interest';
import { RouteSearchParams } from '../../../route/route-search-params';
import { Route } from '../../../route/route';
import { VideoSearchParams } from '../../../video/video-search-params';
import { Video } from '../../../video/video';
import { ActivityLogSearchParams } from '@app/plugins/activity';
@Component({
	selector: 'hpf-user-edit',
	templateUrl: './user-edit.component.html',
})
export class UserEditComponent implements OnInit, OnDestroy {
	/** The user */
	user = new User();
	/**	History search params */
	historySearchParams: ActivityLogSearchParams;
	/** The user */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** The filter for audio as owner */
	audioAsOwnerSearchParams = new AudioSearchParams();
	/** The filter for image as owner */
	imageAsOwnerSearchParams = new ImageSearchParams();
	/** The filter for poi text as owner */

	/** The filter for point of interest as owner */
	pointOfInterestAsOwnerSearchParams = new PointOfInterestSearchParams();
	/** The filter for route as owner */
	routeAsOwnerSearchParams = new RouteSearchParams();
	/** The filter for video as owner */
	videoAsOwnerSearchParams = new VideoSearchParams();
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private userService: UserService
	) {}
	/** Init */
	ngOnInit() {
		// Get the user after reading the id
		this.idSubscription = this.route.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
			// Filter by owner for audio
			this.audioAsOwnerSearchParams.fromObject({ owner: this.id });
			// Filter by owner for image
			this.imageAsOwnerSearchParams.fromObject({ owner: this.id });
			// Filter by owner for poi text

			// Filter by owner for point of interest
			this.pointOfInterestAsOwnerSearchParams.fromObject({
				owner: this.id,
			});
			// Filter by owner for route
			this.routeAsOwnerSearchParams.fromObject({ owner: this.id });
			// Filter by owner for video
			this.videoAsOwnerSearchParams.fromObject({ owner: this.id });
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch user from API */
	private refresh() {
		this.userService
			.get(this.id, { _populate: ['*'] })
			.then((user: User) => {
				this.user.fromObject(user.toObject());

				// Refresh history
				const params = {
					entity_id: this.id,
					entity_type: 'user',
				};
				if (!this.historySearchParams) {
					this.historySearchParams = new ActivityLogSearchParams(
						params
					);
				} else {
					this.historySearchParams.fromObject(params);
				}
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the user is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the user is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/user')
			.catch((error) => this.errorService.handle(error));
	}
	/** Called on when user select a audio as owner */
	onSelectAudioAsOwner(item: Audio): void {
		this.router.navigateByUrl(`/audio/${item.getId()}`);
	}
	/** Called on when user select a image as owner */
	onSelectImageAsOwner(item: Image): void {
		this.router.navigateByUrl(`/image/${item.getId()}`);
	}

	/** Called on when user select a point of interest as owner */
	onSelectPointOfInterestAsOwner(item: PointOfInterest): void {
		this.router.navigateByUrl(`/point-of-interest/${item.getId()}`);
	}
	/** Called on when user select a route as owner */
	onSelectRouteAsOwner(item: Route): void {
		this.router.navigateByUrl(`/route/${item.getId()}`);
	}
	/** Called on when user select a video as owner */
	onSelectVideoAsOwner(item: Video): void {
		this.router.navigateByUrl(`/video/${item.getId()}`);
	}
}
