import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {UserSearchParams} from '../user-search-params';

@Component({
  selector: 'hpf-user-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class UserListTopBarComponent extends EntityListTopBarComponent< UserSearchParams> {
}
 