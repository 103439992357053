import { SelfResponse } from './session.service';

export class Self {
	/** Properties */
	private props: SelfResponse;

	/** Constructor */
	constructor(self: SelfResponse) {
		this.props = self;
	}

	/** Returns primary key */
	getId() {
		return this.props._id;
	}

	/** Returns role */
	getRole() {
		return this.props.role;
	}

	/** Returns display name */
	getName() {
		// @context user:unified-names
		return `${this.props.name}`;
		// @context user:separate-names
		// return `${this.props.first_name} ${this.props.last_name}`;
	}

	/**
	 * Returns if user can access admin
	 * @return {boolean}
	 */
	isAdmin(): boolean {
		return this.props.admin || this.props.role === 'admin';
	}

	/**
	 *	Returns if user is allow to do action in a model
	 * @param model
	 * @param action
	 */
	checkPermission(model, action): boolean {
		if (this.props.scope.indexOf('role-admin') > -1) return true;

		return (
			this.props.scope.indexOf(
				`admin-${model.replace(/_/g, '-')}-${action}`
			) > -1
		);
	}
}
