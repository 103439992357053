<nz-modal
	[(nzVisible)]="deleteOneModal"
	(nzOnCancel)="deleteOneModal = false"
	(nzOnOk)="removeOne()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<nz-modal
	[(nzVisible)]="deleteManyModal"
	(nzOnCancel)="deleteManyModal = false"
	(nzOnOk)="removeMany()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-many' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<div class="activity-log-list-container">
	<nz-table
		#table
		[nzData]="items"
		[nzSize]="'small'"
		[nzShowSizeChanger]="true"
		[nzFrontPagination]="false"
		[nzLoading]="refreshing"
		[nzLoadingDelay]="refreshingDelay"
		[nzTotal]="total"
		[nzNoResult]="notFound"
		[(nzPageIndex)]="pageIndex"
		[(nzPageSize)]="searchParams.props._limit"
		(nzPageIndexChange)="update()"
		(nzPageSizeChange)="update(true)"
	>
		<thead (nzSortOrderChange)="sort($event)">
			<tr>
				<th *ngIf="enableMultiSelect">
					<button
						(click)="deleteManyModal = true"
						nz-button
						[disabled]="selectedRows.length === 0"
						nzType="default"
						nzShape="circle"
					>
						<i nz-icon nzType="delete"></i>
					</button>
				</th>
				<th
					*ngIf="columns.includes('created_at')"
					nzColumnKey="created_at"
					[nzSortOrder]="getSortOrder('created_at')"
				>
					{{ 'activity-log_created-at' | translate }}
				</th>
				<th
					*ngIf="columns.includes('initiator')"
					nzColumnKey="initiator"
				>
					{{ 'activity-log_initiator' | translate }}
				</th>
				<th
					*ngIf="columns.includes('name')"
					nzColumnKey="name"
					[nzSortOrder]="getSortOrder('name')"
				>
					{{ 'activity-log_name' | translate }}
				</th>
				<th
					*ngIf="columns.includes('entity_type')"
					nzColumnKey="entity_type"
					[nzSortOrder]="getSortOrder('entity_type')"
				>
					{{ 'activity-log_entity-type' | translate }}
				</th>
				<th *ngIf="columns.includes('entity_id')">
					{{ 'activity-log_entity-id' | translate }}
				</th>
				<th
					*ngIf="columns.includes('action')"
					nzColumnKey="action"
					[nzSortOrder]="getSortOrder('action')"
				>
					{{ 'activity-log_action' | translate }}
				</th>
				<th *ngIf="columns.includes('fields')">
					{{ 'activity-log_fields' | translate }}
				</th>
				<th *ngIf="columns.includes('data')">
					{{ 'activity-log_data' | translate }}
				</th>
				<th *ngIf="showActions" class="actions-cell">
					{{ 'common_actions' | translate }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of table.data" (click)="onClick(row)">
				<td
					*ngIf="enableMultiSelect"
					nzShowCheckbox
					(nzCheckedChange)="selectRow($event, row)"
					[nzChecked]="isSelected(row)"
				></td>
				<td *ngIf="columns.includes('created_at')">
					{{ row.props.created_at | date: 'medium' }}
				</td>
				<td *ngIf="columns.includes('initiator')">
					<a
						[routerLink]="['/user', row.props.initiator.getId()]"
						*ngIf="row.props.initiator"
						>{{ row.props.initiator.getLabel() }}</a
					>
					<span *ngIf="!row.props.initiator">{{
						'common-user_unknown' | translate
					}}</span>
				</td>
				<td *ngIf="columns.includes('name')">
					{{ row.props.name }}
				</td>
				<td *ngIf="columns.includes('entity_type')">
					{{ row.props.entity_type }}
				</td>
				<td *ngIf="columns.includes('entity_id')">
					{{ row.props.entity_id }}
				</td>
				<td *ngIf="columns.includes('action')">
					{{ row.props.action }}
				</td>
				<td *ngIf="columns.includes('fields')">
					{{ row.props.fields }}
				</td>
				<td *ngIf="columns.includes('data')">
					<small>
						<pre>{{ row.props.data | json }}</pre>
					</small>
				</td>
				<td *ngIf="showActions" class="actions-cell">
					<button
						(click)="
							rowToDelete = row;
							deleteOneModal = true;
							$event.stopPropagation()
						"
						nz-button
						nzType="default"
						nzShape="circle"
						[title]="'playlist-table_delete-button' | translate"
						class="ml-3"
					>
						<i nz-icon nzType="delete"></i>
					</button>
				</td>
			</tr>
		</tbody>
	</nz-table>
	<ng-template #notFound>
		<nz-empty
			[nzNotFoundContent]="'activity-log_common_not-found' | translate"
		></nz-empty>
	</ng-template>
</div>
