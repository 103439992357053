import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'hpf-role-permission',
	templateUrl: './role-permission.component.html',
})
export class RolePermissionComponent implements OnInit {
	/** Constructor */
	constructor() {}

	/** Init */
	ngOnInit() {}
}
