import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../../models/user/user';
export interface ActivityLogInterface extends BaseModelInterface {
	created_at: number | Date;
	initiator: string | User | UserInterface;
	name: string;
	entity_type: string;
	entity_id: string;
	action: string;
	fields: string;
	data: any;
	previous: string | ActivityLog | ActivityLogInterface;
}
export interface ActivityLogPayload {}
type ActivityLogPayloadKey = keyof ActivityLogPayload;
export class ActivityLog extends BaseModel<
	ActivityLogInterface,
	ActivityLogPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of initiator has been populated */
	initiatorExists(): boolean {
		return (
			!!this.props &&
			this.props.initiator instanceof User &&
			this.props.initiator.exists()
		);
	}
	/** Denotes if the instance of previous has been populated */
	previousExists(): boolean {
		return (
			!!this.props &&
			this.props.previous instanceof ActivityLog &&
			this.props.previous.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: ActivityLogInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.initiator === 'object') {
			this.props.initiator = new User(
				<UserInterface>this.props.initiator
			);
		}
		if (typeof this.props.previous === 'object') {
			this.props.previous = new ActivityLog(
				<ActivityLogInterface>this.props.previous
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ActivityLogInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.initiator instanceof User) {
			props.initiator = props.initiator.toObject();
		}
		if (props.previous instanceof ActivityLog) {
			props.previous = props.previous.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ActivityLogPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ActivityLogInterface);
		return payload as ActivityLogPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): any[] {
		return [];
	}
}
