import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import {
	TextTranslated,
	TextTranslatedInterface,
} from '../text-translated/text-translated';
import {
	PointOfInterest,
	PointOfInterestInterface,
} from '../point-of-interest/point-of-interest';
import { Plateau, PlateauInterface } from '../plateau';

export interface RoutePoint {
	latitude: number;
	longitude: number;
}

export interface RouteInterface extends BaseModelInterface {
	created_at: number | Date;
	owner: string | User | UserInterface;
	name: string;
	itinerary?: RoutePoint[];
	published: boolean;
	deleted: boolean;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	duration: number;
	color: string;
	plateau: string | Plateau | PlateauInterface;
}
export interface RoutePayload {
	name: string;
	plateau: string | Plateau | PlateauInterface;
	itinerary?: RoutePoint[];
	published: boolean;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	duration: number;
	color: string;
}
type RoutePayloadKey = keyof RoutePayload;
export class Route extends BaseModel<RouteInterface, RoutePayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of owner has been populated */
	ownerExists(): boolean {
		return (
			!!this.props &&
			this.props.owner instanceof User &&
			this.props.owner.exists()
		);
	}
	/** Denotes if the instance of title has been populated */
	titleExists(): boolean {
		return (
			!!this.props &&
			this.props.title instanceof Array &&
			(<TextTranslated[]>this.props.title).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: RouteInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.owner === 'object') {
			this.props.owner = new User(<UserInterface>this.props.owner);
		}
		if (this.props.title instanceof Array) {
			this.props.title = (<TextTranslatedInterface[]>(
				this.props.title
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}

		if (
			this.props.plateau !== null &&
			typeof this.props.plateau === 'object'
		) {
			this.props.plateau = new Plateau(
				<PlateauInterface>this.props.plateau
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): RouteInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.itinerary === 'undefined') {
			props.itinerary = [];
		}
		if (typeof props.title === 'undefined') {
			props.title = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.owner instanceof User) {
			props.owner = props.owner.toObject();
		}
		if (this.props.title instanceof Array) {
			props.title = (<TextTranslated[]>props.title).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}

		if (props.plateau !== null && props.plateau instanceof Plateau) {
			props.plateau = props.plateau.toObject();
		}

		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): RoutePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as RouteInterface);
		payload.title = payload.title
			? payload.title.map((i) => this.extractId(i))
			: null;

		payload.plateau = payload.plateau
			? this.extractId(payload.plateau)
			: null;

		return payload as RoutePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): RoutePayloadKey[] {
		return [
			'name',
			'itinerary',
			'published',
			'title',
			'duration',
			'color',
			'plateau',
		];
	}
}
