<nz-modal [(nzVisible)]="deleteOneModal" (nzOnCancel)="deleteOneModal = false" (nzOnOk)="removeOne()"
  [nzTitle]="'common_deletion' | translate" [nzContent]="'common_delete-one' | translate" [nzOkLoading]="deleting">
</nz-modal>
<nz-modal [(nzVisible)]="deleteManyModal" (nzOnCancel)="deleteManyModal = false" (nzOnOk)="removeMany()"
  [nzTitle]="'common_deletion' | translate" [nzContent]="'common_delete-many' | translate" [nzOkLoading]="deleting">
</nz-modal>
<div class="point-of-interest-list-container">
  <nz-table #table [nzData]="items" [nzSize]="'small'" [nzShowSizeChanger]="true" [nzFrontPagination]="false"
    [nzLoading]="refreshing" [nzLoadingDelay]="refreshingDelay" [nzTotal]="total" [nzNoResult]="notFound"
    [(nzPageIndex)]="pageIndex" [(nzPageSize)]="searchParams.props._limit" (nzPageIndexChange)="update()"
    (nzPageSizeChange)="update(true)">
    <thead (nzSortOrderChange)="sort($event)">
      <tr>
        <th *ngIf="enableMultiSelect">
          <button (click)="deleteManyModal = true" nz-button [disabled]="selectedRows.length === 0" nzType="default"
            nzShape="circle">
            <i nz-icon nzType="delete"></i>
          </button>
        </th>
        <th></th>
        <th nzColumnKey="name" [nzSortOrder]="getSortOrder('name')">{{ 'point-of-interest_title' | translate }}</th>
        <th>{{ 'point-of-interest_categories' | translate }}</th>
        <th>{{ 'point-of-interest_plateau' | translate }}</th>

        <th *ngIf="columns.includes('published')" nzColumnKey="published" [nzSortOrder]="getSortOrder('published')">
          {{ 'point-of-interest_published' | translate }}</th>
        <th *ngIf="columns.includes('created_at')" nzColumnKey="created_at" [nzSortOrder]="getSortOrder('created_at')">
          {{ 'point-of-interest_created-at' | translate }}</th>
        <th *ngIf="showActions" class="actions-cell">
          {{ 'common_actions' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of table.data" (click)="onClick(row)">
        <td *ngIf="enableMultiSelect" nzShowCheckbox (nzCheckedChange)="selectRow($event, row)"
          [nzChecked]="isSelected(row)">
        </td>
        <td>

          <img *ngIf="row.thumbnailExists()" src="{{ row.props.thumbnail.getThumbnail() }}" alt="">
        </td>

        <td>
          {{ row.getTitle() }}
        </td>
        <td>
          {{ row.getCategoryTitle() }}
        </td>
        <td>
          {{ row.getPlateauTitle() }}
        </td>
        <td *ngIf="columns.includes('published')">
          {{ (row.props.published ? 'common_true' : 'common_false') | translate }}
        </td>
        <td *ngIf="columns.includes('created_at')">
          {{ row.props.created_at | date:'medium' }}
        </td>
        <td *ngIf="showActions" class="actions-cell">
          <button (click)="rowToDelete = row; deleteOneModal = true; $event.stopPropagation()" nz-button
            nzType="default" nzShape="circle" [title]="'playlist-table_delete-button' | translate" class="ml-3">
            <i nz-icon nzType="delete"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <ng-template #notFound>
    <nz-empty [nzNotFoundContent]="'point-of-interest_common_not-found' | translate "></nz-empty>
  </ng-template>
</div>
