import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';

import { SharedModule } from '@app/shared';
import { StaticModule } from '@app/static';
import { SessionModule } from '@app/session';
import { ModelsModule } from '@app/models';
import { IsAdminGuard, IsLoggedGuard, IsNotLoggedGuard } from '@app/guards';
import {
	GlobalErrorService,
	ErrorService,
	SessionService,
	S3Service,
	GeocoderService,
	PasswordService,
	EmailConfirmationService,
	EncodeHttpParamsInterceptor,
	NavigationService,
} from '@app/services';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModuleLoad } from './translate-import';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MetaModule } from '@ngx-meta/core';
import { CheckPermissionGuard } from './guards/check-permission.guard';
import { PluginsModule } from './plugins/plugins.module';
import { ModelsViewsModule } from './models/models-views.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,
		StaticModule,
		SessionModule,
		ModelsViewsModule,
		PluginsModule,
		HttpClientModule,
		AppRoutingModule,
		TranslateModuleLoad(),
		MetaModule.forRoot(),
	],
	providers: [
		CheckPermissionGuard,
		IsAdminGuard,
		IsLoggedGuard,
		IsNotLoggedGuard,
		{
			provide: ErrorHandler,
			useClass: GlobalErrorService,
		},
		ErrorService,
		SessionService,
		S3Service,
		GeocoderService,
		PasswordService,
		EmailConfirmationService,
		NavigationService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: EncodeHttpParamsInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
