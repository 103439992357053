<nz-affix>
	<div class="action-box-sticky bg-white border-bottom py-4">
		<div class="container-fluid px-5">
			<div class="row">
				<div
					class="col-12 d-flex justify-content-between align-items-center mb-4 head-line"
					[class.mb-0]="!showQuickSearch && !showAdvancedSearch"
				>
					<h1 class="m-0">{{ title }}</h1>
					<div>
						<span *ngIf="activeDisplayMode">
							<button
								*ngIf="displayMode === 'table'"
								(click)="displayMode = 'grid'"
								nz-button
								nzType="default"
								nzShape="circle"
								class="mr-4"
								[title]="'media-list-display-grid' | translate"
							>
								<i nz-icon nzType="appstore"></i>
							</button>
							<button
								*ngIf="displayMode === 'grid'"
								(click)="displayMode = 'table'"
								nz-button
								nzType="default"
								nzShape="circle"
								class="mr-4"
								[title]="'media-list-display-table' | translate"
							>
								<i nz-icon nzType="unordered-list"></i>
							</button>
						</span>
						<span *ngIf="showCreateButton">
							<button
								(click)="createEvent.emit()"
								nz-button
								nzType="primary"
								*appRestrictedToRoles="['admin', 'user']"
							>
								{{ createButton }}
							</button>
						</span>
					</div>
				</div>
				<div
					*ngIf="showQuickSearch || showAdvancedSearch"
					class="col-12 d-flex"
				>
					<nz-input-group
						*ngIf="showQuickSearch"
						[nzSuffix]="suffixIconSearch"
						class="mr-4"
					>
						<input
							nz-tooltip
							[nzTooltipTitle]="quickSearchTooltip"
							type="text"
							nz-input
							[placeholder]="quickSearchPlaceholder"
							[(ngModel)]="searchParams.props[quickSearchParam]"
							(keyup)="onChangeDebounced()"
						/>
					</nz-input-group>
					<ng-template #suffixIconSearch>
						<i nz-icon nzType="search"></i>
					</ng-template>
					<button
						*ngIf="showAdvancedSearch"
						(click)="filterEvent.emit()"
						nz-button
						[nzType]="filterIsActivated ? 'primary' : 'default'"
						[nzGhost]="filterIsActivated"
					>
						{{ 'common_advanced-search' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</nz-affix>
