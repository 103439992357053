import {
	Component,
	EventEmitter,
	ChangeDetectorRef,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscriber, Subscription as RxJsSubscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { Image } from '../image';
import { ImageService } from '../image.service';
import { LanguageTabComponent } from '@app/models';
import { arraysEqual } from 'ng-zorro-antd';
import { subscribeOn } from 'rxjs/operators';
import { promise } from 'selenium-webdriver';
import { resolve } from 'path';

class ProgressFile {
	uploaded: boolean = false;
	name: string;
	img: ArrayBuffer | string;
	file;
}
@Component({
	selector: 'hpf-image-multiple-form',
	templateUrl: './image-multiple-form.component.html',
	styleUrls: ['./image-multiple-form.component.css'],
})
export class ImageMultipleFormComponent implements OnInit, OnDestroy {
	@Input() progressFiles: ProgressFile[] = [];
	selectedFiles: FileList;
	processing: boolean = false;
	progression: string = '0';
	/** Constructor */
	constructor(
		private imageService: ImageService,
		private route: ActivatedRoute,
		private router: Router
	) {}
	selectFiles(event) {
		this.selectedFiles = event.target.files;
		let readers = [];
		for (let i = 0; i < this.selectedFiles.length; i++) {
			var file = this.selectedFiles[i];

			var p = new ProgressFile();
			p.name = file.name.split('.')[0];
			readers.push(this.readAsImage(p, file));
			p.file = file;
			this.progressFiles.push(p);
		}

		Promise.all(readers).then((values) => {});
	}

	readAsImage(p, file) {
		return new Promise(function (resolve) {
			let fr = new FileReader();
			fr.onload = function () {
				p.img = fr.result;
			};
			fr.readAsDataURL(file);
		});
	}

	remove(p) {
		const index = this.progressFiles.indexOf(p);

		this.progressFiles.splice(index, 1);
	}

	async upload(): Promise<void> {
		this.processing = true;
		for (let i = 0; i < this.progressFiles.length; i++) {
			var file = this.progressFiles[i].file;
			var image = new Image();
			image.props.name = file.name.split('.')[0];
			image.props.copyright = [''];
			image.props.title = [''];
			image.props.fileUpload = file;

			await this.imageService.postWithFile(image.toFormData());
			this.progressFiles[i].uploaded = true;
			this.progression = ((i / this.progressFiles.length) * 100).toFixed(
				1
			);
		}
		this.processing = false;
		this.progression = '0';
		this.router.navigateByUrl('/image');
	}
	ngOnDestroy(): void {}
	ngOnInit(): void {}
}
