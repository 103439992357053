import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Self } from './self';
import { Observable, ReplaySubject } from 'rxjs';
import { ErrorService } from './error.service';
import { Router, RouterStateSnapshot } from '@angular/router';

export interface SelfResponse {
	_id?: string;
	// @context user:unified-names
	name?: string;
	// @context user:separate-names
	// first_name?: string;
	// last_name?: string;
	role?: string;
	scope?: string[];
	admin?: boolean;
}

@Injectable()
export class SessionService {
	/** Route for session */
	private _sessionUri = `${environment.api.uri}/session`;
	/** Route for password login */
	private _passwordUri = `${environment.api.uri}/password/login`;
	/** Store self user instance */
	private _self: Self = null;
	/** Subject for Self */
	private _selfSubject: ReplaySubject<Self> = new ReplaySubject(1);
	/** Observable for Self */
	private _selfObservable: Observable<
		Self
	> = this._selfSubject.asObservable();
	/** Flag to denote if current has been called once */
	private _currentCalled = false;
	/** Store the path to load after login */
	private _pathAfterLogin: RouterStateSnapshot = null;

	/** Constructor */
	constructor(
		private http: HttpClient,
		private router: Router,
		private errorService: ErrorService
	) {
		// Call API to get info about current user
		this.current();
	}

	/** Retrieve user info from api */
	async current(): Promise<void> {
		// Get current from api
		const options = { withCredentials: true };
		const result: SelfResponse = await this.http
			.get(this._sessionUri, options)
			.toPromise()
			.catch((e: HttpErrorResponse) => {
				if (e.status !== 401) {
					this.errorService.handle(e);
				}
				return null;
			});
		// Update user from results
		this._self = result !== null ? new Self(result) : null;
		this._selfSubject.next(this._self);
		this._currentCalled = true;
	}

	/** Resolves when the current has been called once */
	private async waitCurrent(): Promise<void> {
		if (this._currentCalled) {
			return;
		}
		await new Promise((resolve) => {
			setTimeout(() => resolve(this.waitCurrent()), 10);
		});
	}

	/** Process a login */
	async login(email: string, password: string): Promise<Self> {
		// Do login
		const body = {
			email,
			password,
		};
		const options = { withCredentials: true };
		const result: SelfResponse = await this.http
			.post(this._passwordUri, body, options)
			.toPromise();
		// Create user from results
		this._self = new Self(result);
		this._selfSubject.next(this._self);
		return this._self;
	}

	/** Logout current user */
	async logout(): Promise<void> {
		// Get current from api
		const options = { withCredentials: true };
		await this.http
			.delete(this._sessionUri, options)
			.toPromise()
			.catch((e: HttpErrorResponse) =>
				e.status === 401 ? null : Promise.reject(e)
			);
		// Remove user
		this._self = null;
		this._selfSubject.next(this._self);
	}

	/** Get self observable */
	getSelf(): Observable<Self> {
		return this._selfObservable;
	}

	/** Returns the current users id */
	async getSelfId(): Promise<string> {
		return (await this.loggedIn()) ? this._self.getId() : null;
	}

	/** Denotes if the user is connected */
	async loggedIn(): Promise<boolean> {
		await this.waitCurrent();
		return this._self !== null;
	}

	/** Denotes if the user is an admin */
	async isAdmin(): Promise<boolean> {
		if (await this.loggedIn()) {
			return this._self.isAdmin();
		}
		return false;
	}

	async checkPermission(model, action): Promise<boolean> {
		if (await this.loggedIn()) {
			return this._self.checkPermission(model, action);
		}
		return false;
	}

	/** Define the path to load after login */
	setPathAfterLogin(route: RouterStateSnapshot) {
		this._pathAfterLogin = route;
	}

	/** Redirect to the path to load after login if exists */
	async consumePathAfterLogin(): Promise<boolean> {
		if (!this._pathAfterLogin) {
			return false;
		}

		await this.router.navigateByUrl(this._pathAfterLogin.url);
		this._pathAfterLogin = null;
		return true;
	}
}
