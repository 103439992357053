import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Image} from '../../image';

@Component({
  selector: 'hpf-image-create',
  templateUrl: './image-create.component.html'
})
export class ImageCreateComponent implements OnInit {
  /** The image */
  image = new Image();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the image is created */
  onCreate(image: Image): void {
    this.router.navigateByUrl(`/image/${image.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
