import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
export interface RoleInterface extends BaseModelInterface {
	created_at: number | Date;
	name: string;
	admin: boolean;
}
export interface RolePayload {
	name: string;
	admin: boolean;
}
type RolePayloadKey = keyof RolePayload;
export class Role extends BaseModel<RoleInterface, RolePayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Populate the current instance from an object */
	fromObject(object: RoleInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): RoleInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): RolePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as RoleInterface);
		return payload as RolePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): RolePayloadKey[] {
		return ['name', 'admin'];
	}
}
