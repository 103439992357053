import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Marker} from '../../marker';

@Component({
  selector: 'hpf-marker-create',
  templateUrl: './marker-create.component.html'
})
export class MarkerCreateComponent implements OnInit {
  /** The marker */
  marker = new Marker();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the marker is created */
  onCreate(marker: Marker): void {
    this.router.navigateByUrl(`/marker/${marker.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
