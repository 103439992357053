import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {PointOfInterestSearchParams} from '../point-of-interest-search-params';
import {PointOfInterestService} from '../point-of-interest.service';
import {PointOfInterest} from '../point-of-interest';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-point-of-interest-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class PointOfInterestSelectComponent extends EntitySelectComponent< PointOfInterest> {
  protected modelName = 'point_of_interest';
  /**
   * Constructor
   * @param {PointOfInterestService} pointOfInterestService
   * @param {ErrorService} errorService
   */
  constructor(private pointOfInterestService: PointOfInterestService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof PointOfInterest)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<PointOfInterest[]>this.model).push(<PointOfInterest>model);
          } else {
            this.model = <PointOfInterest>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< PointOfInterest[]> {
      const params = new PointOfInterestSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      if (this.filterEnabled && this.filterValue) {
        params.props._text = this.filterValue;
      }
      return (await this.pointOfInterestService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< PointOfInterest> {
      return await this.pointOfInterestService.get(id);
  }
}
