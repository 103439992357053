<div *ngIf="formGroup && controlName" [formGroup]="formGroup">
  <nz-select [nzPlaceHolder]="placeholder" [formControlName]="controlName" [(ngModel)]="model"
    [nzMode]="multiple ? 'multiple' : 'default'" (ngModelChange)="onChange()" (nzOpenChange)="onOpened($event)"
    [compareWith]="compareEntities" [nzShowSearch]="filterEnabled" [nzAllowClear]="nullable"
    (nzOnSearch)="onFilterChanged($event)" (nzScrollToBottom)="loadMore()" [nzServerSearch]="true" [nzLoading]="loading"
    class="w-100">
    <nz-option *ngIf="loading" nzDisabled nzCustomContent>
      <i nz-icon type="loading" class="loading-icon"></i>
    </nz-option>
    <nz-option *ngIf="!loading && !items.length" nzDisabled nzCustomContent>
      <nz-empty [nzNotFoundContent]="emptyLabel"></nz-empty>
    </nz-option>
    <nz-option *ngFor="let item of items; trackBy: trackById" [nzValue]="item" [nzLabel]="item.getLabel()"></nz-option>
  </nz-select>
</div>
<div *ngIf="!(formGroup && controlName)">
  <nz-select [nzPlaceHolder]="placeholder" [(ngModel)]="model" [nzMode]="multiple ? 'multiple' : 'default'"
    (ngModelChange)="onChange()" (nzOpenChange)="onOpened($event)" [compareWith]="compareEntities"
    [nzShowSearch]="filterEnabled" [nzAllowClear]="nullable" (nzOnSearch)="onFilterChanged($event)"
    (nzScrollToBottom)="loadMore()" [nzServerSearch]="true" [nzLoading]="loading" class="w-100">
    <nz-option *ngIf="loading" nzDisabled nzCustomContent>
      <i nz-icon type="loading" class="loading-icon"></i>
    </nz-option>
    <nz-option *ngIf="!loading && !items.length" nzDisabled nzCustomContent>
      <nz-empty [nzNotFoundContent]="emptyLabel"></nz-empty>
    </nz-option>
    <nz-option *ngFor="let item of items" [nzValue]="item" [nzLabel]="item.getLabel()"></nz-option>
  </nz-select>
</div>
<div *ngFor="let img of this.getModels()">
  <a routerLink="/image/{{img.getId()}}" target="_blank">
    <img src="{{img.getUri()}}" height="100px">
  </a>
</div>
<a *ngIf="quickFormEnabled" (click)="quickFormCreate(); $event.stopPropagation()" class="text-primary"><i nz-icon
    nzType="plus" nzTheme="outline"></i><span class="ml-2">{{ 'common_add' | translate }}</span></a>
