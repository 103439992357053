import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {AudioDescription} from '../../audio-description';
import {AudioDescriptionService} from '../../audio-description.service';
@Component({
  selector: 'hpf-audio-description-edit',
  templateUrl: './audio-description-edit.component.html'
})
export class AudioDescriptionEditComponent implements OnInit, OnDestroy {
  /** The audio description */
  audioDescription = new AudioDescription();
  /** The audio description */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private audioDescriptionService: AudioDescriptionService) {}
  /** Init */
  ngOnInit() {
    // Get the audio description after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch audio description from API */
  private refresh() {
    this.audioDescriptionService.get(this.id, { _populate: ['*'] })
      .then((audioDescription: AudioDescription) => {
        this.audioDescription.fromObject(audioDescription.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the audio description is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the audio description is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/audio-description')
      .catch((error) => this.errorService.handle(error));
  }
}
