import { Component, OnInit } from '@angular/core';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { environment } from '@env/environment';
import { QuickFormService } from '../quick-form.service';

@Component({
	selector: 'app-quick-form',
	templateUrl: './quick-form.component.html',
})
export class QuickFormComponent implements OnInit {
	model: string;
	visible = false;
	config = environment.plugins.quickForm;

	constructor(private quickFormService: QuickFormService) {}

	ngOnInit(): void {
		this.quickFormService.create.subscribe((model) => {
			this.visible = true;
			this.model = model;
		});
	}

	onCreate(model: BaseModel<BaseModelInterface, {}>) {
		this.model = null;
		this.visible = false;
		this.quickFormService.refresh.next(model);
	}
}
