<nz-modal [(nzVisible)]="deleteModal" (nzOnCancel)="deleteModal = false" (nzOnOk)="onDelete()"
  [nzTitle]="'common_deletion' | translate" [nzContent]="'common_delete-one' | translate" [nzOkLoading]="deleting">
</nz-modal>
<form nz-form class="diaporama-form w-100" *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()"
  [nzAutoTips]="explainErrorsService.autoTips">
  <nz-affix>
    <div class="py-4 border-bottom bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <!-- Title -->
            <div>
              <h1 class="mb-0">
                <span *ngIf="diaporama.isNew()">{{ 'diaporama_create-form-title' | translate }}</span>
                <span *ngIf="!diaporama.isNew()">{{ diaporama.getLabel() }}</span>
              </h1>
              <small *ngIf="!diaporama.isNew()"
                class="text-black-50">{{ 'common_id' | translate: { id : diaporama.getId() } }}</small>
            </div>
            <!-- /Title -->

            <!-- Actions -->
            <div>
              <button nz-button [disabled]="form.invalid" [nzLoading]="saving" nzType="primary" *appRestrictedByScope="{
                                    model: 'diaporama',
                                    action: 'update'
                                }">
                {{ 'common_save' | translate }}
              </button>
              <ng-container *appRestrictedByScope="{
                                model: 'diaporama',
                                action: 'delete'
                            }">
                <button *ngIf="!diaporama.isNew() && deletable" class="ml-4" nz-button nzShape="circle"
                  [title]="'common_delete' | translate" [nzLoading]="deleting" (click)="deleteModal = true"
                  type="button">
                  <i nz-icon nzType="delete"></i>
                </button>
              </ng-container>
            </div>
            <!-- /Actions -->
          </div>
        </div>
      </div>
    </div>
  </nz-affix>
  <div class="container">
    <nz-card class="round-border my-5">
      <div *ngIf="loading" class="row">
        <div class="col-12">
          <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
      </div>
      <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">


          <div class="col-12 col-md-6">
            <!-- Name -->


            <nz-form-item>
              <h4>{{ 'diaporama_name' | translate }}</h4>
              <nz-form-control>


                <input nz-input class="w-100" type="text" [placeholder]="'common_value-string' | translate"
                  formControlName="name">
              </nz-form-control>
            </nz-form-item>
            <!-- /Name -->
          </div>

          <div class="col-12 col-md-6">
            <!-- Audios -->


            <nz-form-item>
              <h4>{{ 'diaporama_audios' | translate }}</h4>
              <nz-form-control [nzValidateStatus]="form.get('audios')">


                <hpf-audio-select [controlName]="'audios'" [formGroup]="form" [quickFormEnabled]="quickFormEnabled"
                  [(model)]="diaporama.props.audios" [multiple]="true" [filterEnabled]="true"
                  [placeholder]="'audio_common_search-placeholder' | translate"
                  [emptyLabel]="'common_empty' | translate">
                </hpf-audio-select>
              </nz-form-control>
            </nz-form-item>
            <!-- /Audios -->
          </div>

          <div class="col-12 col-md-6">
            <!-- Is360 -->


            <nz-form-item>
              <h4>{{ 'diaporama_is360' | translate }}</h4>
              <nz-form-control>


                <nz-select class="w-100" [nzPlaceHolder]="'common_value-boolean' | translate" formControlName="is360">
                  <nz-option [nzValue]="true" [nzLabel]="'common_true' | translate"></nz-option>
                  <nz-option [nzValue]="false" [nzLabel]="'common_false' | translate"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <!-- /Is360 -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Is Video -->


            <nz-form-item>
              <h4>{{ 'diaporama_is-video' | translate }}</h4>
              <nz-form-control>


                <nz-select class="w-100" [nzPlaceHolder]="'common_value-boolean' | translate"
                  formControlName="is_video">
                  <nz-option [nzValue]="true" [nzLabel]="'common_true' | translate"></nz-option>
                  <nz-option [nzValue]="false" [nzLabel]="'common_false' | translate"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <!-- /Is Video -->
          </div>


          <div class="col-12" *ngIf="form.get('is_video').value===false">
            <!-- Slides -->
            <!-- /Slides -->
            <app-diaporama-slider-tab [name]="'slides'" [model]="diaporama"></app-diaporama-slider-tab>
          </div>
          <div class="col-12 col-md-6" *ngIf="form.get('is_video').value===true">
            <!-- Video -->


            <nz-form-item>
              <h4>{{ 'diaporama_video' | translate }}</h4>
              <nz-form-control [nzValidateStatus]=" form.get('video')">


                <hpf-video-select [controlName]="'video'" [formGroup]="form" [quickFormEnabled]="quickFormEnabled"
                  [(model)]="diaporama.props.video" [nullable]="true" [filterEnabled]="true"
                  [placeholder]="'video_common_search-placeholder' | translate"
                  [emptyLabel]="'common_empty' | translate">
                </hpf-video-select>
              </nz-form-control>
            </nz-form-item>
            <!-- /Video -->
          </div>

          <div class="col-12 col-md-6">
            <!-- Subtitle -->

            <h4>{{ 'diaporama_subtitle' | translate }}</h4>
            <app-language-tab [name]="'subtitle'" [model]="diaporama"></app-language-tab>
          </div>



        </div>

        <nz-divider *ngIf="!diaporama.isNew() && !readOnly"></nz-divider>
        <div *ngIf="!diaporama.isNew()" class="row">
          <div class="col-12 col-md-6">
            <!-- _Id -->
            <div class="mb-4">
              <h4>{{ 'diaporama_-id' | translate }}</h4>
              <div>{{ diaporama.props._id }}</div>
            </div>
            <!-- /_Id -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Created At -->
            <div class="mb-4">
              <h4>{{ 'diaporama_created-at' | translate }}</h4>
              <div>{{ diaporama.props.created_at | date:'medium' }}</div>
            </div>
            <!-- /Created At -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Is360 -->
            <div class="mb-4">
              <h4>{{ 'diaporama_is360' | translate }}</h4>
              <div>
                <span *ngIf="diaporama.props.is360 === true || diaporama.props.is360 === false">
                  {{ (diaporama.props.is360 ? 'common_true' : 'common_false') | translate }}
                </span>
              </div>
            </div>
            <!-- /Is360 -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Is Video -->
            <div class="mb-4">
              <h4>{{ 'diaporama_is-video' | translate }}</h4>
              <div>
                <span *ngIf="diaporama.props.is_video === true || diaporama.props.is_video === false">
                  {{ (diaporama.props.is_video ? 'common_true' : 'common_false') | translate }}
                </span>
              </div>
            </div>
            <!-- /Is Video -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Video -->
            <div class="mb-4">
              <h4>{{ 'diaporama_video' | translate }}</h4>
              <div *ngIf="diaporama.videoExists()">
                <a [routerLink]="['/video', diaporama.props.video.getId()]">
                  {{ diaporama.props.video.getLabel() }}
                </a>
              </div>
            </div>
            <!-- /Video -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Name -->
            <div class="mb-4">
              <h4>{{ 'diaporama_name' | translate }}</h4>
              <div>{{ diaporama.props.name }}</div>
            </div>
            <!-- /Name -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Subtitle -->
            <div class="mb-4">
              <h4>{{ 'diaporama_subtitle' | translate }}</h4>
              <div *ngIf="diaporama.subtitleExists()">
                <div *ngFor="let item of diaporama.props.subtitle">
                  <a class="mr-2" [routerLink]="['/text-translated', item.getId()]">
                    {{ item.getLabel() }}
                  </a>
                </div>
              </div>
            </div>
            <!-- /Subtitle -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Audios -->
            <div class="mb-4">
              <h4>{{ 'diaporama_audios' | translate }}</h4>
              <div *ngIf="diaporama.audiosExists()">
                <div *ngFor="let item of diaporama.props.audios">
                  <a class="mr-2" [routerLink]="['/audio', item.getId()]">
                    {{ item.getLabel() }}
                  </a>
                </div>
              </div>
            </div>
            <!-- /Audios -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Slides -->
            <div class="mb-4">
              <h4>{{ 'diaporama_slides' | translate }}</h4>
              <small>
                <pre>{{ diaporama.props.slides | json }}</pre>
              </small>
            </div>
            <!-- /Slides -->
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</form>
