import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {AudioSearchParams} from '../audio-search-params';

@Component({
  selector: 'hpf-audio-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class AudioListTopBarComponent extends EntityListTopBarComponent< AudioSearchParams> {
}
 