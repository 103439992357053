
<!-- Owner -->
<nz-form-item>
    <nz-form-label>{{ 'point-of-interest_owner' | translate }}</nz-form-label>
    <hpf-user-select
        [(id)]="searchParams.props.owner"
        [nullable]="true"
        [emptyLabel]="'common_empty' | translate"
        [filterEnabled]="true"
        [placeholder]="'user_common_search-placeholder' | translate"
        (change)="onChange();">
    </hpf-user-select>
</nz-form-item>
<!-- /Owner -->
<!-- Name -->
<nz-form-item>
    <nz-form-label>{{ 'point-of-interest_name' | translate }}</nz-form-label>
    <input nz-input
           class="w-100"
           (keyup)="onChangeDebounced();"
           [placeholder]="'common_value-string' | translate"
           [(ngModel)]="searchParams.props.name">
</nz-form-item>
<!-- /Name -->
<!-- Categories -->
<nz-form-item>
    <nz-form-label>{{ 'point-of-interest_categories' | translate }}</nz-form-label>
    <hpf-category-select
        [(id)]="searchParams.props.categories"
        [nullable]="true"
        [emptyLabel]="'common_empty' | translate"
        [filterEnabled]="true"
        [placeholder]="'category_common_search-placeholder' | translate"
        (change)="onChange();">
    </hpf-category-select>
</nz-form-item>
<!-- /Categories -->
<!-- Published -->
<nz-form-item>
    <nz-form-label>{{ 'point-of-interest_published' | translate }}</nz-form-label>
    <nz-select  class="w-100"
                [nzPlaceHolder]="'common_value-boolean' | translate"
                [(ngModel)]="searchParams.props.published"
                (ngModelChange)="onChange();"
                [nzAllowClear]="true">
        <nz-option [nzValue]="true" [nzLabel]="'common_true' | translate"></nz-option>
        <nz-option [nzValue]="false" [nzLabel]="'common_false' | translate"></nz-option>
    </nz-select>
</nz-form-item>
<!-- /Published -->
<button
	nz-button
	nzType="default"
	(click)="resetSearchParams()"
>
	{{ 'common_reset-filters' | translate }}
</button>
