import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {Subscription as RxJsSubscription} from 'rxjs';
import {AudioSearchParams} from '../../audio-search-params';
import {Audio} from '../../audio';
import {Helpers} from '@app/shared';
@Component({
  selector: 'hpf-audio-list',
  templateUrl: './audio-list.component.html',
  styleUrls: []
})
export class AudioListComponent implements OnInit, OnDestroy {
  /** The filters */
  searchParams = new AudioSearchParams(this.route.snapshot.queryParams);
  /** The search params subscription */
  private paramsSubscription: RxJsSubscription;
  /** Used to open or close filter drawer */
  filtersDrawer = false;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private location: Location) {
  }
  /** Init */
  ngOnInit() {
    // Update query string when params change
    this.paramsSubscription = this.searchParams.subscribe(() => {
      this.location.replaceState('/audio', Helpers.toQueryString(this.searchParams.toObject()));
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }
  /** Called when the user select a row in the table */
  onSelect(item: Audio) {
    this.router.navigateByUrl(`/audio/${item.getId()}`);
  }
  /** Called when the user click on create */
  onCreate() {
    this.router.navigate(['/audio/create']);
  }
}
