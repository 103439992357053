<nz-modal [(nzVisible)]="deleteModal" (nzOnCancel)="deleteModal = false" (nzOnOk)="onDelete()"
  [nzTitle]="'common_deletion' | translate" [nzContent]="'common_delete-one' | translate" [nzOkLoading]="deleting">
</nz-modal>
<form nz-form class="image-form w-100" enctype="multipart/form-data" *ngIf="form" [formGroup]="form"
  (ngSubmit)="onSubmit()" [nzAutoTips]="explainErrorsService.autoTips">
  <nz-affix>
    <div class="py-4 border-bottom bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <!-- Title -->
            <div>
              <h1 class="mb-0">
                <span *ngIf="image.isNew()">{{
									'image_create-form-title' | translate
								}}</span>
                <span *ngIf="!image.isNew()">{{
									image.getLabel()
								}}</span>
              </h1>
              <small *ngIf="!image.isNew()" class="text-black-50">{{
									'common_id'
										| translate: { id: image.getId() }
								}}</small>
            </div>
            <!-- /Title -->


          </div>
          <!-- Actions -->
          <div>
            <button nz-button [disabled]="form.invalid" [nzLoading]="saving" nzType="primary" *appRestrictedByScope="{
									model: 'image',
									action: 'update'
								}">
              {{ 'common_save' | translate }}
            </button>
            <ng-container *appRestrictedByScope="{
									model: 'image',
									action: 'delete'
								}">
              <button *ngIf="!image.isNew() && deletable" class="ml-4" nz-button nzShape="circle"
                [title]="'common_delete' | translate" [nzLoading]="deleting" (click)="deleteModal = true" type="button">
                <i nz-icon nzType="delete"></i>
              </button>
            </ng-container>
          </div>
          <!-- /Actions -->
        </div>
      </div>
    </div>

  </nz-affix>
  <div class="container">
    <nz-card class="round-border my-5">
      <div *ngIf="loading" class="row">
        <div class="col-12">
          <nz-skeleton class="no-header" [nzActive]="true"></nz-skeleton>
        </div>
      </div>
      <div *ngIf="!loading">
        <div class="row" *ngIf="!readOnly">
          <div class="col-12 col-md-6">
            <!-- Name -->
            <nz-form-item>
              <h4>{{ 'image_name' | translate }}</h4>
              <nz-form-control>
                <input nz-input class="w-100" type="text" [placeholder]="
										'common_value-string' | translate
									" formControlName="name" />
              </nz-form-control>
            </nz-form-item>
            <!-- /Name -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Title -->

            <h4>{{ 'image_title' | translate }}</h4>
            <app-language-tab [name]="'title'" [model]="image"></app-language-tab>
          </div>
          <div class="col-12 col-md-6">
            <h4>{{ 'image_common_name' | translate }}</h4>
            <!-- File -->
            <input type="file" name="fileUpload" (input)="fileUploaded($event)" id="fileUpload" class="form-control">
            <!-- /File -->
          </div>
          <div class="col-12 col-md-6">

            <h4>{{'image_copyright' | translate}}</h4>
            <app-language-tab [name]="'copyright'" [model]="image"></app-language-tab>

          </div>

        </div>

        <nz-divider *ngIf="!image.isNew() && !readOnly"></nz-divider>
        <div *ngIf="!image.isNew()" class="row">
          <div class="col-12 col-md-6">
            <!-- _Id -->
            <div class="mb-4">
              <h4>{{ 'image_-id' | translate }}</h4>
              <div>{{ image.props._id }}</div>
            </div>
            <!-- /_Id -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Created At -->
            <div class="mb-4">
              <h4>{{ 'image_created-at' | translate }}</h4>
              <div>
                {{ image.props.created_at | date: 'medium' }}
              </div>
            </div>
            <!-- /Created At -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Owner -->
            <div class="mb-4">
              <h4>{{ 'image_owner' | translate }}</h4>
              <div *ngIf="image.ownerExists()">
                <a [routerLink]="[
										'/user',
										image.props.owner.getId()
									]">
                  {{ image.props.owner.getLabel() }}
                </a>
              </div>
            </div>
            <!-- /Owner -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Uri -->
            <div class="mb-4">
              <h4>{{ 'image_uri' | translate }}</h4>
              <a [href]="image.getUri()">{{ image.getUri() }}</a>
            </div>
            <!-- /Uri -->
          </div>
          <div class="col-12 col-md-6">
            <!-- Media preview -->
            <div class="mb-4">
              <h4>{{ 'image_preview' | translate }}</h4>
              <img src="{{ image.getUri() }}">
            </div>
            <!-- /Media preview -->
          </div>
          <div class="col-12 col-md-6" *ngIf="readOnly">
            <!-- Name -->
            <div class="mb-4">
              <h4>{{ 'image_name' | translate }}</h4>
              <div>{{ image.props.name }}</div>
            </div>
            <!-- /Name -->
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</form>
