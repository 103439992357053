import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { ActivityLogCreateComponent } from './views/activity-log-create/activity-log-create.component';
import { ActivityLogEditComponent } from './views/activity-log-edit/activity-log-edit.component';
import { ActivityLogListComponent } from './views/activity-log-list/activity-log-list.component';
import { CheckPermissionGuard } from '@app/guards';

const routes: Routes = [
	{
		path: 'activity-log',
		component: ActivityLogComponent,
		children: [
			{
				path: '',
				component: ActivityLogListComponent,
				data: {
					title: 'List activity log',
					model: 'activity_log',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: ActivityLogCreateComponent,
				data: {
					title: 'Create activity log',
					model: 'activity_log',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: ActivityLogEditComponent,
				data: {
					title: 'Read activity log',
					model: 'activity_log',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ActivityRoutingModule {}
