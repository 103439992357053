<nz-drawer
	[nzClosable]="false"
	[(nzVisible)]="filtersDrawer"
	nzPlacement="right"
	(nzOnClose)="filtersDrawer = false"
	[nzTitle]="'common_filters-title' | translate"
	nzWidth="300"
>
	<hpf-activity-log-filters
		[searchParams]="searchParams"
		[resetPage]="true"
	></hpf-activity-log-filters>
</nz-drawer>
<hpf-activity-log-list-top-bar
	[title]="'activity-log_common_name' | translate"
	(filterEvent)="filtersDrawer = true"
	[searchParams]="searchParams"
	(createEvent)="onCreate()"
	[createButton]="'common_create' | translate"
	[showQuickSearch]="true"
	[quickSearchTooltip]="
		'common_search-by'
			| translate: { list: 'name, entity type, entity id, action' }
	"
	[showCreateButton]="false"
	[quickSearchPlaceholder]="'common_quick-search' | translate"
	[showAdvancedSearch]="true"
></hpf-activity-log-list-top-bar>
<div class="container-fluid px-5">
	<div class="row my-5">
		<div class="col-12">
			<hpf-activity-log-table
				[searchParams]="searchParams"
				[showActions]="true"
				(select)="onSelect($event)"
				[enableMultiSelect]="false"
				[showActions]="false"
			></hpf-activity-log-table>
		</div>
	</div>
</div>
